import React, { useContext } from "react";
import MyOrder from "../assets/svg/MyOrder.svg";
import Trade from "../assets/svg/Trade.svg";
import Wallet from "../assets/svg/wallet.svg";
import MyCreditRequest from "../assets/svg/MyCreditRequest.svg";
import ProfileImage from "../assets/images/profile-image.png";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";

const MobileNavbar = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: 55 }}>
      {userId?.jwt && (
        <nav className="mobile-bottom-nav">
          <div
            className={`mobile-bottom-nav__item ${
              location.pathname === "/wallet"
                ? "mobile-bottom-nav__item--active"
                : ""
            } `}
            onClick={() => {
              navigate("/wallet");
            }}
          >
            <div className={`mobile-bottom-nav__item-content`}>
              <img src={Wallet} alt="Wallet" />
              Wallet
            </div>
          </div>
          <div
            className={`${
              location.pathname === "/myorders"
                ? "mobile-bottom-nav__item--active"
                : ""
            }  mobile-bottom-nav__item `}
            onClick={() => {
              navigate("/myorders");
            }}
          >
            <div className={`mobile-bottom-nav__item-content`}>
              <img src={MyOrder} alt="MyOrder" />
              Orders
            </div>
          </div>
          <div
            className="mobile-bottom-nav__item"
            onClick={() => {
              navigate("/trading/1");
            }}
          >
            <div
              className={`
                mobile-bottom-nav__item--fixed
                  mobile-bottom-nav__item-content`}
            >
              <img src={Trade} alt="Trade" />
              Trading
            </div>
          </div>

          <div
            className={`mobile-bottom-nav__item ${
              location.pathname === "/myrequests"
                ? "mobile-bottom-nav__item--active"
                : ""
            } `}
            onClick={() => {
              navigate("/myrequests");
            }}
          >
            <div className={`mobile-bottom-nav__item-content`}>
              <img src={MyCreditRequest} alt="MyCreditRequest" />
              Cr.Req
            </div>
          </div>
          <div
            className={`mobile-bottom-nav__item ${
              location.pathname === "/profile"
                ? "mobile-bottom-nav__item--active"
                : ""
            }`}
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div className={` mobile-bottom-nav__item-content `}>
              <img width={"25px"} src={ProfileImage} alt="som- user-image" />
              Profile
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default MobileNavbar;
