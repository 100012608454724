import React from "react";
import { Input } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

const CustomInputBox = ({
  type,
  value,
  onchange,
  placeholder,
  className,
  disabled,
  required,
  minLength,
  maxLength,
}: {
  type: string;
  value: string;
  onchange?: (e: any) => void;
  placeholder: string;
  className: string;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}) => {
  return (
    <Input
      type={type as InputType}
      value={value}
      className={`${className} cus-input-box`}
      bsSize="lg"
      required={required || false}
      minLength={minLength || undefined}
      maxLength={maxLength || undefined}
      disabled={disabled || false}
      onChange={onchange}
      placeholder={placeholder}
      style={{ border: "2px solid #444e5d" }}
    />
  );
};

export default CustomInputBox;
