import React, { useEffect, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import { useNavigate } from "react-router-dom";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import axios from "axios";
import {
  BASE_URL,
  GET_ACCOUNT_ACTIVITY,
  GET_PROFILE_EP,
  // UPDATE_PASSWORD,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import {
  AccountActivityType,
  ProfileType,
  // VerificationModalType,
} from "../../helpers/types/Profile";
import { useErrorHandler } from "react-error-boundary";
import CustomButton from "../../Component/CustomButton";
import VerificationStepper from "./VerificationStepper";
import ActivityTable from "./ActivityTable";
import { TablePagination } from "@mui/material";
import ChangePasswordModal from "./ChangePasswordModal";

const Profile = () => {
  const { isMobile, isTablet } = useBreakPoints();
  const jwt = GetCookies(CookieItems.JWT);

  const navigate = useNavigate();

  // STATES
  const handleError = useErrorHandler();

  const [profileInfo, setProfileInfo] = useState<ProfileType | null>(null);

  const [page, setPage] = useState(0);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [accountActivity, setAccountActivity] = useState<AccountActivityType[]>(
    []
  );
  const [paginatedAccountActivity, setPaginatedAccountActivity] = useState<
    AccountActivityType[]
  >([]);
  console.log(accountActivity, "accountActivity");
  // USE EFFECTS
  useEffect(() => {
    if (jwt) {
      // get profile verification level
      axios
        .post(
          BASE_URL + GET_PROFILE_EP,
          { use_ID: -1 },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          setProfileInfo(res.data);
          getAllAccountActivity();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error("Error while fetching profile info", err);
            handleError(new Error("Error while fetching profile info"));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const getAllAccountActivity = () => {
    axios
      .post(
        BASE_URL + GET_ACCOUNT_ACTIVITY,
        { user_ID: -1 },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res: any) => {
        setAccountActivity(res.data);

        setPaginatedAccountActivity(res.data.slice(0, 10));
      })
      .catch((err: any) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        }
        console.error("Error while fetching profile info", err);
        toast.error("Error while fetching account activity");
      });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const startIndex = newPage * 10;
    const endIndex = startIndex + 10;
    const paginatedData = accountActivity.slice(startIndex, endIndex);
    setPaginatedAccountActivity(paginatedData);
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span style={{ fontSize: isMobile ? 20 : 32, fontWeight: 700 }}>
          Profile{" "}
        </span>

        {isMobile && (
          <CustomButton
            placeholder="Logout"
            color="#cd1b1b"
            onClick={() => {
              localStorage.clear();
              navigate("/logout");
            }}
          />
        )}
      </div>

      <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
        <Col lg={6} md={12}>
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              border: "none",
            }}
          >
            <span style={{ fontSize: 25, fontWeight: 600 }}>
              General Information
            </span>

            <Row className="mt-4">
              <Col md={12}>
                <Label>Email</Label>
                <CustomInputBox
                  value={profileInfo?.email || ""}
                  placeholder="Enter Email"
                  className="form-control"
                  disabled={true}
                  type="text"
                />
              </Col>
              <Col md={12} className="mt-4">
                <Label>Password</Label>
                <CustomInputBox
                  value={"**********************"}
                  placeholder="Enter Password"
                  className="form-control"
                  disabled={true}
                  type="password"
                />
              </Col>
              <Col md={12} className="mt-4 mb-4">
                <CustomButton
                  placeholder="Change Password"
                  color="#00abff"
                  onClick={() => {
                    setChangePasswordModal(true);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              border: "none",
            }}
          >
            <VerificationStepper />
          </div>
        </Col>
      </Row>
      <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
        <Col md={12}>
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              border: "none",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">

            <span style={{ fontSize: 25, fontWeight: 600 }}>
              Account Activity
            </span>

            {!isMobile && (
              <TablePagination
                component="div"
                count={accountActivity.length} // Total number of rows
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={10}
                rowsPerPageOptions={[]} // Hide the rows per page options
                sx={{
                  color: "white",
                  "& .css-pdct74-MuiTablePagination-selectLabel ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .css-levciy-MuiTablePagination-displayedRows ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .Mui-disabled": {
                    color: "grey",
                    opacity: 0.5,
                  },
                  "& .MuiTablePagination-actions": {
                    "& .MuiIconButton-root.Mui-disabled": {
                      color: "grey",
                    },
                  },
                }}
              />
            )}

            </div>
            <div className="mt-4">
              <ActivityTable data={paginatedAccountActivity} />
            </div>

            {isMobile && (
              <TablePagination
                component="div"
                count={accountActivity.length} // Total number of rows
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={10}
                rowsPerPageOptions={[]} // Hide the rows per page options
                sx={{
                  color: "white",
                  "& .css-pdct74-MuiTablePagination-selectLabel ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .css-levciy-MuiTablePagination-displayedRows ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .Mui-disabled": {
                    color: "grey",
                    opacity: 0.5,
                  },
                  "& .MuiTablePagination-actions": {
                    "& .MuiIconButton-root.Mui-disabled": {
                      color: "grey",
                    },
                  },
                }}
              />
            )}
          </div>
        </Col>
      </Row>

      {changePasswordModal && (
        <ChangePasswordModal
          open={changePasswordModal}
          onClose={() => setChangePasswordModal(false)}
        />
      )}
    </div>
  );
};

export default Profile;
