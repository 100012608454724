import { Tooltip as ReactTooltip, PlacesType } from "react-tooltip";

export interface ToolTipIconProps {
  content: string;
  id: string;
  place?: PlacesType;
  iconName?: string;
  style?: React.CSSProperties;
}

const ToolTipIcon = (props: ToolTipIconProps) => {
  return (
    <>
      <i
        className={`${props.iconName ? props.iconName : "fas fa-info-circle"}`}
        style={props.style}
        data-tooltip-id={props.id}
      ></i>
      <ReactTooltip
        id={props.id}
        place={props.place || "bottom"}
        content={props.content}
        style={{ zIndex: 999999999, textWrap: "wrap", width: "300px" }}
      />
    </>
  );
};

export default ToolTipIcon;
