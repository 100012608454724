import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  CookieItems,
  GetCookies,
  SetCookies,
} from "../../helpers/cookies_helper";
import {
  BASE_URL,
  COOKIE_TIMEOUT_VALUE,
  COOKIE_TO_UNIT,
  VALIDATE_JWT,
} from "../../helpers/api_helper";
import axios from "axios";
// import { useErrorHandler } from "react-error-boundary";
import { UserContext } from "../../helpers/types/contextType";
import { useNavigate } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
// import CustomInputBox from "../../Component/CustomInputBox";
import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";
import logo from "../../assets/images/logo.png";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomInputBoxWithIcon from "../../Component/CustomInputWithIcon";

const Signup = () => {
  const [signedUp, setSignedUp] = useState(false);
  const { isTablet, isMobile, isSmallMobile } = useBreakPoints();
  const navigate = useNavigate();
  const [isCorporate, setIsCorporate] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<{
    email: string;
    name: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    address: string;
  }>({
    email: "",
    name: "",
    confirmPassword: "",
    password: "",
    phoneNumber: "",
    address: "",
  });

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { userId, handleChangeUserId } = userContext;

  useEffect(() => {
    const jwt = GetCookies(CookieItems.JWT);
    const role = GetCookies(CookieItems.ROLE);

    if (
      (userId !== null && userId.jwt !== null && userId.role !== null) ||
      (jwt !== undefined && role !== undefined)
    ) {
      axios
        .post(BASE_URL + VALIDATE_JWT, { token: jwt })
        .then((response: any) => {
          if (
            response.data !== null &&
            response.status === 200 &&
            isCorporate === false
          ) {
            navigate("/profile");
          } else if (
            response.data !== null &&
            response.status === 200 &&
            isCorporate === true
          ) {
            navigate("/corpprofile");
          }
        })
        .catch((error: any) => {
          toast.error("Error while creating account, Please try again");
          console.error("error while authenticating", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    // Password should be at least 8 characters and contain alphabets, numerics, and a special character
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateName = (name: string) => {
    // Name should contain at least one letter, allowing spaces, periods, and single letters as separators
    const nameRegex = /^[a-zA-Z]+(?:[ .'a-zA-Z]+)*$/;
    return nameRegex.test(name);
  };

  const handleSubmit = async () => {
    if (!formData.email || !validateEmail(formData.email)) {
      toast.error("Invalid email format");
    } else if (!formData.name || !validateName(formData.name)) {
      toast.error("Name should be at least 3 characters");
    } else if (!formData.password || !validatePassword(formData.password)) {
      if (formData.password.length < 8) {
        toast.error("Password should be at least 8 characters");
      } else {
        toast.error(
          "Password should contain alphabets, numerics, and a special character"
        );
      }
    } else if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
    } else if (
      !formData.phoneNumber ||
      !isValidPhoneNumber(formData.phoneNumber)
    ) {
      toast.error("Invalid Phone Number");
    } else if (formData.phoneNumber.length === 10) {
      toast.error("Phone Number is not 10 digits");
    } else {
      try {
        // fetch some data
        // create user request to backend
        setLoading(true);
        const response = await axios.post(BASE_URL + "create_user", {
          email: formData.email,
          name: formData.name,
          username: formData.email.split("@")[0],
          hashed_password: formData.password,
          is_active: "True",
          Mobile_number: formData.phoneNumber,
          is_corporate: isCorporate,
          address: formData.address,
        });
        // throw new Error("Check error");
        handleChangeUserId({
          userID: null,
          email: null,
          jwt: response.data.token,
          role: response.data.role,
        });
        SetCookies(
          CookieItems.JWT,
          response.data.token,
          COOKIE_TIMEOUT_VALUE,
          COOKIE_TO_UNIT
        );
        SetCookies(
          CookieItems.ROLE,
          response.data.role,
          COOKIE_TIMEOUT_VALUE,
          COOKIE_TO_UNIT
        );
        setSignedUp(true);
        setLoading(false);

        navigate("/profile");
      } catch (error) {
        console.error("signup error", error);
        setLoading(false);
        toast.error("Error while signing up, Please try again");
      }
    }
  };

  useEffect(() => {
    if (signedUp) {
      // Redirect to wallet page
      navigate("/wallet");
    }
  });

  return (
    <div
      className="login-screen d-flex justify-content-center align-items-center w-100"
      style={{ position: "relative", padding: isTablet ? 0 : 40 }}
    >
      <Row
        className="container"
        style={{
          marginBottom: isTablet ? 40 : 0,
          marginTop: isTablet ? 40 : 0,
          gap: isMobile ? 20 : 0,
        }}
      >
        <Col lg={6} md={5}>
          <div className="d-flex justify-content-start align-items-start flex-column">
            <img src={logo} alt="logo" height={43} />
            {!isMobile && (
              <>
                {" "}
                <p
                  style={{ fontSize: isTablet ? (isMobile ? 15 : 12) : 20 }}
                  className="mt-5 mb-0"
                >
                  Welcome to
                </p>
                <p
                  className="mt-2 mb-0 "
                  style={{
                    fontSize: isTablet ? (isMobile ? 30 : 25) : 44,
                    fontWeight: 700,
                  }}
                >
                  State-of-the-art Exchange
                </p>
                <p
                  className="mt-2 mb-0"
                  style={{
                    fontSize: isTablet ? (isMobile ? 15 : 12) : 20,
                    lineHeight: "24px",
                  }}
                >
                  Where Tokenized Sustainability Credits Meet Blockchain
                  Technology{" "}
                </p>
              </>
            )}
          </div>
        </Col>
        <Col lg={6} md={7}>
          <div
            style={{
              background: "#111827",
              border: "1px solid #313d4f",

              borderRadius: 10,
              padding: 20,
            }}
          >
            <div
              className={`d-flex justify-content-between align-items-center ${
                isTablet ? "flex-column" : ""
              }`}
            >
              <p
                style={{
                  color: "#fff",
                  fontSize: isCorporate ? 20 : 30,
                  fontWeight: 700,
                }}
              >
                {isCorporate ? "CORPORATE SIGN UP" : "SIGN UP"}
              </p>

              <div>
                <button
                  type="button"
                  onClick={() => {
                    setIsCorporate(false);
                    setFormData({
                      email: "",
                      name: "",
                      confirmPassword: "",
                      password: "",
                      phoneNumber: "",
                      address: "",
                    });
                  }}
                  style={{
                    border: isCorporate
                      ? "1px solid #444e5d"
                      : "1px solid #00abff",
                    background: isCorporate ? "#323d4e" : "#00abff",
                    padding: isSmallMobile ? "5px 5px" : "7px 13px",
                    color: "white",
                    borderRadius: "5px 0px 0px 5px",
                  }}
                >
                  INDIVIDUAL
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsCorporate(true);
                    setFormData({
                      email: "",
                      name: "",
                      confirmPassword: "",
                      password: "",
                      phoneNumber: "",
                      address: "",
                    });
                  }}
                  style={{
                    border: isCorporate
                      ? "1px solid #00abff"
                      : "1px solid #444e5d",
                    background: isCorporate ? "#00abff" : "#323d4e",

                    padding: isSmallMobile ? "5px 5px" : "7px 13px",
                    color: "white",
                    borderRadius: "0px 5px 5px 0px",
                  }}
                >
                  CORPORATE
                </button>
              </div>
            </div>
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className={`mt-4 `}>
                <Col lg={12} md={12}>
                  <Label style={{ fontSize: 15 }}>Email </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.32324 7.00005L10.5232 11.65C11.5899 12.45 13.0565 12.45 14.1232 11.65L20.3232 7"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.3232 5H5.32324C4.21867 5 3.32324 5.89543 3.32324 7V17C3.32324 18.1046 4.21867 19 5.32324 19H19.3232C20.4278 19 21.3232 18.1046 21.3232 17V7C21.3232 5.89543 20.4278 5 19.3232 5Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    }
                    value={formData.email}
                    onchange={(e: any) =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                    placeholder="Enter Email"
                    className="form-control"
                    type="email"
                  />
                </Col>

                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>
                    {isCorporate ? "Company Name" : "Name"}
                  </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      isCorporate ? (
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3232 20H21.3232V10C21.3232 8.89543 20.4278 8 19.3232 8H15.3232M11.3232 16H11.3332M17.3232 16H17.3332M7.32324 16H7.33324M11.3232 12H11.3332M17.3232 12H17.3332M7.32324 12H7.33324M11.3232 8H11.3332M7.32324 8H7.33324M15.3232 20V6C15.3232 4.89543 14.4278 4 13.3232 4H5.32324C4.21867 4 3.32324 4.89543 3.32324 6V20H15.3232Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.32324 21C5.32324 17.134 8.45725 14 12.3232 14C16.1892 14 19.3232 17.134 19.3232 21M16.3232 7C16.3232 9.20914 14.5323 11 12.3232 11C10.1141 11 8.32324 9.20914 8.32324 7C8.32324 4.79086 10.1141 3 12.3232 3C14.5323 3 16.3232 4.79086 16.3232 7Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )
                    }
                    value={formData.name}
                    onchange={(e: any) =>
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      })
                    }
                    placeholder={
                      isCorporate ? "Enter Company Name" : "Enter Name"
                    }
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>Password </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3896 14.5V16.5M7.38965 10.0288C7.86107 10 8.44224 10 9.18965 10H15.5896C16.337 10 16.9182 10 17.3896 10.0288M7.38965 10.0288C6.80133 10.0647 6.38394 10.1455 6.02768 10.327C5.46319 10.6146 5.00425 11.0735 4.71663 11.638C4.38965 12.2798 4.38965 13.1198 4.38965 14.8V16.2C4.38965 17.8802 4.38965 18.7202 4.71663 19.362C5.00425 19.9265 5.46319 20.3854 6.02768 20.673C6.66941 21 7.50949 21 9.18965 21H15.5896C17.2698 21 18.1098 21 18.7516 20.673C19.3161 20.3854 19.775 19.9265 20.0626 19.362C20.3896 18.7202 20.3896 17.8802 20.3896 16.2V14.8C20.3896 13.1198 20.3896 12.2798 20.0626 11.638C19.775 11.0735 19.3161 10.6146 18.7516 10.327C18.3953 10.1455 17.9779 10.0647 17.3896 10.0288M7.38965 10.0288V8C7.38965 5.23858 9.62823 3 12.3896 3C15.151 3 17.3896 5.23858 17.3896 8V10.0288"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    passwordIcon={true}
                    value={formData.password}
                    onchange={(e: any) =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      })
                    }
                    placeholder="Enter Password"
                    className="form-control"
                    type="password"
                  />
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>Confrim Password </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3896 14.5V16.5M7.38965 10.0288C7.86107 10 8.44224 10 9.18965 10H15.5896C16.337 10 16.9182 10 17.3896 10.0288M7.38965 10.0288C6.80133 10.0647 6.38394 10.1455 6.02768 10.327C5.46319 10.6146 5.00425 11.0735 4.71663 11.638C4.38965 12.2798 4.38965 13.1198 4.38965 14.8V16.2C4.38965 17.8802 4.38965 18.7202 4.71663 19.362C5.00425 19.9265 5.46319 20.3854 6.02768 20.673C6.66941 21 7.50949 21 9.18965 21H15.5896C17.2698 21 18.1098 21 18.7516 20.673C19.3161 20.3854 19.775 19.9265 20.0626 19.362C20.3896 18.7202 20.3896 17.8802 20.3896 16.2V14.8C20.3896 13.1198 20.3896 12.2798 20.0626 11.638C19.775 11.0735 19.3161 10.6146 18.7516 10.327C18.3953 10.1455 17.9779 10.0647 17.3896 10.0288M7.38965 10.0288V8C7.38965 5.23858 9.62823 3 12.3896 3C15.151 3 17.3896 5.23858 17.3896 8V10.0288"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    passwordIcon={true}
                    value={formData.confirmPassword}
                    onchange={(e: any) =>
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      })
                    }
                    placeholder="Enter Confirm Password"
                    className="form-control"
                    type="password"
                  />
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>Address </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3232 21C15.8232 17.4 19.3232 14.1764 19.3232 10.2C19.3232 6.22355 16.1892 3 12.3232 3C8.45725 3 5.32324 6.22355 5.32324 10.2C5.32324 14.1764 8.82324 17.4 12.3232 21Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.3232 12C13.4278 12 14.3232 11.1046 14.3232 10C14.3232 8.89543 13.4278 8 12.3232 8C11.2186 8 10.3232 8.89543 10.3232 10C10.3232 11.1046 11.2186 12 12.3232 12Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    value={formData.address}
                    onchange={(e: any) =>
                      setFormData({
                        ...formData,
                        address: e.target.value,
                      })
                    }
                    placeholder="Enter Address"
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>
                    {isCorporate ? "Company Phone Number" : "Phone Number"}
                  </Label>
                  <CustomPhoneInput
                    label=""
                    value={formData.phoneNumber}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        phoneNumber: value || "",
                      });
                    }}
                    error={""}
                    defaultCountry="in"
                  />
                </Col>

                <Col md={12} className="mt-4">
                  <CustomButton
                    placeholder="Create Account"
                    style={{
                      width: "100%",
                      height: 47,
                      fontSize: 14,
                      fontWeight: 800,
                      lineHeight: "19.1px",
                    }}
                    color="#00abff"
                    type="submit"
                    divclassName={`w-100`}
                    disabled={loading}
                  />
                </Col>
              </Row>
            </form>
          </div>
        </Col>
      </Row>

      {!isTablet && (
        <div
          className=""
          style={{ position: "absolute", left: 150, bottom: 10 }}
        >
          <p
            // className="custom-semibold-font"
            style={{
              fontSize: 14,
              color: "#fff",
              fontWeight: 600,

              lineHeight: "19.1px",
              letterSpacing: "0.3px",
            }}
          >
            © 2024 by Cenzex. LEGAL TERMS PRIVACY
          </p>
        </div>
      )}
    </div>
  );
};

export default Signup;
