import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import MainApp from "./MainApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./hooks/ErrorFallback/ErrorFallback";
import { Capacitor } from "@capacitor/core";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const errorHandler = (error: Error, info: { componentStack: string }) => {
  console.error("ErrorBoundary top level", error, info);
};

if (Capacitor.isNativePlatform()) {
  document.addEventListener("deviceready", () => {
    root.render(
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={errorHandler}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000} // Close after 3 seconds
          newestOnTop={true}
          pauseOnHover
          theme="dark"
          toastStyle={{ backgroundColor: "#121724" }} // Change the background color
        />
        {/* eslint-disable-next-line camelcase */}
        <BrowserRouter>
          {/* eslint-disable-next-line camelcase */}
          <MainApp />
          {/* eslint-disable-next-line camelcase */}
        </BrowserRouter>
      </ErrorBoundary>
    );
  });
} else {
  root.render(
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={errorHandler}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000} // Close after 3 seconds
        newestOnTop={true}
        pauseOnHover
        theme="dark"
        toastStyle={{ backgroundColor: "#121724" }} // Change the background color
      />

      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </ErrorBoundary>
  );
}
