import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SocketConectionContext } from "../../hooks/SocketConectionContext/SocketConectionContext";
import { TradePair, UserData } from "../../helpers/types/contextType";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// import { stat } from "fs";
import { Typography } from "@mui/material";
// Initialize the necessary module
HighchartsMore(Highcharts);

export interface VitalDataTypes {
  highest_buy_price: number | null;
  highest_sell_price: number | null;
  lowest_buy_price: number | null;
  lowest_sell_price: number | null;
  most_recent_trade_pair_id: number | null;
  most_recent_trade_price: number | null;
  most_recent_trade_timestamp: number | null;
  change_percentage: number | null;
  change_value: number | null;
}

interface VitalSignsProps {
  tradePairID: TradePair | null;
  userID: UserData | null;
  resizeMode?: boolean;
}

export interface OrderBookEntryType {
  x: number;
  price: number;
  y: number;
}

export interface OrderBookData {
  asks: OrderBookEntryType[];
  bids: OrderBookEntryType[];
}

const OrderBookConatiner = (props: VitalSignsProps) => {
  const orderBookChartRef = useRef<any>(null); // Use 'any' for the ref
  const socketConnectionContext = useContext(SocketConectionContext);
  if (!socketConnectionContext) {
    throw new Error("socketConnectionContext is undefined");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { webSocketService, setWebSocketService } = socketConnectionContext;
  const [chartKey, setChartKey] = useState(0);

  const [orderBookData, setOrderBookData] = useState<OrderBookData>({
    asks: [],
    bids: [],
  });
  const [vitalData, setVitalData] = useState<VitalDataTypes>({
    highest_buy_price: 0,
    lowest_sell_price: 0,
    highest_sell_price: 0,
    lowest_buy_price: 0,
    most_recent_trade_pair_id: 0,
    most_recent_trade_price: 0,
    most_recent_trade_timestamp: 0,
    change_percentage: 0,
    change_value: 0,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(300); // Default height

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight - 100);
      }
    };

    // Initial height update
    updateHeight();

    // Set up resize observer
    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(containerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [options, setOptions] = useState<Highcharts.Options>({
    chart: {
      animation: {
        duration: 200,
      },
      type: "bar",
      backgroundColor: "#273142",
      marginTop: 70,
      height: props.resizeMode ? containerHeight : "auto", // Subtract some pixels for padding and other elements
    },

    title: {
      text: props.resizeMode ? "" : "Order Book",
      style: {
        color: "#ffffff",
      },
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tooltip: {
      // eslint-disable-next-line no-template-curly-in-string
      headerFormat: "Price: <b>${point.point.price:,.1f}</b></br>",
      pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
      // shape: "square",
      positioner(labelWidth, labelHeight, point) {
        const { plotX, plotY } = point;
        const chart = this.chart;
        const isLeftSide = plotX < chart.plotWidth / 2;

        return {
          x: isLeftSide ? plotX + 10 : plotX - labelWidth - 10,
          y: plotY - labelHeight / 2,
        };
      },
    },

    xAxis: [
      {
        reversed: true,
        visible: false,
        title: {
          text: "Market depth / price",
        },
      },
      {
        opposite: true,
        visible: false,
        title: {
          text: "Market depth / price",
        },
      },
    ],

    yAxis: [
      {
        offset: 0,
        visible: true,
        opposite: true,
        gridLineWidth: 0,
        tickAmount: 1,
        left: "50%",
        width: "50%",
        title: {
          text: "Amount of ask orders",
          style: {
            visibility: "hidden",
          },
        },
        labels: {
          enabled: true,
          format: "{#if isLast}Asks{/if}",
          style: {
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "700",
          },
          y: 10,
        },
      },
      {
        offset: 0,
        visible: true,
        opposite: true,
        gridLineWidth: 0,
        tickAmount: 2,
        left: "0%",
        width: "50%",
        reversed: true,
        title: {
          text: "Amount of bid orders",
          style: {
            visibility: "hidden",
          },
        },
        labels: {
          enabled: true,
          format: `
                    {#if (eq pos 0)}Price ($){/if}
                    {#if isLast}Bids{/if}
                `,
          style: {
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "700",
          },
          y: 10,
        },
      },
    ],

    legend: {
      enabled: false,
    },

    navigation: {
      buttonOptions: {
        theme: {
          fill: "none",
        },
      },
    },

    plotOptions: {
      series: {
        animation: false,
        dataLabels: {
          enabled: true,
          color: "#ffffff",
        },
        borderWidth: 0,
        crisp: false,
      },
      bar: {
        pointPadding: 0,
        groupPadding: 0,
      },
    },

    series: [
      {
        type: "bar", // Add this line
        dataLabels: [
          {
            align: "right",
            style: {
              fontSize: "14px",
              textOutline: "0px",
            },
            format: "{point.y:,.0f}",
          },
          {
            align: "left",
            inside: true,
            style: {
              fontSize: "13px",
              textOutline: "0px",
            },
            format: "{point.price:,.1f}",
          },
        ],
        name: "Asks",
        color: "#e94953",
        data: orderBookData.asks,
      },
      {
        type: "bar", // Add this line
        dataLabels: [
          {
            align: "left",
            style: {
              fontSize: "14px",
              textOutline: "0px",
            },
            format: "{point.y:,.0f}",
          },
          {
            align: "right",
            inside: true,
            style: {
              fontSize: "13px",
              textOutline: "0px",
            },
            format: "{point.price:,.1f}",
          },
        ],
        name: "Bids",
        color: "#26b8d1",
        data: orderBookData.bids,
        yAxis: 1,
      },
    ],
  });

  // Update options when containerHeight changes
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        ...prevOptions.chart,
        height: containerHeight - 100,
      },
    }));
  }, [containerHeight]);

  useEffect(() => {
    if (webSocketService !== null) {
      const handleMessage = (newData: any) => {
        if (newData && newData.orderbook_new !== undefined) {
          console.log(newData, "orderbook_new");
          setOrderBookData(newData.orderbook_new);
        }
        if (newData && newData.vital_signs !== undefined) {
          setVitalData(newData.vital_signs);
        }
      };
      webSocketService.setOnMessageHandler(handleMessage);

      // Clean up the event listener when the component unmounts
    }
  }, [webSocketService]);

  useEffect(() => {
    if (orderBookData) {
      // Update the options object with the new data
      const updatedOptions: Highcharts.Options = {
        ...options,
        series: [
          {
            type: "bar", // Add this line
            dataLabels: [
              {
                align: "right",
                style: {
                  fontSize: "14px",
                  textOutline: "0px",
                },
                format: "{point.y:,.0f}",
              },
              {
                align: "left",
                inside: true,
                style: {
                  fontSize: "13px",
                  textOutline: "0px",
                },
                format: "{point.price:,.1f}",
              },
            ],
            name: "Asks",
            color: "#ff4c54",
            data: orderBookData.asks,
          },
          {
            type: "bar", // Add this line
            dataLabels: [
              {
                align: "left",
                style: {
                  fontSize: "14px",
                  textOutline: "0px",
                },
                format: "{point.y:,.0f}",
              },
              {
                align: "right",
                inside: true,
                style: {
                  fontSize: "13px",
                  textOutline: "0px",
                },
                format: "{point.price:,.1f}",
              },
            ],
            name: "Bids",
            color: "#1ccab8",
            data: orderBookData.bids,
            yAxis: 1,
          },
        ],
      };

      setOptions(updatedOptions);

      setChartKey((prevKey) => prevKey + 1); // Force re-render of the chart
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBookData, webSocketService]);

  const updateData = useCallback(
    (chart: Highcharts.Chart) => {
      if (chart && chart.series && orderBookData) {
        chart.series[0].setData(orderBookData.asks, false);
        chart.series[1].setData(orderBookData.bids, false);
        chart.redraw();
      }
    },
    [orderBookData]
  );

  useEffect(() => {
    if (orderBookChartRef.current && orderBookChartRef.current.chart) {
      updateData(orderBookChartRef.current.chart);
    }
  }, [orderBookData, updateData]);

  console.log("orderBookData", orderBookData);

  return (
    <>
      {props.resizeMode ? (
        <div
          ref={containerRef}
          style={{
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            border: "1px solid #313d4f",
            transition: "transform 0.3s ease-in-out",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: 24, lineHeight: "20px", fontWeight: 700 }}
          >
            Order Book
          </Typography>

          <div className="mt-3 d-flex justify-content-between align-items-center w-100">
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "#1ccab8" }}>
                {" "}
                {vitalData.lowest_sell_price
                  ? vitalData.lowest_sell_price.toFixed(2)
                  : "-"}
              </p>
              <p> Best BUY Price</p>
            </div>
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "#ff4c54" }}>
                {" "}
                {vitalData.highest_buy_price
                  ? vitalData.highest_buy_price.toFixed(2)
                  : "-"}
              </p>
              <p>Best SELL Price</p>
            </div>
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "white" }}>
                {vitalData.most_recent_trade_price
                  ? vitalData.most_recent_trade_price.toFixed(2)
                  : "-"}
              </p>
              <p>Last Trade Price</p>
            </div>
          </div>

          <div className="mt-3" style={{ flex: 1 }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={orderBookChartRef}
              callback={updateData}
              key={chartKey}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            minHeight: 547,
            border: "1px solid #313d4f",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <div className="mt-3 d-flex justify-content-between align-items-center w-100">
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "#1ccab8" }}>
                {" "}
                {vitalData.lowest_sell_price
                  ? vitalData.lowest_sell_price.toFixed(2)
                  : "-"}
              </p>
              <p> Best BUY Price</p>
            </div>
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "#ff4c54" }}>
                {" "}
                {vitalData.highest_buy_price
                  ? vitalData.highest_buy_price.toFixed(2)
                  : "-"}
              </p>
              <p>Best SELL Price</p>
            </div>
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: 24, color: "white" }}>
                {vitalData.most_recent_trade_price
                  ? vitalData.most_recent_trade_price.toFixed(2)
                  : "-"}
              </p>
              <p>Last Trade Price</p>
            </div>
          </div>

          <div className="mt-3">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={orderBookChartRef}
              callback={updateData}
              key={chartKey}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OrderBookConatiner;
