import React, { useState, useContext, useEffect } from "react";
import CustomButton from "../../Component/CustomButton";
import axios from "axios";
import {
  BASE_URL,
  COOKIE_TIMEOUT_VALUE,
  COOKIE_TO_UNIT,
  TwoFA_VERIFY_OTP_SIGNIN,
  VALIDATE_JWT,
} from "../../helpers/api_helper";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../helpers/types/contextType";
import {
  CookieItems,
  GetCookies,
  SetCookies,
} from "../../helpers/cookies_helper";
import { toast } from "react-toastify";
import { Col, Label, Row } from "reactstrap";
import logo from "../../assets/images/logo.png";
import useBreakPoints from "../../hooks/useBreakPoints";
import OTPInput from "react-otp-input";
import CustomInputBoxWithIcon from "../../Component/CustomInputWithIcon";

interface FormData {
  email: string;
  password: string;
}

export const Login = (): JSX.Element => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();
  const userContext = useContext(UserContext);
  const [isTwoFactorReq, setIsTwoFactorReq] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorSignIn, setErrorSignIn] = useState("");
  console.log("errorSignIn", errorSignIn);
  const [loading, setLoading] = useState(false);

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { userId, handleChangeUserId } = userContext;

  const handleSubmit = () => {
    const params = new URLSearchParams();
    params.append("username", formData.email);
    params.append("password", formData.password);
    console.log("test_log", params);
    setLoading(true);
    // Sign In
    axios
      .post(BASE_URL + "sign_in", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response: any) => {
        if ("bearer_token" in response.data) {
          handleChangeUserId({
            userID: null,
            email: null,
            jwt: response.data.bearer_token,
            role: response.data.role,
          });
          SetCookies(
            CookieItems.JWT,
            response.data.bearer_token,
            COOKIE_TIMEOUT_VALUE,
            COOKIE_TO_UNIT
          );
          SetCookies(
            CookieItems.ROLE,
            response.data.role,
            COOKIE_TIMEOUT_VALUE,
            COOKIE_TO_UNIT
          );
          // console.log(response.data.role === "unverified_corporate");
          // return false;

          if (response.data.role?.endsWith("corporate")) {
            navigate("/profile");
          } else if (response.data.role === "unverified") {
            navigate("/profile");
          }
        } else if ("twofa" in response.data) {
          setIsTwoFactorReq(true);
        } else {
          throw Error("error on backend");
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setErrorSignIn("Invalid email or password");
          toast.error("Invalid email or password");
        } else if (
          error.response.data.error ===
          "Error: cannot access local variable 'user' where it is not associated with a value"
        ) {
          setErrorSignIn("Invalid email or password");
          toast.error("Invalid email or password");
        } else if ("locked" in error.response.data) {
          setErrorSignIn("account locked, please reset password");
          toast.error("account locked, please reset password");
        } else if (error.response.status === 401) {
          setErrorSignIn("Invalid email or password");
          toast.error("Invalid email or password");
        }
        console.error("There was an error during login!", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const jwt = GetCookies(CookieItems.JWT);
    const role = GetCookies(CookieItems.ROLE);

    if (
      (userId !== null && userId.jwt !== null && userId.role !== null) ||
      (jwt !== undefined && role !== undefined)
    ) {
      axios
        .post(BASE_URL + VALIDATE_JWT, { token: jwt })
        .then((response: any) => {
          console.log("response", response);
          if (response.data.user_id && response.status === 200) {
            navigate("/wallet");
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          toast.error("Sign In expired, Please Log in again", {
            toastId: "sessionExp",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (otp.length === 6) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleVerifyOtp = () => {
    if (otp.length !== 6) {
      toast.error("Please enter a valid OTP");
    } else {
      setLoading(true);
      axios
        .post(BASE_URL + TwoFA_VERIFY_OTP_SIGNIN, {
          otp: otp,
          email: formData.email,
          password: formData.password,
        })
        .then((res) => {
          setLoading(false);

          if ("bearer_token" in res.data) {
            handleChangeUserId({
              userID: null,
              email: null,
              jwt: res.data.bearer_token,
              role: res.data.role,
            });

            SetCookies(
              CookieItems.JWT,
              res.data.bearer_token,
              COOKIE_TIMEOUT_VALUE,
              COOKIE_TO_UNIT
            );
            SetCookies(
              CookieItems.ROLE,
              res.data.role,
              COOKIE_TIMEOUT_VALUE,
              COOKIE_TO_UNIT
            );
            toast.success("Authentication Successful");
            if (res.data.role === "unverified_corporate") {
              navigate("/profile");
              setIsTwoFactorReq(false);
            } else if (res.data.role === "unverified") {
              navigate("/profile");
              setIsTwoFactorReq(false);
            } else {
              navigate("/wallet");
              setIsTwoFactorReq(false);
            }
          }
          // if (res.status === 401) {
          //     console.log(res.status);
          //     toast.error("Failed To Authenticate");
          //     closePopup();
          // }
          // if (res.status === 500) {
          //     console.log(res.status);
          //     toast.info("Please wait for a new OTP on the 2FA app");
          // }
        })
        .catch((err) => {
          setLoading(false);

          console.error("Error while fetching verification level", err);
          if (err.response.status === 500) {
            console.log(err.response.status);
            toast.error("Failed To Authenticate");
            setIsTwoFactorReq(false);
          } else if (err.response.status === 401) {
            console.log(err.response.status);
            toast.error(
              "This OTP has Expired. Please wait for a new OTP on the 2FA app"
            );
          }
        });
    }
  };
  return (
    <div
      className="login-screen d-flex justify-content-center align-items-center w-100"
      style={{ position: "relative", height: "100vh" }}
    >
      <Row
        className="container"
        style={{
          marginBottom: isTablet ? 40 : 0,
          marginTop: isTablet ? 40 : 0,
          gap: isMobile ? 20 : 0,
        }}
      >
        <Col lg={6} md={5}>
          <div className="d-flex justify-content-start align-items-start flex-column">
            <img src={logo} alt="logo" height={43} />
            {!isMobile && (
              <>
                {" "}
                <p
                  style={{ fontSize: isTablet ? (isMobile ? 15 : 12) : 20 }}
                  className="mt-5 mb-0"
                >
                  Welcome to
                </p>
                <p
                  className="mt-2 mb-0 "
                  style={{
                    fontSize: isTablet ? (isMobile ? 30 : 25) : 44,
                    fontWeight: 700,
                  }}
                >
                  State-of-the-art Exchange
                </p>
                <p
                  className="mt-2 mb-0"
                  style={{
                    fontSize: isTablet ? (isMobile ? 15 : 12) : 20,
                    lineHeight: "24px",
                  }}
                >
                  Where Tokenized Sustainability Credits <br />
                  Meet Blockchain Technology{" "}
                </p>
              </>
            )}
          </div>
        </Col>
        <Col lg={6} md={7}>
          <div
            style={{
              background: "#111827",
              border: "1px solid #313d4f",
              borderRadius: 10,
              padding: 20,
            }}
          >
            {isTwoFactorReq ? (
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  handleVerifyOtp();
                }}
              >
                <div className="text-center">
                  <p style={{ color: "#fff", fontSize: 20, fontWeight: 700 }}>
                    Please Enter the OTP Generated by your 2fa app.
                  </p>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    inputStyle={{
                      width: "2rem",
                      height: "3rem",
                      margin: "0.5rem",
                      backgroundColor: "#323d4e",
                      color: "white",
                      border: "none",
                      borderRadius: 10,
                    }}
                    renderInput={(props) => <input {...props} />}
                  />

                  <CustomButton
                    placeholder="Verify "
                    style={{ marginTop: 20 }}
                    color="#00abff"
                    type="submit"
                    divclassName={`w-100 text-center`}
                    disabled={loading}
                  />
                </div>
              </form>
            ) : (
              <>
                <p style={{ color: "#fff", fontSize: 30, fontWeight: 700 }}>
                  Login
                </p>

                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row className={`mt-4 `}>
                    <Col lg={12} md={12}>
                      <Label style={{ fontSize: 15 }}>Email </Label>

                      <CustomInputBoxWithIcon
                        svgIcon={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.32324 7.00005L10.5232 11.65C11.5899 12.45 13.0565 12.45 14.1232 11.65L20.3232 7"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.3232 5H5.32324C4.21867 5 3.32324 5.89543 3.32324 7V17C3.32324 18.1046 4.21867 19 5.32324 19H19.3232C20.4278 19 21.3232 18.1046 21.3232 17V7C21.3232 5.89543 20.4278 5 19.3232 5Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </svg>
                        }
                        value={formData.email}
                        errorSignIn={errorSignIn?.length > 0}
                        onchange={(e: any) =>
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          })
                        }
                        placeholder="Enter Email"
                        className="form-control"
                        type="email"
                      />
                    </Col>

                    <Col lg={12} md={12} className="mt-3">
                      <Label style={{ fontSize: 15 }}>Password </Label>
                      <CustomInputBoxWithIcon
                        errorSignIn={errorSignIn?.length > 0}
                        passwordIcon={true}
                        svgIcon={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.3896 14.5V16.5M7.38965 10.0288C7.86107 10 8.44224 10 9.18965 10H15.5896C16.337 10 16.9182 10 17.3896 10.0288M7.38965 10.0288C6.80133 10.0647 6.38394 10.1455 6.02768 10.327C5.46319 10.6146 5.00425 11.0735 4.71663 11.638C4.38965 12.2798 4.38965 13.1198 4.38965 14.8V16.2C4.38965 17.8802 4.38965 18.7202 4.71663 19.362C5.00425 19.9265 5.46319 20.3854 6.02768 20.673C6.66941 21 7.50949 21 9.18965 21H15.5896C17.2698 21 18.1098 21 18.7516 20.673C19.3161 20.3854 19.775 19.9265 20.0626 19.362C20.3896 18.7202 20.3896 17.8802 20.3896 16.2V14.8C20.3896 13.1198 20.3896 12.2798 20.0626 11.638C19.775 11.0735 19.3161 10.6146 18.7516 10.327C18.3953 10.1455 17.9779 10.0647 17.3896 10.0288M7.38965 10.0288V8C7.38965 5.23858 9.62823 3 12.3896 3C15.151 3 17.3896 5.23858 17.3896 8V10.0288"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        value={formData.password}
                        onchange={(e: any) =>
                          setFormData({
                            ...formData,
                            password: e.target.value,
                          })
                        }
                        placeholder="Enter Password"
                        className="form-control"
                        type="password"
                      />
                    </Col>
                    {errorSignIn && (
                      <span style={{ color: "#ff4c54" }}>{errorSignIn}</span>
                    )}
                    <Col md={12} className="mt-4">
                      <CustomButton
                        placeholder="Login"
                        style={{
                          width: "100%",
                          height: 47,
                          fontSize: 14,
                          fontWeight: 800,
                          lineHeight: "19.1px",
                        }}
                        color="#00abff"
                        type="submit"
                        divclassName={`w-100`}
                        disabled={loading}
                      />
                    </Col>

                    <Col md={12} className="mt-5 text-center">
                      <p
                        onClick={() => {
                          navigate("/reset-password");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password ?
                      </p>
                    </Col>

                    <Col md={12} className="mt-3">
                      <CustomButton
                        placeholder="Create New Account"
                        style={{
                          width: "100%",
                          height: 47,
                          fontSize: 14,
                          fontWeight: 800,
                          lineHeight: "19.1px",
                        }}
                        color="#323d4e"
                        type="button"
                        divclassName={`w-100`}
                        onClick={() => {
                          navigate("/signup");
                        }}
                        disabled={loading}
                      />
                    </Col>
                  </Row>
                </form>
              </>
            )}
          </div>
        </Col>
      </Row>

      {!isTablet && (
        <div
          className=""
          style={{ position: "absolute", left: 150, bottom: 10 }}
        >
          <p
            className=""
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: "#fff",
              lineHeight: "19.1px",
              letterSpacing: "0.3px",
            }}
          >
            © 2024 by Cenzex. LEGAL TERMS PRIVACY
          </p>
        </div>
      )}
    </div>
  );
};
