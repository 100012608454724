import React from "react";

import WebSocketService from "../../services/WebSocketsService";

interface SocketConectionContextProps {
  webSocketService: WebSocketService | null;
  setWebSocketService: React.Dispatch<
    React.SetStateAction<WebSocketService | null>
  >;
}

export const SocketConectionContext =
  React.createContext<SocketConectionContextProps | null>(null);
