import React from "react";
import "react-international-phone/style.css";
import {
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

interface CustomPhoneInputProps {
  label: string;
  value: string;
  error?: string;
  onChange: (phone: string) => void;
  defaultCountry: CountryIso2;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  label,
  value,
  error,
  onChange,
  defaultCountry,
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: defaultCountry || "in",
      value,
      countries: defaultCountries,
      onChange: (data: any) => {
        onChange(data.phone);
      },
    });

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="custom-input">
      <InputGroup>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            style={{
              height: 48,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#323d4e",
              borderColor: "#444e5d",
              border: "1px solid #444e5d",
            }}
          >
            <FlagImage iso2={country.iso2} style={{ display: "flex" }} />
          </DropdownToggle>
          <DropdownMenu
            style={{
              maxHeight: "300px",
              overflow: "auto",
              backgroundColor: "#323d4e",
            }}
          >
            {defaultCountries.map((c: any) => {
              const countryData = parseCountry(c);
              return (
                <DropdownItem
                  key={countryData.iso2}
                  onClick={() => setCountry(countryData.iso2)}
                  style={{ color: "#949494" }}
                >
                  <FlagImage
                    iso2={countryData.iso2}
                    style={{ marginRight: "8px" }}
                  />
                  <span style={{ marginRight: "8px" }}>{countryData.name}</span>
                  <span style={{ color: "#949494" }}>
                    +{countryData.dialCode}
                  </span>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <Input
          type="tel"
          name="phone"
          id="phone"
          bsSize={"lg"}
          placeholder={label}
          value={inputValue}
          onChange={handlePhoneValueChange}
          innerRef={inputRef}
          invalid={!!error}
          style={{
            height: 47,
            backgroundColor: "#323d4e",
            color: "white",
            borderColor: error ? "#dc3545" : "#495057",
          }}
        />
      </InputGroup>
      {error && <div className="text-danger small mt-1">{error}</div>}
    </div>
  );
};

export default CustomPhoneInput;
