import { Table } from "react-bootstrap";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";

const ActivityTable = ({ data }: { data: any }) => {
  console.log(data, "data");
  return (
    <div>
      <Table responsive bordered={false}>
        <thead>
          <tr>
            <th
              style={{
                width: "16%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                border: "0",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              Date - Time
            </th>
            <th
              style={{
                width: "16%",
                minWidth: 150,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                borderBottom: "0",
              }}
            >
              Action
            </th>
            <th
              style={{
                width: "16%",
                minWidth: 150,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                borderBottom: "0",
              }}
            >
              IP Address
            </th>
            <th
              style={{
                width: "20%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                border: "0",
              }}
            >
              Location
            </th>
            <th
              style={{
                width: "16%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                border: "0",
              }}
            >
              Result
            </th>
            <th
              style={{
                width: "16%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                border: "0",
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              User Agent
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any) => (
            <tr>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {UnixUTCtoLocalTime(Number(item?.date)) || ""}
              </td>
              <td
                style={{
                  background: "#273142",
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {item?.action === "sign_in" ? "Sign In" : "Unknown"}
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {item?.ip_address || ""}
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {item?.location || ""}
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {item?.result || ""}
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {item?.user_agent || ""}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ActivityTable;
