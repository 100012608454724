import React, { useState, FC, ReactNode } from "react";
import {
  DepositWithrawData,
  TradePair,
  // TradingData,
  UserContext,
  UserData,
} from "../helpers/types/contextType";

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [userId, setUserId] = useState<UserData | null>(
    localStorage.getItem("userId")
      ? JSON.parse(localStorage.getItem("userId") || "{}")
      : null
  );
  const [depositData, setDepositData] = useState<DepositWithrawData | null>(
    null
  );

  const [tradingData, setTradingData] = useState<
    | {
        label: string;
        value: TradePair;
      }[]
    | null
  >(null);

  const [resizeMode, setResizeMode] = useState<boolean>(() => {
    const storedResizeMode = localStorage.getItem("resizeMode");
    return storedResizeMode ? JSON.parse(storedResizeMode) : false;
  });
  const [layoutGrid, setLayoutGrid] = useState<any[]>(() => {
    const storedLayoutGrid = localStorage.getItem("layout");
    return storedLayoutGrid ? JSON.parse(storedLayoutGrid) : [];
  });

  const [selectedTradePair, setSelectedTradePair] = useState<{
    label: string;
    value: TradePair;
  } | null>(null);

  const handleChangeUserId = (userId: UserData) => {
    if (
      userId?.email === null &&
      userId?.userID === null &&
      userId?.jwt === null &&
      userId?.role === null
    ) {
      localStorage.clear();
      setUserId(null);
    } else {
      setUserId(userId);
      localStorage.setItem("userId", JSON.stringify(userId));
    }
  };

  const handleResizeModeChange = (newResizeMode: boolean) => {
    setResizeMode(newResizeMode);
    localStorage.setItem("resizeMode", JSON.stringify(newResizeMode));
  };
  const handleLayoutGridChange = (newLayoutGrid: any[]) => {
    setLayoutGrid(newLayoutGrid);
    localStorage.setItem("layout", JSON.stringify(newLayoutGrid));
  };

  console.log("layoutGrid", layoutGrid);

  return (
    <UserContext.Provider
      value={{
        userId,
        handleChangeUserId,
        setUserId,
        depositData,
        setDepositData,
        tradingData,
        setTradingData,
        selectedTradePair,
        setSelectedTradePair,
        resizeMode,
        handleResizeModeChange,
        layoutGrid,
        handleLayoutGridChange,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
