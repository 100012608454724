import React from "react";
import { Table } from "react-bootstrap";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";

const TransactionTable = ({ data }: { data: any }) => {
  return (
    <div>
      <Table responsive bordered={false}>
        <thead>
          <tr>
            <th
              style={{
                width: "25%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "1px solid #444e5d",
                borderRight: "none",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              Date - Time
            </th>
            <th
              style={{
                width: "25%",
                minWidth: 150,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Status
            </th>
            <th
              style={{
                width: "25%",
                minWidth: 150,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",
                borderBottom: "0",

                border: "1px solid #444e5d",
                borderLeft: "0",
                borderRight: "0",
              }}
            >
              Type
            </th>
            <th
              style={{
                width: "25%",
                minWidth: 200,
                background: "#313c4d",
                paddingLeft: 15,
                color: "white",

                border: "1px solid #444e5d",
                borderLeft: "0",
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any) => (
            <tr>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                {UnixUTCtoLocalTime(Number(item?.Time))}
              </td>
              <td
                style={{
                  background: "#273142",
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
              >
                <span
                  style={{
                    background: "#1ccab8",
                    padding: "5px 15px",

                    borderRadius: "20px",
                  }}
                >
                  Successful
                </span>
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                  textTransform: "capitalize",
                }}
              >
                {item?.Tx_Type || "Deposit"}
              </td>
              <td
                style={{
                  background: "#273142",
                  paddingLeft: 15,
                  color: "white",
                  fontSize: 15,
                  border: "none",
                }}
                className="d-flex justify-content-start align-items-center gap-2"
              >
                <div
                  className={`frame-107-uil-arrow-up-13 uil-arrow-up-4-uil-arrow-up-7`}
                >
                  <svg
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    transform={
                      item?.Tx_Type !== "deposit" ? "rotate(270)" : "rotate(90)"
                    }
                  >
                    <path
                      d="M 21.391 21.336 L 21.609 14.269 C 21.61 14.137 21.586 14.006 21.536 13.884 C 21.442 13.638 21.255 13.439 21.016 13.331 C 20.897 13.274 20.768 13.241 20.637 13.234 L 13.569 13.015 C 13.437 13.011 13.306 13.033 13.183 13.08 C 13.059 13.127 12.946 13.197 12.85 13.287 C 12.656 13.47 12.543 13.722 12.534 13.988 C 12.526 14.254 12.624 14.513 12.806 14.707 C 12.989 14.901 13.241 15.014 13.507 15.023 L 18.165 15.16 L 12.634 20.358 C 12.441 20.54 12.328 20.791 12.32 21.056 C 12.312 21.321 12.409 21.578 12.591 21.772 C 12.772 21.965 13.023 22.078 13.288 22.086 C 13.554 22.094 13.811 21.997 14.004 21.815 L 19.535 16.617 L 19.383 21.274 C 19.379 21.406 19.4 21.538 19.447 21.661 C 19.493 21.785 19.564 21.898 19.654 21.994 C 19.745 22.09 19.853 22.168 19.974 22.222 C 20.094 22.276 20.224 22.305 20.356 22.309 C 20.488 22.314 20.62 22.292 20.743 22.246 C 20.867 22.199 20.98 22.129 21.076 22.038 C 21.172 21.948 21.249 21.839 21.303 21.719 C 21.358 21.598 21.387 21.468 21.391 21.336 Z"
                      fill={item?.Tx_Type !== "deposit" ? "#f33b63" : "#1ccab8"}
                    />
                  </svg>
                </div>
                <span style={{ marginLeft: -15 }}>{item?.amount}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TransactionTable;
