import React, { useEffect, useState } from "react";
import WalletCreditIcon from "../../assets/svg/Wallet-credit-icon.svg";
import useBreakPoints from "../../hooks/useBreakPoints";
import { VitalDataTypes } from "../../helpers/types/WalletType";
import { useNavigate } from "react-router-dom";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { UserContext } from "../../helpers/types/contextType";
import axios from "axios";
import { BASE_URL, GET_VITAL_SIGNS } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import CustomButton from "../../Component/CustomButton";
import TradeDetailModal from "./TradeDetailModal";

const TradeDrawerPopup = ({
  tradeDrawerOpen,
  setTradeDrawerOpen,
}: {
  tradeDrawerOpen: any;
  setTradeDrawerOpen?: any;
}) => {
  const { isTablet, isSmallMobile } = useBreakPoints();
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const userContext = React.useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;

  const [tradeDetailModal, setTradeDetailModal] = useState({
    open: false,
    data: null,
  });

  const [vitalData, setVitalData] = useState<VitalDataTypes>({
    highest_buy_price: 0,
    highest_sell_price: 0,
    lowest_buy_price: 0,
    lowest_sell_price: 0,
    most_recent_trade_pair_id: 0,
    most_recent_trade_price: 0,
    most_recent_trade_timestamp: 0,
    change_percentage: 0,
    change_value: 0,
    "24h_volume": 0,
  });

  useEffect(() => {
    if (userId !== null && tradeDrawerOpen) {
      // fetch vital data
      axios
        .post(
          BASE_URL + GET_VITAL_SIGNS,
          { trade_pair_id: tradeDrawerOpen?.data?.id },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.data !== null) {
            setVitalData(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          }
          console.error("There was an error!", error);
          toast.error("There was an error fetching vital data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeDrawerOpen, userId]);

  return (
    <div
      style={{
        cursor: "pointer",
        backgroundColor: "#273142",
        borderRadius: 12,
        // height: "100%",
        padding: "10px 15px",
        marginTop: 10,
        border: "2px solid #0d6798",
        position: "relative", // Add this
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
          color: "white",
        }}
        onClick={() => {
          setTradeDrawerOpen({
            id: null,
            open: false,
            data: null,
          });
        }}
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.86328 20.7981L21.1433 3.51807"
            stroke="white"
            stroke-width="3.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.86328 3.51807L21.1433 20.7981"
            stroke="white"
            stroke-width="3.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div className="d-flex justify-content-center align-items-center w-100 flex-column">
        <img
          className="mt-2"
          style={{ height: 80 }}
          src={WalletCreditIcon}
          alt="Rupees Icon"
        />
        <p
          className=" mb-0"
          style={{
            fontSize: isTablet ? 18 : 24,
            fontWeight: 700,
            marginTop: -10,
          }}
        >
          {tradeDrawerOpen?.data?.name}
        </p>
        <p style={{ fontSize: isTablet ? 10 : 12 }}>
          {tradeDrawerOpen?.data?.full_name}
        </p>
      </div>

      <div>
        <hr />
        <div
          style={{ paddingLeft: isTablet ? 10 : 47 }}
          className="d-flex justify-content-around align-items-center"
        >
          <div style={{ fontSize: 18 }} className="d-flex ">
            <p className="mb-0">&#x20b9; {tradeDrawerOpen?.data?.last_price}</p>

            {tradeDrawerOpen?.data?.change_percentage > 0 ? (
              <i
                className="fa-solid fa fa-caret-up"
                style={{ marginLeft: 20, marginTop: 5, color: "#8ce200" }}
              ></i>
            ) : tradeDrawerOpen?.data?.change_percentage === 0 ? (
              <span
                style={{ marginLeft: 20, marginTop: 5, color: "#8ce200" }}
              ></span>
            ) : (
              <i
                className="fa-solid fa fa-caret-down"
                style={{ marginLeft: 20, marginTop: 5, color: "#ff0000" }}
              ></i>
            )}
            <p className="mb-0 ">
              {" "}
              <i
                className="fas fa-chart-line p-1"
                style={{ color: "white" }}
              ></i>{" "}
              {tradeDrawerOpen?.data?.change_percentage}%{" "}
            </p>
          </div>
          <div style={{ fontSize: 12 }} className="w-50">
            <div className="d-flex gap-3">
              <p style={{ width: isTablet ? "40%" : "25%" }} className="mb-0">
                Mint Year
              </p>
              <p className="mb-0">{tradeDrawerOpen?.data?.mint_year}</p>
            </div>
            <div className="d-flex gap-3">
              <p style={{ width: isTablet ? "40%" : "25%" }} className="mb-0">
                Tonnage
              </p>
              <p className="mb-0">
                {tradeDrawerOpen?.data?.tonnage_per_credit}
              </p>
            </div>
            <div className="d-flex gap-3">
              <p style={{ width: isTablet ? "40%" : "25%" }} className="mb-0">
                Total Credit
              </p>
              <p className="mb-0">{tradeDrawerOpen?.data?.total_credits}</p>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <div style={{ padding: "5px 30px " }}>
        <p>Asset Information</p>

        <div
          className={`d-flex ${
            isTablet ? "justify-content-between" : "justify-content-start"
          }  w-100  ${
            isSmallMobile
              ? "flex-column align-items-start "
              : "align-items-center "
          }`}
          style={{ padding: "0px 20px 0px 0px" }}
        >
          <div style={{ width: isSmallMobile ? "100%" : "50%" }}>
            <p className="mb-0">Total Projects Registered</p>
            <p>{tradeDrawerOpen?.data?.total_projects_registered || "-"}</p>
          </div>
          <div>
            <p className="mb-0">24h Volume</p>
            <p>{vitalData?.["24h_volume"] || "-"}</p>
          </div>
        </div>
        <div
          className={`d-flex ${
            isTablet ? "justify-content-between" : "justify-content-start"
          }   w-100 ${
            isSmallMobile
              ? "flex-column align-items-start "
              : "align-items-center "
          }`}
          style={{ padding: isTablet ? "" : "0px 20px 0px 0px" }}
        >
          <div style={{ width: isSmallMobile ? "100%" : "50%" }}>
            <p className="mb-0"> Highest Buy Price</p>
            <p>{vitalData?.highest_buy_price || "-"}</p>
          </div>
          <div>
            <p className="mb-0">Highest Sell Price</p>
            <p>{vitalData?.highest_sell_price || "-"}</p>
          </div>
        </div>
        <div
          className={`d-flex ${
            isTablet ? "justify-content-between" : "justify-content-start"
          }   w-100 ${
            isSmallMobile
              ? "flex-column align-items-start "
              : "align-items-center "
          }`}
          style={{ padding: isTablet ? "" : "0px 20px 0px 0px" }}
        >
          <div style={{ width: isSmallMobile ? "100%" : "50%" }}>
            <p className="mb-0">Lowest Buy Price</p>
            <p>
              {vitalData?.highest_buy_price !== vitalData?.lowest_buy_price
                ? vitalData?.lowest_buy_price
                : "-"}
            </p>
          </div>
          <div>
            <p className="mb-0"> Lowest Sell Price</p>
            <p>
              {vitalData?.highest_buy_price !== vitalData?.lowest_sell_price
                ? vitalData?.lowest_sell_price
                : "-"}
            </p>
          </div>
        </div>
      </div>

      <div
        className={`d-flex justify-content-center w-100 mb-3 mt-2 ${
          isTablet ? "flex-column gap-2" : ""
        }`}
      >
        {tradeDrawerOpen?.data ? (
          <>
            {jwt !== undefined && role !== undefined && (
              <CustomButton
                placeholder="View Trading Page"
                color="#1ccab8"
                style={{
                  width: "100%",
                  padding: "10px 20px",
                  textWrap: "nowrap",
                }}
                divclassName="w-100"
                onClick={() => {
                  navigate(`/trading/${tradeDrawerOpen?.data?.id}`);
                }}
              />
            )}
            <CustomButton
              placeholder="Show More Details"
              style={{
                width: "100%",
                padding: "10px 20px",
                textWrap: "nowrap",
              }}
              divclassName="w-100"
              color="#3498DB"
              onClick={() => {
                setTradeDetailModal({
                  open: true,
                  data: tradeDrawerOpen?.data,
                });
              }}
            />
          </>
        ) : (
          <CustomButton
            placeholder="Show More Details"
            color="#3498DB"
            onClick={() => {
              setTradeDetailModal({
                open: true,
                data: tradeDrawerOpen?.data,
              });
            }}
          />
        )}
      </div>

      {tradeDetailModal.open && (
        <TradeDetailModal
          open={tradeDetailModal.open}
          onClose={() => setTradeDetailModal({ open: false, data: null })}
          data={tradeDetailModal.data}
        />
      )}
    </div>
  );
};

export default TradeDrawerPopup;
