import React, { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

const CustomInputBoxWithIcon = ({
  type,
  value,
  onchange,
  placeholder,
  className,
  disabled,
  required,
  minLength,
  maxLength,
  svgIcon,
  passwordIcon,
  errorSignIn,
}: {
  type: string;
  value: string;
  onchange?: (e: any) => void;
  placeholder: string;
  className: string;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  svgIcon: React.ReactNode;
  passwordIcon?: boolean;
  errorSignIn?: boolean;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <InputGroupText
        className="cus-input-box"
        style={{
          border: errorSignIn ? "1px solid #ff4c54" : "none",
          borderRight: "none",
        }}
      >
        {svgIcon}
      </InputGroupText>
      <Input
        type={
          passwordIcon
            ? showPassword
              ? "text"
              : "password"
            : (type as InputType)
        }
        value={value}
        className={`${className} cus-input-box`}
        bsSize="lg"
        required={required || false}
        minLength={minLength || undefined}
        maxLength={maxLength || undefined}
        disabled={disabled || false}
        onChange={onchange}
        placeholder={placeholder}
        style={{
          border: errorSignIn ? "1px solid #ff4c54" : "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      />
      {passwordIcon && (
        <InputGroupText
          className="cus-input-box"
          style={{
            border: errorSignIn ? "1px solid #ff4c54" : "none",
            borderLeft: "none",
          }}
        >
          <i
            className={`${showPassword ? "fa fa-eye-slash" : "fa fa-eye"}`}
            onClick={() => setShowPassword(!showPassword)}
            style={{
              color: "white",
              cursor: "pointer",
            }}
          ></i>
        </InputGroupText>
      )}
    </InputGroup>
  );
};

export default CustomInputBoxWithIcon;
