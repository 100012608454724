export enum Role {
  user = "user",
  admin = "admin",
  corporate = "corporate",
  unverified = "unverified",
  unverifiedCorporate = "unverified_corporate",
}

export const AccessControl = {
  trading: [
    Role.user,
    Role.unverified,
    Role.admin,
    Role.corporate,
    Role.unverifiedCorporate,
  ],
  wallet: [Role.user, Role.admin, Role.corporate],
  myorders: [Role.user, Role.admin, Role.corporate],
  transactions: [Role.user, Role.admin, Role.corporate],
  profile: [Role.user, Role.unverified, Role.admin],
  corpprofile: [Role.corporate, Role.unverifiedCorporate],
  admin: [Role.admin],
  corprequestmngmt: [Role.admin],
  myrequests: [Role.user, Role.admin, Role.corporate],
  explore: [
    Role.user,
    Role.unverified,
    Role.admin,
    Role.corporate,
    Role.unverifiedCorporate,
  ],
};
