import React, { useEffect, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  // convertFromRaw,
} from "draft-js";
import { useErrorHandler } from "react-error-boundary";
import axios from "axios";
import {
  BASE_URL,
  TOKENIZE_CREDITS,
  UPDATE_CREDIT_REQUEST,
} from "../../helpers/api_helper";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { toast } from "react-toastify";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import CustomButton from "../../Component/CustomButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

const TokenizeCredit = () => {
  const { isMobile, isTablet, isMediumMobile } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const jwt = GetCookies(CookieItems.JWT);

  const [tokenizeInfo, setTokenizeInfo] = useState({
    registryAccountID: "",
    projectID: "",
    registryName: "",
    registryProjectURL: "",
    creditsNo: "",
    programmeName: "",
    projectCountry: "",
    projectState: "",
    projectType: "",
    project_name: "",
    methodologyID: "",
    alreadyRegistered: false,
    mintDate: "",
    oneTimeClaim: true,
    mintDurationYears: "",
    mintDurationMonths: "",
    mintDurationDays: "",
    tokenID: "",
    additionalAccreditations: EditorState.createEmpty(),
  });

  const [extras, setExtras] = useState({
    status: false,
    projectProponentName: "",
    projectProponentAddress: "",
    projectProponentPhone: "",
    projectProponentCode: "",
  });
  // const [registry, setRegistry] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleError = useErrorHandler();

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>): void => {
    e?.preventDefault();
    setLoading(true);
    if (location?.state?.edit === undefined) {
      const contentState =
        tokenizeInfo.additionalAccreditations.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = rawContentState.blocks
        .map((block) => block.text)
        .join("\n");
      axios
        .post(
          BASE_URL + TOKENIZE_CREDITS,
          {
            if_registry_account: tokenizeInfo.alreadyRegistered,
            registry_name: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryName
              : "",
            registry_account_id: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryAccountID
              : "",
            project_name: tokenizeInfo.project_name,
            project_id: tokenizeInfo.projectID,
            project_type: tokenizeInfo.projectType,
            project_country: tokenizeInfo.projectCountry,
            project_state: tokenizeInfo.projectState,
            registry_project_url: tokenizeInfo.registryProjectURL,
            num_of_credits: tokenizeInfo.creditsNo,
            token_id: tokenizeInfo.tokenID,
            methodology_id: tokenizeInfo.methodologyID,
            credit_programme: tokenizeInfo.programmeName,
            additional_accreditations: plainText,
            mint_year: tokenizeInfo.mintDate,
            if_one_time_claim: tokenizeInfo.oneTimeClaim,
            mint_duration: !tokenizeInfo.oneTimeClaim
              ? tokenizeInfo.mintDurationYears +
                "Y;" +
                tokenizeInfo.mintDurationMonths +
                "M;" +
                tokenizeInfo.mintDurationDays +
                "D;"
              : "",
            applicant_is_not_pp: !extras.status,
            pp_name: !extras.status ? extras.projectProponentName : "",
            pp_address: !extras.status ? extras.projectProponentAddress : "",
            pp_contact_details: !extras.status
              ? extras.projectProponentCode.toString() +
                "-" +
                extras.projectProponentPhone.toString()
              : "",
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          toast.success("Credit Tokenize Request Placed Successfully");
          setLoading(false);
          navigate("/wallet");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            handleError(new Error("Tokenization Failed"));
          }
          setLoading(false);
        });
    } else {
      const contentState =
        tokenizeInfo.additionalAccreditations.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = rawContentState.blocks
        .map((block) => block.text)
        .join("\n");
      axios
        .post(
          BASE_URL + UPDATE_CREDIT_REQUEST,
          {
            request_id: location?.state?.edit.id,
            if_registry_account: tokenizeInfo.alreadyRegistered.toString(),
            registry_name: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryName
              : "",
            registry_account_id: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryAccountID
              : "",
            project_id: tokenizeInfo.projectID,
            project_name: tokenizeInfo.project_name,
            project_type: tokenizeInfo.projectType,
            project_country: tokenizeInfo.projectCountry,
            project_state: tokenizeInfo.projectState,
            registry_project_url: tokenizeInfo.registryProjectURL,
            num_of_credits: Number(tokenizeInfo.creditsNo),
            token_id: tokenizeInfo.tokenID,
            methodology_id: tokenizeInfo.methodologyID,
            credit_programme: tokenizeInfo.programmeName,
            additional_accreditations: plainText,
            mint_year: tokenizeInfo.mintDate,
            if_one_time_claim: tokenizeInfo.oneTimeClaim.toString(),
            mint_duration: !tokenizeInfo.oneTimeClaim
              ? tokenizeInfo.mintDurationYears +
                "Y;" +
                tokenizeInfo.mintDurationMonths +
                "M;" +
                tokenizeInfo.mintDurationDays +
                "D;"
              : "",
            applicant_is_not_pp: (!extras.status).toString(),
            pp_name: !extras.status ? extras.projectProponentName : "",
            pp_address: !extras.status ? extras.projectProponentAddress : "",
            pp_contact_details: !extras.status
              ? extras.projectProponentCode.toString() +
                "-" +
                extras.projectProponentPhone.toString()
              : "",
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          toast.success("Credit Tokenize Request Updated");
          setLoading(false);
          navigate("/myrequests");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Error while updating tokenize request");
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (location?.state?.edit) {
      setTokenizeInfo({
        ...tokenizeInfo,
        registryAccountID: location?.state?.edit.registry_account_id,
        projectID: location?.state?.edit.project_id,
        registryName: location?.state?.edit.registry_name,
        registryProjectURL: location?.state?.edit.registry_project_url,
        creditsNo: location?.state?.edit.num_of_credits.toString(),
        programmeName: location?.state?.edit.credit_programme,
        projectCountry: location?.state?.edit.project_country,
        projectState: location?.state?.edit.project_state,
        projectType: location?.state?.edit.project_type,
        project_name:
          location?.state?.edit.project_name ||
          location?.state?.edit.registry_project_name,
        methodologyID: location?.state?.edit.methodology_id,
        alreadyRegistered: location?.state?.edit.if_registry_account,
        mintDate: location?.state?.edit.mint_year,
        oneTimeClaim: location?.state?.edit.if_one_time_claim,
        mintDurationYears:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration?.split(";")[0]?.slice(0, -1)
            : "",
        mintDurationMonths:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration
                ?.split(";")?.[1]
                ?.slice(0, -1)
            : "",
        mintDurationDays:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration
                ?.split(";")?.[2]
                ?.slice(0, -1)
            : "",
        tokenID: location?.state?.edit.token_id,
        additionalAccreditations: EditorState.createWithContent(
          ContentState.createFromText(
            location?.state?.edit.additional_accreditations
          )
        ),
      });
      setExtras({
        ...extras,
        status: !location?.state?.edit.applicant_is_not_pp,
        projectProponentName: location?.state?.edit.pp_name,
        projectProponentAddress: location?.state?.edit.pp_address,
        projectProponentPhone:
          location?.state?.edit.pp_contact_details.split("-")[1],
        projectProponentCode:
          location?.state?.edit.pp_contact_details.split("-")[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span style={{ fontSize: isMobile ? 20 : 32, fontWeight: 700 }}>
          Tokenize Your Credits{" "}
        </span>
        <CustomButton
          placeholder="back"
          style={{ width: isMobile ? "100%" : "auto" }}
          color="#323d4e"
          type="button"
          divclassName={` ${isMobile ? "w-100" : ""}`}
          onClick={() => {
            if (location?.state?.edit) {
              navigate("/myrequests");
            } else {
              navigate("/wallet");
            }
          }}
        />
      </div>

      <Row
        style={{
          backgroundColor: "#273142",
          border: "1px solid #313d4f",
          borderRadius: "10px",
          margin: "10px 5px",
          padding: "10px 20px",
        }}
      >
        <Col className="" style={{ marginTop: 12, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              General Information
            </span>
          </div>
          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={4} md={6}>
              <Label>
                Number of Credits{" "}
                <Tooltip
                  title="Number of credits to be tokenized"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.creditsNo}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    creditsNo: e.target.value,
                  })
                }
                placeholder="Enter Number of Credits"
                className="form-control"
                type="number"
              />
            </Col>
            <Col lg={4} md={6}>
              <Label>
                Registry URL{" "}
                <Tooltip
                  title="Please enter the Link of the Project on the registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.registryProjectURL}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    registryProjectURL: e.target.value,
                  })
                }
                placeholder="Enter Registry URL"
                className="form-control"
                type="url"
              />
            </Col>
            <Col
              lg={4}
              md={6}
              className={`${isTablet ? (isMobile ? "" : "mt-3") : ""}`}
            >
              <Label>
                Project ID{" "}
                <Tooltip
                  title="Please enter the Project ID as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectID}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    projectID: e.target.value,
                  })
                }
                placeholder="Enter Project ID"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Credit Program Name{" "}
                <Tooltip
                  title="Please enter the Project name as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.programmeName}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    programmeName: e.target.value,
                  })
                }
                placeholder="Enter Credit Program Name"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Name{" "}
                <Tooltip
                  title="eg. Verra VCS, or Goldstandard CERs, etc"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.project_name}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    project_name: e.target.value,
                  })
                }
                placeholder="Enter Project Name"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project State{" "}
                <Tooltip
                  title="Please enter the Project state as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>{" "}
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectState}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    projectState: e.target.value,
                  })
                }
                placeholder="Enter Project State"
                className="form-control"
                type="text"
              />
            </Col>{" "}
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Country{" "}
                <Tooltip
                  title="Please enter the Project country as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectCountry}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    projectCountry: e.target.value,
                  })
                }
                placeholder="Enter Project Country"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Type{" "}
                <Tooltip
                  title="eg. carbon Capture, GHG mitigation, forestry, Credits to reduce, etc"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectType}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    projectType: e.target.value,
                  })
                }
                placeholder="Enter Project Type"
                className="form-control"
                type="text"
              />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Methodology ID{" "}
                <Tooltip
                  title="The Methodology ID can be found on the project's page on the registry."
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.methodologyID}
                onchange={(e: any) =>
                  setTokenizeInfo({
                    ...tokenizeInfo,
                    methodologyID: e.target.value,
                  })
                }
                placeholder="Enter Methodology ID"
                className="form-control"
                type="text"
              />
            </Col>
            <Col md={12}>
              <div
                className={`d-flex justify-content-start   ${
                  isMediumMobile
                    ? "flex-column align-items-start mt-4  gap-2"
                    : "align-items-center mt-5  gap-4"
                } `}
              >
                <p
                  className={`mb-0`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Do you have a Registry Account ?{" "}
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        alreadyRegistered: true,
                      });
                    }}
                    style={{
                      border: tokenizeInfo.alreadyRegistered
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: tokenizeInfo.alreadyRegistered
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        alreadyRegistered: false,
                      });
                    }}
                    style={{
                      border: !tokenizeInfo.alreadyRegistered
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !tokenizeInfo.alreadyRegistered
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>
            {tokenizeInfo.alreadyRegistered && (
              <>
                <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>
                    Registry Account ID{" "}
                    <Tooltip
                      title="Please enter the Registry Account ID as provided by your registry"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.registryAccountID}
                    onchange={(e: any) =>
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        registryAccountID: e.target.value,
                      })
                    }
                    placeholder="Enter Registry Account ID"
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>
                    Registry Name{" "}
                    <Tooltip
                      title="Please enter the Registry Name as provided by your registry"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.registryName}
                    onchange={(e: any) =>
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        registryName: e.target.value,
                      })
                    }
                    placeholder="Enter Registry Name"
                    className="form-control"
                    type="text"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Mint Information
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={4} md={6}>
              <Label>
                Mint Year{" "}
                <Tooltip
                  title="Specify the year your credits of the project were minted. Credits are minted annually."
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <InputGroup>
                <CustomInputBox
                  value={tokenizeInfo.mintDate}
                  onchange={(e: any) =>
                    setTokenizeInfo({
                      ...tokenizeInfo,
                      mintDate: e.target.value,
                    })
                  }
                  placeholder="Enter Mint Year"
                  className="form-control"
                  type="number"
                />
                <InputGroupText
                  style={{
                    backgroundColor: "#323d4e",
                    color: "white",
                    border: "1px solid #444e5d",
                  }}
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.742676 17C0.742676 18.7 2.04268 20 3.74268 20H17.7427C19.4427 20 20.7427 18.7 20.7427 17V9H0.742676V17ZM17.7427 2H15.7427V1C15.7427 0.4 15.3427 0 14.7427 0C14.1427 0 13.7427 0.4 13.7427 1V2H7.74268V1C7.74268 0.4 7.34268 0 6.74268 0C6.14268 0 5.74268 0.4 5.74268 1V2H3.74268C2.04268 2 0.742676 3.3 0.742676 5V7H20.7427V5C20.7427 3.3 19.4427 2 17.7427 2Z"
                      fill="#B6B6B6"
                    />
                  </svg>
                </InputGroupText>
              </InputGroup>
            </Col>
            <Col md={8}>
              <div
                className={`d-flex justify-content-start ${
                  isMediumMobile
                    ? "flex-column align-items-start gap-2 "
                    : "align-items-center gap-4 "
                } `}
              >
                <p
                  className={`mb-0`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Is the credit one time claimable?{" "}
                  <Tooltip
                    title="Select 'Yes' if you'll receive credits annually from this project."
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#475469",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 16,
                        marginLeft: 5,
                        marginBottom: 3,
                      }}
                    />
                  </Tooltip>
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        oneTimeClaim: true,
                      });
                    }}
                    style={{
                      border: tokenizeInfo.oneTimeClaim
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: tokenizeInfo.oneTimeClaim
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        oneTimeClaim: false,
                      });
                    }}
                    style={{
                      border: !tokenizeInfo.oneTimeClaim
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !tokenizeInfo.oneTimeClaim
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>

            {!tokenizeInfo.oneTimeClaim && (
              <>
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Year{" "}
                    <Tooltip
                      title="Please enter the Mint Duration in years"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationYears}
                    onchange={(e: any) =>
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        mintDurationYears: e.target.value,
                      })
                    }
                    placeholder="Mint Duration in year"
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Months{" "}
                    <Tooltip
                      title="Please enter the Mint Duration in months"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationMonths}
                    onchange={(e: any) =>
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        mintDurationMonths: e.target.value,
                      })
                    }
                    placeholder="Mint Duration in months"
                    className="form-control"
                    type="text"
                  />
                </Col>{" "}
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Days{" "}
                    <Tooltip
                      title="Please enter the Mint Duration in days"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>{" "}
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationDays}
                    onchange={(e: any) =>
                      setTokenizeInfo({
                        ...tokenizeInfo,
                        mintDurationDays: e.target.value,
                      })
                    }
                    placeholder="Mint Duration in days"
                    className="form-control"
                    type="text"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Project Proponent Information
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={12} md={12}>
              <div
                className={`d-flex justify-content-start ${
                  isMediumMobile
                    ? "flex-column align-items-start gap-2 "
                    : "align-items-center gap-4 "
                } `}
              >
                <p
                  className={`mb-0`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Were the Credits claimed by you on your project?{" "}
                  <Tooltip
                    title="If you own the project you're claiming for, select 'Yes'. Otherwise, select 'No' and provide the project owner's details."
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#475469",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 16,
                        marginLeft: 5,
                        marginBottom: 3,
                      }}
                    />
                  </Tooltip>
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setExtras({
                        ...extras,
                        status: true,
                      });
                    }}
                    style={{
                      border: extras.status
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: extras.status ? "#00abff" : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setExtras({
                        ...extras,
                        status: false,
                      });
                    }}
                    style={{
                      border: !extras.status
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !extras.status ? "#00abff" : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>

            {!extras.status && (
              <>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Name{" "}
                    <Tooltip
                      title="Please enter the Project Proponent Name"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={extras.projectProponentName}
                    onchange={(e: any) =>
                      setExtras({
                        ...extras,
                        projectProponentName: e.target.value,
                      })
                    }
                    placeholder="Enter Project Proponent Name"
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Address{" "}
                    <Tooltip
                      title="Please enter the Project Proponent Address"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={extras.projectProponentAddress}
                    onchange={(e: any) =>
                      setExtras({
                        ...extras,
                        projectProponentAddress: e.target.value,
                      })
                    }
                    placeholder="Enter Project Proponent Address"
                    className="form-control"
                    type="text"
                  />
                </Col>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Phone Number{" "}
                    <Tooltip
                      title="Please enter the Project Proponent Phone Number"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomPhoneInput
                    label=""
                    value={
                      extras.projectProponentCode + extras.projectProponentPhone
                    }
                    onChange={(value) => {
                      const [code, phone] = value.split("-");
                      setExtras({
                        ...extras,
                        projectProponentCode: code || "",
                        projectProponentPhone: phone || "",
                      });
                    }}
                    error={""}
                    defaultCountry="in"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Accrediation Information{" "}
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={12} md={12}>
              <Label>
                Numbers of Credit{" "}
                <Tooltip
                  title="Please enter the Numbers of Credit"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <div
                style={{
                  backgroundColor: "#323d4e",
                  border: "1px solid #444e5d",
                  borderRadius: 12,
                  padding: 10,
                }}
              >
                <Editor
                  wrapperClassName="dark-theme"
                  editorClassName="dark-theme"
                  toolbarClassName="toolbar"
                  placeholder="Additional Accreditations"
                  editorState={tokenizeInfo.additionalAccreditations}
                  onEditorStateChange={(e) => {
                    setTokenizeInfo({
                      ...tokenizeInfo,
                      additionalAccreditations: e,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <div
            className={`d-flex mt-3 mb-3 ${
              isMobile ? "justify-content-center" : "justify-content-end"
            } align-items-center gap-3`}
          >
            <CustomButton
              placeholder="Cancel"
              style={{ width: isMobile ? "100%" : "auto" }}
              color="#323d4e"
              type="button"
              divclassName={` ${isMobile ? "w-100" : ""}`}
              onClick={() => {
                if (location?.state?.edit) {
                  navigate("/myrequests");
                } else {
                  navigate("/wallet");
                }
              }}
            />
            <CustomButton
              placeholder="Submit"
              style={{ width: isMobile ? "100%" : "auto" }}
              color="#00abff"
              type="submit"
              divclassName={` ${isMobile ? "w-100" : ""}`}
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TokenizeCredit;
