/* eslint-disable */
import { useEffect, useRef } from "react";
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  LibrarySymbolInfo,
  SearchSymbolResultItem,
  IBasicDataFeed,
  OnReadyCallback,
} from "../../charting_library";
import * as React from "react";
import { BASE_URL } from "../../helpers/api_helper";
import { Typography } from "@mui/material";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

interface TVChartContainerProps {
  symbol: string;
  resizeMode?: boolean;
}

export const ChartConatiner = (props: TVChartContainerProps) => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol: props.symbol,
    // symbol: "AAPL",
    interval: "1" as ResolutionString,
    datafeedUrl: BASE_URL,
    // datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {
      type: "heikenashi", // Set the main series type to Heikin Ashi
      hollowCandle: false, // Heikin Ashi candles are not hollow
    },
  };

  useEffect(() => {
    const customDatafeed: IBasicDataFeed = {
      onReady: (callback: OnReadyCallback) => {
        setTimeout(() =>
          callback({
            supported_resolutions: [
              "1",
              "5",
              "15",
              "30",
              "60",
              "1D",
              "1W",
              "1M",
            ] as ResolutionString[],
          })
        );
      },
      getBars: async (
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        periodParams: { from: number; to: number; firstDataRequest: boolean },
        onHistoryCallback: (
          bars: {
            time: number;
            open: number;
            high: number;
            low: number;
            close: number;
            volume: number;
          }[],
          meta: { noData: boolean }
        ) => void,
        onErrorCallback: (error: string) => void
      ) => {
        try {
          const { from, to } = periodParams;
          const response = await fetch(
            `${defaultProps.datafeedUrl}/history?symbol=${symbolInfo.ticker}&from=${from}&to=${to}&resolution=${resolution}`
          );
          const data = await response.json();

          if (data.s === "ok" && data.t.length > 0) {
            const bars = data.t.map((time: number, index: number) => ({
              time: time * 1000,
              open: data.o[index],
              high: data.h[index],
              low: data.l[index],
              close: data.c[index],
              volume: data.v[index],
            }));
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback([], { noData: true });
          }
        } catch (error: any) {
          console.error("Error fetching data:", error);
          onErrorCallback(error.toString());
        }
      },
      searchSymbols: async (
        userInput: string,
        exchange: string,
        symbolType: string,
        onResult: (result: SearchSymbolResultItem[]) => void
      ) => {
        const response = await fetch(
          `${defaultProps.datafeedUrl}/search?query=${userInput}`
        );
        const data = await response.json();

        console.log("********************************", data);
        const symbols: SearchSymbolResultItem[] = data || [];
        onResult(symbols);
      },
      resolveSymbol: (
        symbolName: string,
        onResolve: (symbolInfo: LibrarySymbolInfo) => void,
        onError: (error: string) => void
      ) => {
        const symbolInfo: LibrarySymbolInfo = {
          name: symbolName,
          full_name: symbolName,
          description: symbolName,
          type: "Blockchain",
          session: "24x7",
          timezone: "Asia/Kolkata",
          exchange: "CENZEX",
          listed_exchange: "CENZEX",
          format: "price",
          minmov: 1,
          pricescale: 100,
          has_intraday: true,
          supported_resolutions: [
            "1",
            "5",
            "15",
            "30",
            "60",
            "1D",
            "1W",
            "1M",
          ] as ResolutionString[],
          data_status: "streaming",
          ticker: symbolName,
        };
        onResolve(symbolInfo);
      },
      subscribeBars: (
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        onRealtimeCallback: (bar: {
          time: number;
          open: number;
          high: number;
          low: number;
          close: number;
          volume: number;
        }) => void,
        subscriberUID: string,
        onResetCacheNeededCallback: () => void
      ) => {
        const interval = setInterval(async () => {
          try {
            const to = Math.floor(Date.now() / 1000);
            const from = to - getSecondsFromResolution(resolution);
            const response = await fetch(
              `${defaultProps.datafeedUrl}/history?symbol=${symbolInfo.ticker}&from=${from}&to=${to}&resolution=${resolution}`
            );
            const data = await response.json();

            if (data.s === "ok" && Array.isArray(data.t) && data.t.length > 0) {
              const latestBar = {
                time: data.t[data.t.length - 1] * 1000,
                open: parseFloat(data.o[data.o.length - 1]),
                high: parseFloat(data.h[data.h.length - 1]),
                low: parseFloat(data.l[data.l.length - 1]),
                close: parseFloat(data.c[data.c.length - 1]),
                volume: parseFloat(data.v[data.v.length - 1]),
              };
              onRealtimeCallback(latestBar);
            }
          } catch (error) {
            console.error("Error fetching real-time data:", error);
          }
        }, getIntervalFromResolution(resolution));

        return () => clearInterval(interval);
      },
      unsubscribeBars: (subscriberUID: string) => {
        // Implement if needed
      },
    };

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      datafeed: customDatafeed,
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      timezone: "Asia/Kolkata",
      locale: getLanguageFromURL() || "en",
      disabled_features: [],
      enabled_features: ["study_templates", "use_localstorage_for_settings"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      overrides: {
        "mainSeriesProperties.style": 8, // 8 represents Heikin Ashi style (may vary, check TradingView documentation)
      },
      theme: "dark",
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );
        button.innerHTML = "Check API";
      });
    });

    return () => {
      tvWidget.remove();
    };
  });

  return (
    <>
      {props.resizeMode ? (
        <div
          style={{
            height: "100%",
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            border: "1px solid #313d4f",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: 24, lineHeight: "20px", fontWeight: 700 }}
          >
            Trading{" "}
          </Typography>

          <div
            style={{ height: "calc(100% - 50px)" }}
            ref={chartContainerRef}
            className={"TVChartContainer mt-3"}
          />
        </div>
      ) : (
        <div
          style={{ height: "40vh" }}
          ref={chartContainerRef}
          className={"TVChartContainer"}
        />
      )}
    </>
  );
};

// Helper functions
function getSecondsFromResolution(resolution: string): number {
  const numericPart = parseInt(resolution);
  if (isNaN(numericPart)) {
    switch (resolution) {
      case "1D":
        return 86400;
      case "1W":
        return 604800;
      case "1M":
        return 2592000;
      default:
        return 60;
    }
  }
  return numericPart * 60;
}

function getIntervalFromResolution(resolution: string): number {
  const seconds = getSecondsFromResolution(resolution);
  return Math.min(seconds * 1000, 60000); // Update at most every minute
}
