import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import { WalletChartProps } from "../../helpers/types/WalletType";

Highcharts3D(Highcharts);

export const WalletChart: React.FC<WalletChartProps> = ({ walletData }) => {
  const chartRef = useRef<Highcharts.Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    if (walletData.length > 0) {
      const options: Highcharts.Options = {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
        
          },

          backgroundColor: "transparent",
        },
        title: undefined,
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            // borderRadius: 8,
            dataLabels: [
              {
                enabled: true,
                style: {
                  padding: "20px",
                },
                format: "{point.name}",
              },
              {
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  fontSize: "0.9em",
                  padding: "-15px",
                },
              },
            ],
            showInLegend: true,
          },
        },
        subtitle: {
          text: undefined, // Remove the chart title by setting it to null
        },
        series: [
          {
            type: "pie",
            name: "Amount",
            innerSize: "65%",

            data: walletData?.filter((item) => item.amount > 0).map((item) => [item.name, item.amount]),
          },
        ] as any,
      };

      chartRef.current = Highcharts.chart("chart-container", options);
    }
  }, [walletData]);

  return <div id="chart-container" style={{ height: "270px" }} />;
};

export default WalletChart;
