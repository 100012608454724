import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  BASE_URL,
  // GET_ALL_TRADE_PAIRS_EP,
  SEARCHTRADEPAIR,
} from "../../helpers/api_helper";
import {
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { debounce } from "lodash"; // Ensure lodash is installed or use a custom debounce function
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { TradingData } from "../../helpers/types/contextType";

export interface TradePair {
  id: number | undefined;
  name: string | undefined;
  full_name: string | undefined;
}

interface TradePairSelectorProps {
  allTradePairs: TradePair[] | null;
  selectedTradePair: {
    label: string;
    value: TradePair;
  } | null;
  selectedRegion: string;
  setSelectedTradePair: React.Dispatch<
    React.SetStateAction<{ label: string; value: TradePair } | null>
  >;
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiAutocomplete-input": {
              color: "white", // Set the text color to white
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white", // Set the outline color to bright white
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white", // Set the outline color to bright white on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white", // Set the outline color to bright white when focused
            },
            backgroundColor: "rgb(0 171 255 / 80%)", // Set the background color
          },
        },

        paper: {
          backgroundColor: "#18202f", // Set the background color of the dropdown
        },

        option: {
          "&": {
            backgroundColor: "#18202f",
          },
          "&:hover": {
            backgroundColor: "#48576c",
          },
          "&[aria-selected='true']": {
            backgroundColor: "#00abff !important", // Force background color for selected option
          },
          "&[aria-selected='true']:hover": {
            backgroundColor: "#00abff !important", // Force background color for selected option on hover
          },
        },
      },
    },
  },
});

const TradePairSelectorComponent: React.FC<TradePairSelectorProps> = ({
  allTradePairs,
  selectedTradePair,
  selectedRegion,
  setSelectedTradePair,
}) => {
  console.log("allTradePairs", allTradePairs);
  const [tradePairs, setTradePairs] = useState<TradePair[]>(
    allTradePairs || []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: TradePair;
  } | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string, pselectedRegion: string) => {
      searchTradePairs(query, pselectedRegion);
    }, 350),

    []
  );
  // Debounce search function

  useEffect(() => {
    if (inputValue.trim()) {
      debouncedSearch(inputValue.trim(), selectedRegion);
    } else {
      setTradePairs(allTradePairs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, debouncedSearch, allTradePairs]);

  useEffect(() => {
    if (selectedTradePair) {
      setSelectedValue(selectedTradePair);
      setInputValue(selectedTradePair.label);
    }
  }, [selectedTradePair]);

  const searchTradePairs = async (query: string, pselectedRegion: string) => {
    setLoading(true);
    try {
      const requestBody: any = { full_name: query };
      console.log("pselectedRegion:", pselectedRegion);
      console.log("selected Region header searchtradepair", selectedRegion);
      if (selectedRegion.length > 0) {
        console.log(
          "search selected Region header is empty string",
          selectedRegion
        );
      }

      // Add region to the request body if selectedRegion is specified and not 'ALL'
      if (pselectedRegion.length > 0 && pselectedRegion !== "ALL") {
        requestBody.region = pselectedRegion;
      }

      const response = await axios.post(
        `${BASE_URL}${SEARCHTRADEPAIR}`,
        requestBody
      );
      console.log("search response data", response.data);
      setTradePairs(mapResponseToOptions(response.data));
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to search trade pairs:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const mapResponseToOptions = (data: any[]) =>
    data.map((pair) => ({
      id: pair.id,
      name: pair.name,
      full_name: pair.full_name,
    }));

  return (
    <ThemeProvider theme={darkTheme}>
      <Autocomplete
        options={tradePairs}
        sx={{
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "red !important",
          },
          '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
            backgroundColor: "red !important",
          },
        }}
        className="w-100"
        getOptionLabel={(option) => option.name || ""}
        style={{ width: 300 }}
        loading={loading}
        loadingText={<CircularProgress color="inherit" size={20} />}
        noOptionsText="No trade pairs found"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Trade Pairs"
            variant="outlined"
            value={inputValue}
            InputProps={{
              ...params.InputProps,
              style: {
                ...params.InputProps,
                backgroundColor: "#323d4e",
                color: "white", // Attempt to make text color transparent
                caretColor: "white", // Ensure the caret is visible
              },
              // Apply styles directly to the inner <input> element
              inputProps: {
                ...params.inputProps,
                style: {
                  color: "white", // Make the text color transparent
                  caretColor: "white", // Ensure the caret is visible
                  backgroundColor: "#323d4e", // Attempt to make background transparent
                  border: "none", // Attempt to remove any border
                  // Any other styles to visually hide the element
                },
              },
            }}
          />
        )}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            const newSelectedTradePair = {
              label: newValue.name || "",
              value: newValue,
            };
            setSelectedValue(newSelectedTradePair);
            setSelectedTradePair(newSelectedTradePair);
            navigate(`/trading/${newValue.id}`, { replace: true });
          }
        }}
        value={selectedTradePair?.value || undefined}
        inputValue={inputValue}
        key={selectedTradePair?.value?.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableClearable
        clearOnEscape={false}
        filterOptions={(options) => options}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;
          return (
            <li key={key} {...otherProps}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{}}>{option.name}</span>{" "}
                {/* Adjust marginRight to increase the gap */}
                <Tooltip
                  title={option.full_name}
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </li>
          );
        }}
        ListboxProps={{
          style: {
            maxHeight: "250px", // Adjust this value as needed
            overflow: "auto", // Enable scrolling
          },
        }}
      />
    </ThemeProvider>
  );
};

export default TradePairSelectorComponent;
