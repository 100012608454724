import React, { useEffect, useState } from "react";
import {
  // APPROVE_CREDIT_REQUEST,
  BASE_URL,
  GET_ALL_CORP_REQUESTS,
  GET_CORPORATE_REQUESTS_COUNT,
} from "../../helpers/api_helper";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminFilterType,
  AdminRequestType,
  CorporateRequestType,
} from "../../helpers/types/AdminScreenType";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import { Col, Row } from "reactstrap";
import UpdateStatusModal from "./UpdateStatusModal";
import ViewDocumentModal from "./ViewDocumentModal";

const CorporateRequestManagement = () => {
  // eslint-disable-next-line import/no-unresolved
  const { isMobile } = useBreakPoints();
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const [open, setOpen] = useState<number | null>(null);

  // ERROR HANDLER
  const handleError = useErrorHandler();
  // eslint-disable-next-line
  const [adminRequest, setAdminRequests] = useState<AdminRequestType[]>([]);

  // eslint-disable-next-line import/no-unresolved
  const [corporateRequest, setCorporateRequests] = useState<
    CorporateRequestType[]
  >([]);
  const [totalRequests, setTotalRequests] = useState<Number | null>(null);
  const [modalStatus, setModalStatus] = useState({
    Update: false,
    ViewFile: false,
  });
  console.log(modalStatus, "modalStatus");
  const [corporationID, setCorporationID] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<AdminFilterType>({
    page: 1,
    req_type: {
      label: "All",
      value: "all",
    },
    status: {
      label: "All",
      value: "all",
    },
    userName: null,
    asset_name: null,
  });
  const [selectedRequesteeID, setSelectedRequesteeID] = useState(-1);

  useEffect(() => {
    if (jwt !== null) {
      getAllRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, modalStatus, filters]);

  const getAllRequests = () => {
    axios
      .post(
        BASE_URL + GET_ALL_CORP_REQUESTS,
        {
          page: filters.page - 1,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setCorporateRequests(response.data);
        getAllRequestsCount();
      })
      .catch((error) => {
        console.error("Error while getting all requests for admin", error);
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          handleError(error);
        }
      });
  };

  const getAllRequestsCount = () => {
    axios
      .post(
        BASE_URL + GET_CORPORATE_REQUESTS_COUNT,
        {},
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setTotalRequests(response.data);
      })
      .catch((error) => {
        console.error(
          "Error while getting all requests count for admin",
          error
        );
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          toast.error("Error while getting requests count for admin");
        }
      });
  };

  // function handleApproveRetire(row: any) {
  //   axios
  //     .post(
  //       BASE_URL + APPROVE_CREDIT_REQUEST,
  //       {
  //         request_id: row.id,
  //         amount: row.num_of_credits,
  //         asset_id: row.asset_id,
  //         token_id: row.token_id,
  //       },
  //       {
  //         headers: {
  //           Authorization: jwt,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setAdminRequests(response.data);
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         toast.error("Session expired, please login again", {
  //           toastId: "sessionExp",
  //         });
  //         localStorage.clear();
  //         navigate("/logout");
  //       } else {
  //         console.error("Error while approving retire request", error);
  //         toast.error("Error while approving retire request");
  //       }
  //     });
  // }

  return (
    <div className="mt-3 p-4 pt-0">
      <div
        className={` d-flex  w-100 ${
          isMobile
            ? "flex-column gap-2 justify-content-start align-items-start"
            : " justify-content-between align-items-center"
        }`}
      >
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
          }}
        >
          Corporate Request Management{" "}
        </span>
        <div className="d-flex justify-content-center align-items-center ">
          <p
            className="mb-0"
            style={{
              background: "#3cab3d",
              padding: "5px 10px",
              borderRadius: "9px 0px 0px 9px",
              fontWeight: 600,
              textWrap: "nowrap",
            }}
          >
            Total Corporate Request
          </p>
          <p
            className="mb-0"
            style={{
              background: "#008dd2",
              padding: "5px 15px",
              borderRadius: "0px 9px 9px 0px",
              fontWeight: 600,
              textWrap: "nowrap",
            }}
          >
            {totalRequests ? totalRequests?.toString() : "-"}
          </p>
        </div>
      </div>

      <div className="admin-table-2 table-responsive">
        <table className="accordion-table" style={{ fontSize: 13 }}>
          <thead>
            <tr>
              <th className="expand"></th>
              <th style={{ minWidth: 40, maxWidth: 40 }}>ID</th>
              <th style={{ textWrap: "nowrap" }}>Company Name</th>
              <th style={{ textWrap: "nowrap" }}>Company Email</th>
              <th style={{ textWrap: "nowrap", minWidth: 150, maxWidth: 150 }}>
                Status
              </th>
              <th style={{ textWrap: "nowrap", minWidth: 150, maxWidth: 150 }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {corporateRequest.map((row, i) => (
              <>
                <tr key={i} className="header-level">
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                    className="expand"
                  >
                    {open === i ? (
                      <i className="fa fa-caret-down"></i>
                    ) : (
                      <i className="fa fa-caret-right"></i>
                    )}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.corporate_id}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.company_name}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.company_email}
                  </td>

                  <td>
                    <span
                      onClick={() => setOpen(open === i ? null : i)}
                      style={{
                        cursor: "pointer",
                        background:
                          row?.status === "approved"
                            ? "#1ccab8"
                            : row?.status === "pending"
                            ? "rgb(77 202 28)"
                            : row?.status === "requested more information"
                            ? "rgb(202 94 28)"
                            : row?.status === "rejected"
                            ? "#cb1b2c"
                            : "#1ccab8",
                        padding: "5px 29px",
                        borderRadius: "20px",
                      }}
                    >
                      {row?.status.toUpperCase() ===
                      "REQUESTED MORE INFORMATION"
                        ? "Req-More-Info"
                        : row?.status}
                    </span>
                  </td>
                  <td>
                    <div className="gap-3 mx-2 d-flex justify-content-center align-items-center">
                      <CustomButton
                        placeholder="Update Status"
                        onClick={() => {
                          setCorporationID(row.corporate_id);
                          setModalStatus((prev) => ({
                            ...prev,
                            Update: true,
                          }));
                          setSelectedRequesteeID(
                            row.corporate_profile_details.user_id
                          );
                        }}
                        style={{ textWrap: "nowrap" }}
                        color="#008DD2"
                      />
                      <CustomButton
                        placeholder="View Documents"
                        onClick={() => {
                          console.log(row.corporate_id, "row.corporate_id");
                          setCorporationID(row.corporate_id);
                          setModalStatus((prev) => ({
                            ...prev,
                            ViewFile: true,
                          }));
                        }}
                        style={{ textWrap: "nowrap" }}
                        color="#008DD2"
                      />
                    </div>
                  </td>
                </tr>
                <tr key={i} className="sub-level">
                  <td className="expand"></td>
                  <td colSpan={12}>
                    <div className={`${open === i ? "open" : "close"} w-100`}>
                      <Row
                        style={{
                          backgroundColor: "#1b2431",
                          borderRadius: "10px",
                          margin: "10px 5px",
                          padding: "0px 23px 20px 10px",
                        }}
                      >
                        <Col
                          className=""
                          style={{ marginTop: 20, paddingRight: 0 }}
                          md={12}
                        >
                          <div
                            className="d-flex justify-content-between align-items-center w-100"
                            style={{}}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              COMPANY INFORMATION
                            </span>
                          </div>
                          <Row className={`mt-2 ${isMobile ? "gap-2" : ""}`}>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Address Line 1 :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_profile_details
                                    .hq_address_line_1
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Address Line 2 :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_profile_details
                                    .hq_address_line_2
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Address Line 3 :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_profile_details
                                    .hq_address_line_3
                                }
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              md={4}
                              className={`${
                                isMobile ? "text-start mt-3" : "text-start"
                              }`}
                            >
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  City :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.city}
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              md={4}
                              className={`${
                                isMobile ? "text-start mt-3" : "text-start"
                              }`}
                            >
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Country :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.country}
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              md={4}
                              className={`${
                                isMobile ? "text-start mt-3" : "text-start"
                              }`}
                            >
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Pincode :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.pin_code}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className=""
                          style={{ marginTop: 20, paddingRight: 0 }}
                          md={12}
                        >
                          <div
                            className="d-flex justify-content-between align-items-center w-100"
                            style={{}}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              PAN & TAN DETAILS{" "}
                            </span>
                          </div>
                          <Row className={`mt-2 ${isMobile ? "gap-2" : ""}`}>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  PAN Number :
                                </span>{" "}
                                &nbsp;{" "}
                                {row.corporate_profile_details.pan_number}
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  TAN Number :
                                </span>{" "}
                                &nbsp;{" "}
                                {row.corporate_profile_details.tan_number}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className=""
                          style={{ marginTop: 20, paddingRight: 0 }}
                          md={12}
                        >
                          <div
                            className="d-flex justify-content-between align-items-center w-100"
                            style={{}}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              BANK DETAILS{" "}
                            </span>
                          </div>
                          <Row className={`mt-2 ${isMobile ? "gap-2" : ""}`}>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Bank Name :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.bank_name}
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  IFSC Code :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.ifsc_code}
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Account Number :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_profile_details
                                    .bank_account_number
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  UPI ID :
                                </span>{" "}
                                &nbsp; {row.corporate_profile_details.upi_id}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className=""
                          style={{ marginTop: 20, paddingRight: 0 }}
                          md={12}
                        >
                          <div
                            className="d-flex justify-content-between align-items-center w-100"
                            style={{}}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              REPRESENTATIVE DETAILS{" "}
                            </span>
                          </div>
                          <Row className={`mt-2 ${isMobile ? "gap-2" : ""}`}>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Name :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .name
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Type :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .type
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Designation :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .designation
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Mobile Number :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .mobile_number
                                }
                              </div>
                            </Col>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  Email :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .email
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className=""
                          style={{ marginTop: 20, paddingRight: 0 }}
                          md={12}
                        >
                          <div
                            className="d-flex justify-content-between align-items-center w-100"
                            style={{}}
                          >
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                              IDENTIFICATION DETAILS{" "}
                            </span>
                          </div>
                          <Row className={`mt-2 ${isMobile ? "gap-2" : ""}`}>
                            <Col lg={4} md={4} className="text-start">
                              <div className="text-white">
                                <span style={{ color: "#999999" }}>
                                  {" "}
                                  AADHAR Number :
                                </span>{" "}
                                &nbsp;{" "}
                                {
                                  row.corporate_director_representative_details
                                    .aadhar_number
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {modalStatus.Update && (
        <UpdateStatusModal
          open={modalStatus.Update}
          onClose={() => {
            setModalStatus({ ...modalStatus, Update: false });
            setSelectedRequesteeID(-1);
            getAllRequests();
          }}
          corporateStatus={true}
          corporateID={corporationID}
          requestID={selectedRequesteeID}
        />
      )}
      {modalStatus.ViewFile && (
        <ViewDocumentModal
          open={modalStatus.ViewFile}
          onClose={() => {
            setModalStatus({ ...modalStatus, ViewFile: false });
            setSelectedRequesteeID(-1);
            getAllRequests();
          }}
          corporateID={corporationID}
        />
      )}
    </div>
  );
};

export default CorporateRequestManagement;
