import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../helpers/types/contextType";
import { SocketConectionContext } from "../../hooks/SocketConectionContext/SocketConectionContext";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { Typography } from "@mui/material";
// Initialize the necessary module
HighchartsMore(Highcharts);
export interface MarketDepthDataTypes {
  color: string;
  data: number[][];
  name: string;
}
interface MarketDepthChartProps {
  traidID: number;
  resizeMode?: boolean;
}

const MarketDepthContainer = ({
  traidID,
  resizeMode,
}: MarketDepthChartProps) => {
  const chartRef = useRef<any>(null); // Use 'any' for the ref
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  // const { userId } = userContext;

  const socketConnectionContext = useContext(SocketConectionContext);
  if (!socketConnectionContext) {
    throw new Error("socketConnectionContext is undefined");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { webSocketService, setWebSocketService } = socketConnectionContext;
  const [marketDepthData, setMarketDepthData] = useState<
    MarketDepthDataTypes[] | null
  >(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(300); // Default height

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight - 100);
      }
    };

    // Initial height update
    updateHeight();

    // Set up resize observer
    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const [options, setOptions] = useState<Highcharts.Options>({
    chart: {
      type: "area",
      zooming: {
        type: "xy",
      },
      height: resizeMode ? containerHeight : 200,
      backgroundColor: "#273142", // Dark background
    },
    title: {
      text: "",
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0,
      plotLines: [
        {
          color: "#707073", // Slightly lighter line color
          value: 0.1523,
          width: 1,
          label: {
            text: "Actual price",
            rotation: 90,
            style: {
              color: "#E0E0E3", // Light text color
            },
          },
        },
      ],
      title: {
        text: "Price",
        style: {
          color: "#E0E0E3", // Light text color
        },
      },
      lineColor: "#707073",
      tickColor: "#707073",
      labels: {
        style: {
          color: "#E0E0E3", // Light text color
        },
      },
    },
    yAxis: [
      {
        lineWidth: 1,
        gridLineWidth: 1,
        gridLineColor: "#707073",
        title: { text: "" },
        tickWidth: 1,
        tickLength: 5,
        tickPosition: "inside",
        labels: {
          align: "left",
          x: 8,
          style: {
            color: "#E0E0E3", // Light text color
          },
        },
      },
      {
        opposite: true,
        linkedTo: 0,
        lineWidth: 1,
        gridLineWidth: 0,
        title: { text: "" },
        tickWidth: 1,
        tickLength: 5,
        tickPosition: "inside",
        labels: {
          align: "right",
          x: -8,
          style: {
            color: "#E0E0E3", // Light text color
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.2,
        lineWidth: 1,
        step: "center",
      },
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      style: {
        color: "#F0F0F0",
      },
      headerFormat:
        // eslint-disable-next-line no-useless-concat
        '<span style="font-size=10px;">Price: ' + "{point.key}</span><br/>",
      valueDecimals: 2,
    },
    series: [],
  });

  useEffect(() => {
    if (webSocketService !== null) {
      // Define the message handler
      const handleMessage = (newData: any) => {
        // console.log("Maker Depth", newData, newData.market_depth);
        if (newData && newData.market_depth !== undefined) {
          setMarketDepthData(newData.market_depth);
        }
      };
      webSocketService.setOnMessageHandler(handleMessage);
    }
  }, [webSocketService, traidID]);

  useEffect(() => {
    if (chartRef.current && marketDepthData) {
      // Update the options object with the new data
      const updatedOptions: Highcharts.Options = {
        ...options,
        series: [
          {
            name: marketDepthData[0].name,
            type: "area",
            data: marketDepthData[0].data,
            color: "#35e3d1",
          },
          {
            name: marketDepthData[1].name,
            type: "area",
            data: marketDepthData[1].data,
            color: "#ff656d",
          },
        ],
      };

      setOptions(updatedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketDepthData]);

  return (
    <>
      {resizeMode ? (
        <div
          ref={containerRef}
          style={{
            height: "100%",
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            border: "1px solid #313d4f",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: 24, lineHeight: "20px", fontWeight: 700 }}
          >
            Market Depth
          </Typography>

          <div className="mt-3" style={{ flex: 1 }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          </div>
        </div>
      ) : (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </div>
      )}
    </>
  );
};

export default MarketDepthContainer;
