// src/hooks/SocketConectionContext/SocketConectionProvider.tsx
import React, { useState, useEffect, FC, ReactNode } from "react";
import WebSocketService from "../../services/WebSocketsService";
import { SocketConectionContext } from "./SocketConectionContext";
import { useContext } from "react";
import {
  SOCKET_SUB_TRADING,
  SOCKET_SUB_TRADING_MARKET,
} from "../../helpers/api_helper";
import { UserContext } from "../../helpers/types/contextType";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";

interface UserProviderProps {
  children: ReactNode;
  selectedComponent?: any;
  selectedComponentResize?: any;
}

const SocketConectionProvider: FC<UserProviderProps> = ({
  children,
  selectedComponent,
  selectedComponentResize,
}) => {
  const [webSocketService, setWebSocketService] =
    useState<WebSocketService | null>(null);

  const userContext = useContext(UserContext);
  const { userId, selectedTradePair, setSelectedTradePair } = userContext || {};
  const role = GetCookies(CookieItems.ROLE);

  useEffect(() => {
    if (userId && userId.jwt && !webSocketService) {
      const wsService = new WebSocketService(userId.jwt);
      setWebSocketService(wsService);
    }
  }, [userId]);

  useEffect(() => {
    if (webSocketService && selectedTradePair?.value?.id) {
      console.log("running 99990", selectedTradePair?.value?.id)
      if (role?.startsWith("unverified")) {
        webSocketService.connect(
          SOCKET_SUB_TRADING_MARKET,
          selectedTradePair.value.id
        );
      } else {
        webSocketService.connect(
          SOCKET_SUB_TRADING,
          selectedTradePair.value.id
        );
      }
    }
  }, [webSocketService, selectedTradePair?.value?.id]);

  useEffect(() => {
    return () => {
      setWebSocketService(null);
      setSelectedTradePair!(null)
    };
  }, []);

  return (
    <SocketConectionContext.Provider
      value={{ webSocketService, setWebSocketService }}
    >
      {children}
    </SocketConectionContext.Provider>
  );
};

export default SocketConectionProvider;
