import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import {
  MyTransactionsFilterType,
  MyTransactionsType,
} from "../../helpers/types/MyTransactionsTypes";
import axios from "axios";
import { BASE_URL, GET_MY_TRANSACTIONS } from "../../helpers/api_helper";
import moment from "moment";
import { toast } from "react-toastify";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, Label, Table } from "reactstrap";
import { Box, TablePagination, createTheme } from "@mui/material";
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import DownloadIcon from "../../assets/svg/Download-Icon.svg";
import Drawer from "@mui/material/Drawer";
import CustomDropdown from "../../Component/CustomDropdown";
import CustomInputBox from "../../Component/CustomInputBox";
import CustomButton from "../../Component/CustomButton";
// import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "react-bootstrap";
import { ConvertToUnixUtc } from "../../helpers/time_converter_helper";

const TypeOptions = [
  { label: "All", value: "All" },
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
  { label: "Deposit", value: "deposit" },
  { label: "Withdraw", value: "withdrawal" },
];

const Transactions = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const { isMobile } = useBreakPoints();
  const [downloadCSV, setDownloadCSV] = useState<MyTransactionsType[]>([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [myTransactions, setMyTransactions] = useState<MyTransactionsType[]>(
    []
  );
  const [filters, setFilters] = useState<MyTransactionsFilterType>({
    from_date: null,
    to_date: null,
    trade_pair_id: null,
    type: { label: "All", value: "All" },
    page: 0,
  });

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "rgb(114,114,114)",
        light: "white",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            // General styles for icons
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
      },
      MuiSvgIcon: {
        // Targeting all SVG icons
        styleOverrides: {
          root: {
            color: "white", // This will change the color of all SVG icons including the date picker icon
          },
          colorSecondary: {
            color: "white",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          colorPrimary: {
            color: "white",
          },
          colorSecondary: {
            color: "white",
          },
          colorAction: {
            color: "white",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (jwt) {
      axios
        .post(
          BASE_URL + GET_MY_TRANSACTIONS,
          {
            page: filters.page,
            from_date: filters.from_date?.unix(),
            to_date: filters.to_date?.unix(),
            type:
              filters.type?.value !== "All"
                ? filters.type?.value?.toLocaleLowerCase()
                : null,

            trade_pair_id: filters.trade_pair_id,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          setMyTransactions(res.data);
        })
        .catch((err) => {
          console.error("Error while get my transactions", err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            handleError(new Error("Error while get my transactions"));
          }
        });
      axios
        .post(
          BASE_URL + GET_MY_TRANSACTIONS,
          {
            from_date: filters.from_date
              ? ConvertToUnixUtc(filters.from_date.format("YYYY-MM-DD"))
              : null,
            to_date: filters.to_date
              ? ConvertToUnixUtc(filters.to_date.format("YYYY-MM-DD"))
              : null,
            type:
              filters.type?.value !== "All"
                ? filters.type?.value?.toLocaleLowerCase()
                : null,

            trade_pair_id: filters.trade_pair_id,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          setDownloadCSV(res.data);
        })
        .catch((err) => {
          console.error("Error while get my transactions", err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            handleError(new Error("Error while get my transactions"));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, filters, handleError]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({ ...filters, page: newPage });
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
          }}
        >
          My Transactions{" "}
        </span>

        <div
          style={{ paddingRight: 30 }}
          className="d-flex justify-content-end align-items-center"
        >
          <div>
            {!isMobile && (
              <TablePagination
                component="div"
                count={Number(downloadCSV?.length)} // Total number of rows
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={25}
                rowsPerPageOptions={[]} // Hide the rows per page options
                sx={{
                  color: "white",
                  "& .css-pdct74-MuiTablePagination-selectLabel ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .css-levciy-MuiTablePagination-displayedRows ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .Mui-disabled": {
                    color: "grey",
                    opacity: 0.5,
                  },
                  "& .MuiTablePagination-actions": {
                    "& .MuiIconButton-root.Mui-disabled": {
                      color: "grey",
                    },
                  },
                }}
              />
            )}
          </div>
          <div
            style={{
              borderLeft: isMobile ? "" : "1px solid white",
              paddingLeft: 20,
              marginLeft: 10,
            }}
            className="d-flex justify-content-center align-items-center gap-3"
          >
            {/* <img
              onClick={toggleDrawer(true)}
              style={{ cursor: "pointer" }}
              src={FilterIcon}
              alt="Filter Icon"
            /> */}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                const csvData = downloadCSV;
                if (csvData !== null && csvData !== undefined) {
                  const csv = new Blob(
                    [
                      `${Object.keys(csvData[0]).join(",")}\n${csvData
                        .map((e: any) => Object.values(e).join(","))
                        .join("\n")}`,
                    ],
                    {
                      type: "text/csv;charset=utf-8;",
                    }
                  );
                  const url = window.URL.createObjectURL(csv);
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "myTransactions.csv");
                  document.body.appendChild(link);
                  link.click();
                }
              }}
              src={DownloadIcon}
              alt="Download Icon"
            />
          </div>
        </div>
      </div>

      <div className="">
        <Table
          className={`${isMobile ? "mt-3" : ""}`}
          responsive
          bordered={false}
        >
          <thead>
            <tr className="text-center">
              <th
                style={{
                  width: "25%",
                  minWidth: 200,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "1px solid #444e5d",
                  borderRight: "none",
                  borderRadius: "12px 0px 0px 12px",
                }}
              >
                Name
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 150,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Amount
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 150,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Type
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 200,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "1px solid #444e5d",
                  borderRadius: "0px 12px 12px 0px",
                }}
              >
                Date - Time
              </th>
            </tr>
          </thead>
          <tbody>
            {myTransactions?.map((item: any) => (
              <tr className="text-center">
                <td
                  style={{
                    background: "#1b2431",
                    paddingLeft: 15,
                    color: "white",
                    fontSize: 12,
                    border: "none",
                  }}
                >
                  {item?.name || ""}
                </td>
                <td
                  style={{
                    background: "#1b2431",
                    color: "white",
                    fontSize: 12,
                    border: "none",
                  }}
                >
                  {item?.amount || ""}
                </td>
                <td
                  style={{
                    background: "#1b2431",

                    color: "white",
                    fontSize: 12,
                    border: "none",
                    textTransform: "capitalize",
                  }}
                >
                  <span
                    style={{
                      padding: "8px 15px",
                      width: 120,
                      textAlign: "center",
                      display: "inline-block",
                      background:
                        item?.type === "sell"
                          ? "#e94953"
                          : item?.type === "buy"
                          ? "#008dd2"
                          : item?.type === "deposit"
                          ? "rgb(24 201 178)"
                          : item?.type === "withdrawal"
                          ? "rgb(237 78 28)"
                          : "",
                      color: "white",
                      borderRadius: "26px",
                      fontSize: 15,
                      lineHeight: "16.37px",
                    }}
                  >
                    {item?.type || ""}
                  </span>
                </td>
                <td
                  style={{
                    background: "#1b2431",
                    paddingLeft: 15,
                    color: "white",
                    fontSize: 12,
                    border: "none",
                  }}
                >
                  {item?.time
                    ? moment.unix(item?.time).format("DD MMMM YYYY hh:mm A")
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {isMobile && (
        <TablePagination
          component="div"
          count={Number(downloadCSV?.length)} // Total number of rows
          page={filters.page}
          onPageChange={handleChangePage}
          rowsPerPage={25}
          rowsPerPageOptions={[]} // Hide the rows per page options
          sx={{
            color: "white",
            "& .css-pdct74-MuiTablePagination-selectLabel ": {
              color: "white",
              marginBottom: 0,
            },
            "& .css-levciy-MuiTablePagination-displayedRows ": {
              color: "white",
              marginBottom: 0,
            },
            "& .Mui-disabled": {
              color: "grey",
              opacity: 0.5,
            },
            "& .MuiTablePagination-actions": {
              "& .MuiIconButton-root.Mui-disabled": {
                color: "grey",
              },
            },
          }}
        />
      )}

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ backgroundColor: "#273142", color: "white", height: "100%" }}
          role="presentation"
          //   onClick={toggleDrawer(false)}
        >
          <div className="p-4 flex-column w-100">
            <Col md={12}>
              <Label>Search by Type</Label>
              <CustomDropdown
                options={TypeOptions}
                value={filters.type}
                onChange={(e: any) => {
                  setFilters({ ...filters, type: e });
                  setTimeout(() => {
                    setDrawerOpen(false);
                  }, 200);
                }}
                isDisabled={false}
                placeholder="Search by Type"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Transaction From Date: </Label>
              <ThemeProvider theme={darkTheme}>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="From Date"
                      value={filters.from_date}
                      format="YYYY-MM-DD" // Add this line to set the date format
                      onChange={(newValue) => {
                        setFilters({ ...filters, from_date: newValue });
                        setTimeout(() => {
                          setDrawerOpen(false);
                        }, 200);
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                    <DatePicker
                      label="To Date"
                      value={filters.to_date}
                      onChange={(newValue) => {
                        setFilters({ ...filters, to_date: newValue });
                        setTimeout(() => {
                          setDrawerOpen(false);
                        }, 200);
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ThemeProvider>
            </Col>

            <Col md={12} className="mt-4">
              <Label>Search by Trade Pair</Label>
              <CustomInputBox
                type="text"
                value={filters.trade_pair_id || ""}
                placeholder="Search by Trade Pair"
                className="w-100"
                onchange={(e: any) => {
                  setFilters({ ...filters, trade_pair_id: e.target.value });
                  setTimeout(() => {
                    setDrawerOpen(false);
                  }, 1000);
                }}
              />
            </Col>
            <Col md={12} className="mt-4 text-end">
              <CustomButton
                placeholder="Clear"
                color="#323D4E"
                onClick={() => {
                  setFilters({
                    ...filters,
                    from_date: null,
                    to_date: null,
                    trade_pair_id: null,
                    type: { label: "All", value: "All" },
                  });
                }}
              />
            </Col>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default Transactions;
