import React, { useEffect, useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  APPROVE_TOKENIZE_REQUEST,
  BASE_URL,
  GET_ALL_ASSETS,
  // UPDATE_CREDIT_REQUEST_STATUS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import CustomDropdown from "../../Component/CustomDropdown";
import { AssetType } from "../../helpers/types/AdminScreenType";
import useBreakPoints from "../../hooks/useBreakPoints";

const SDGOptions = [
  {
    value: "Goal 1: No Poverty",
    label: "Goal 1: No Poverty",
  },
  {
    value: "Goal 2: Zero Hunger",
    label: "Goal 2: Zero Hunger",
  },
  {
    value: "Goal 3: Good Health and Well-being",
    label: "Goal 3: Good Health and Well-being",
  },
  {
    value: "Goal 4: Quality Education",
    label: "Goal 4: Quality Education",
  },
  {
    value: "Goal 5: Gender Equality",
    label: "Goal 5: Gender Equality",
  },
  {
    value: "Goal 6: Clean Water and Sanitation",
    label: "Goal 6: Clean Water and Sanitation",
  },
  {
    value: "Goal 7: Affordable and Clean Energy",
    label: "Goal 7: Affordable and Clean Energy",
  },
  {
    value: "Goal 8: Decent Work and Economic Growth",
    label: "Goal 8: Decent Work and Economic Growth",
  },
  {
    value: "Goal 9: Industry, Innovation, and Infrastructure",
    label: "Goal 9: Industry, Innovation, and Infrastructure",
  },
  {
    value: "Goal 10: Reduced Inequalities",
    label: "Goal 10: Reduced Inequalities",
  },
  {
    value: "Goal 11: Sustainable Cities and Communities",
    label: "Goal 11: Sustainable Cities and Communities",
  },
  {
    value: "Goal 12: Responsible Consumption and Production",
    label: "Goal 12: Responsible Consumption and Production",
  },
  {
    value: "Goal 13: Climate Action",
    label: "Goal 13: Climate Action",
  },
  {
    value: "Goal 14: Life Below Water",
    label: "Goal 14: Life Below Water",
  },
  {
    value: "Goal 15: Life on Land",
    label: "Goal 15: Life on Land",
  },
  {
    value: "Goal 16: Peace, Justice, and Strong Institutions",
    label: "Goal 16: Peace, Justice, and Strong Institutions",
  },
  {
    value: "Goal 17: Partnerships for the Goals",
    label: "Goal 17: Partnerships for the Goals",
  },
];
const ApproveTokenRequestModal = ({
  open,
  onClose,
  requestID,
}: {
  open: boolean;
  onClose: () => void;
  requestID?: number;
}) => {
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const { isTablet } = useBreakPoints();
  const [tokenizeReq, setTokenizeReq] = useState({
    assetID: {
      value: "",
      label: "",
    },
    selectedAsset: "",
    numberOfCredits: 0,
    tokenID: "",
    generateNewToken: true,
    projectName: "",
    projectType: "",
    corsia_compatible: {
      value: "not compatible",
      label: "Not Compatible",
    },
    redd_plus_plus_compatible: {
      value: "not compatible",
      label: "Not Compatible",
    },
    number_of_sdg_goals: 0,
    sdg_goals: Array<string>(),
    crs_rating: 0,
  });
  const [assets, setAssets] = useState<AssetType[]>([]);
  const [loading, setLoading] = useState(false);

  function handleApproveTokenizeReq() {
    setLoading(true);
    axios
      .post(
        BASE_URL + APPROVE_TOKENIZE_REQUEST,
        {
          request_id: requestID,
          asset_id: tokenizeReq.assetID?.value || "",
          token_id: tokenizeReq.tokenID,
          token_id_name: tokenizeReq.projectName,
          token_id_type: tokenizeReq.projectType,
          corsia_compatible: tokenizeReq.corsia_compatible?.value || "",
          redd_plus_plus_compatible:
            tokenizeReq.redd_plus_plus_compatible?.value || "",
          number_of_sdg_goals: tokenizeReq.number_of_sdg_goals,
          sdg_goals: tokenizeReq.sdg_goals.map((goal: any) => goal.value),
          crs_rating: tokenizeReq.crs_rating,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        toast.success("Credit Tokenized Request Approved Successfully");
        onClose();
      })
      .catch((error) => {
        if (!error.response.data.hasOwnProperty("duplicate")) {
          setLoading(false);
          console.log(error);
          toast.error("Error while approving credit tokenization");
          onClose();
        } else if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          toast.error(
            "Token ID name already exists for the selected TradePair"
          );
          setLoading(false);
          // togglePopup();
        }
      });
  }

  useEffect(() => {
    axios
      .post(
        BASE_URL + GET_ALL_ASSETS,
        {
          asset_id: 1,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setAssets(response.data);
        setTokenizeReq((prevState) => ({
          ...prevState,
          assetID: response.data[0].asset_id.toString(),
        }));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting asset IDs", error);
          toast.error("Error while getting asset inbformation");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal centered size="lg" isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        Approve Tokenize Credit Request
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row className={isTablet ? "gap-4" : ""}>
          <Col lg={6} md={12} className="">
            <Label>Select Asset</Label>
            <CustomDropdown
              options={assets.map((item) => {
                return {
                  value: item.asset_id.toString(),
                  label: item.asset_name,
                };
              })}
              value={tokenizeReq.assetID}
              onChange={(e: any) =>
                setTokenizeReq((prevState) => ({
                  ...prevState,
                  assetID: e,
                }))
              }
              isDisabled={false}
            />
          </Col>
          <Col lg={6} md={12} className="">
            <div className=" ">
              <p className={`mb-0`}>Do you want to create a new token ID?</p>
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => {
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      generateNewToken: true,
                    }));
                  }}
                  style={{
                    border: "none",
                    background: tokenizeReq.generateNewToken
                      ? "rgb(58 163 81)"
                      : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "5px 0px 0px 5px",
                  }}
                >
                  YES
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      generateNewToken: false,
                    }));
                  }}
                  style={{
                    border: "none",
                    background: !tokenizeReq.generateNewToken
                      ? "#00abff"
                      : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "0px 5px 5px 0px",
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          </Col>

          {tokenizeReq.generateNewToken === true && (
            <>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project Name:</Label>
                <CustomInputBox
                  type="text"
                  className=""
                  value={tokenizeReq.projectName}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      projectName: e.target.value,
                    }))
                  }
                  placeholder="Enter Project Name"
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project Type:</Label>
                <CustomInputBox
                  type="text"
                  className=""
                  value={tokenizeReq.projectType}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      projectType: e.target.value,
                    }))
                  }
                  placeholder="Enter Project Type"
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>CRS Rating :</Label>
                <CustomInputBox
                  type="text"
                  className=""
                  value={tokenizeReq.crs_rating.toString()}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      crs_rating: e.target.value,
                    }))
                  }
                  placeholder="Enter CRS Rating"
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project CORSIA Compatible?</Label>
                <CustomDropdown
                  options={[
                    {
                      value: "not compatible",
                      label: "Not Compatible",
                    },
                    {
                      value: "compatible",
                      label: "Compatible",
                    },
                  ]}
                  value={tokenizeReq.corsia_compatible}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      corsia_compatible: e,
                    }))
                  }
                  isDisabled={false}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project REDD++ Compatible?</Label>
                <CustomDropdown
                  options={[
                    {
                      value: "not compatible",
                      label: "Not Compatible",
                    },
                    {
                      value: "compatible",
                      label: "Compatible",
                    },
                  ]}
                  value={tokenizeReq.redd_plus_plus_compatible}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      redd_plus_plus_compatible: e,
                    }))
                  }
                  isDisabled={false}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Number of SDG Goals?</Label>
                <CustomInputBox
                  type="number"
                  className=""
                  value={tokenizeReq.number_of_sdg_goals.toString()}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      number_of_sdg_goals: Number(e.target.value),
                    }))
                  }
                  placeholder="Enter Number of SDG Goals"
                />
              </Col>
              <Col md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>SDG Goals?</Label>
                <CustomDropdown
                  options={SDGOptions}
                  value={tokenizeReq.sdg_goals}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      sdg_goals: e,
                    }))
                  }
                  multiple={true}
                  isDisabled={false}
                  menuPlacement={"top"}
                />
              </Col>
            </>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <CustomButton
          placeholder="Submit"
          color="#00abff"
          type="button"
          disabled={loading}
          onClick={() => {
            handleApproveTokenizeReq();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ApproveTokenRequestModal;
