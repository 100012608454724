import React from "react";

export interface UserData {
  email: string | null;
  userID: string | null;
  jwt: string | null;
  role: string | null;
}

export enum Transaction {
  Deposit = "Deposit",
  Withdraw = "Withdraw",
}

export interface TradingData {
  allTradePairs: TradePair[];
}

export interface DepositWithrawData {
  Mode: ModeType;
  AssetID: number;
  TransactionType: Transaction;
}

interface UserContextProps {
  userId: UserData | null;
  handleChangeUserId: (userId: UserData) => void;
  setUserId: React.Dispatch<React.SetStateAction<UserData | null>>;
  depositData: DepositWithrawData | null;
  setDepositData: React.Dispatch<
    React.SetStateAction<DepositWithrawData | null>
  >;
  tradingData:
    | {
        label: string;
        value: TradePair;
      }[]
    | null;
  setTradingData: React.Dispatch<
    React.SetStateAction<
      | {
          label: string;
          value: TradePair;
        }[]
      | null
    >
  >;
  selectedTradePair: {
    label: string;
    value: TradePair;
  } | null;
  setSelectedTradePair: React.Dispatch<
    React.SetStateAction<{ label: string; value: TradePair } | null>
  >;
  resizeMode: boolean;
  handleResizeModeChange: (newResizeMode: boolean) => void;
  layoutGrid: any[];
  handleLayoutGridChange: (newLayoutGrid: any[]) => void;
  // selectedRegion: string;
  // setSelectedRegion: React.Dispatch<React.SetStateAction<string>>;
}

export const UserContext = React.createContext<UserContextProps | null>(null);

export enum ModeType {
  Fiat = "fiat",
  Credit = "credit",
}

export interface TradePair {
  id: number | undefined;
  name: string | undefined;
  full_name: string | undefined;
}
