// import { io, Socket } from "socket.io-client";
import { cp } from "fs";
import {
  BASE_SOCKET_URL,
  BASE_URL,
  ACTION_CHANGE_SUBSCRIPTION,
} from "../helpers/api_helper";

// WebSocketService.ts
type MessageHandler = (data: any) => void;

class WebSocketService {
  private socket: WebSocket | null;
  private jwtToken: string;
  public connected: boolean;
  private messageHandlers: MessageHandler[];
  private currentTradePairID: number | null;
  private subscription: string | null;

  constructor(jwtToken: string) {
    this.jwtToken = jwtToken;
    this.socket = null;
    this.connected = false;
    this.messageHandlers = [];
    this.currentTradePairID = null;
    this.subscription = null;
  }

  connect(subscriptionName: string, tradePairID: number | null): Promise<void> {
    console.log("Running 123")
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {

        // If the WebSocket connection is already open, send the subscription change message
        this.sendMessage({
          data: {
            from_trade_pair_id: this.currentTradePairID,
            to_trade_pair_id: tradePairID,
            from_subscription: this.subscription,
            to_subscription: subscriptionName,
          },
          action: ACTION_CHANGE_SUBSCRIPTION,
        });
        this.currentTradePairID = tradePairID;
        this.subscription = subscriptionName;
        resolve();
      } else {
        console.log("Running +++++++++++++++++++++++ 456")

        // If the WebSocket connection is not open, establish a new connection
        // this.disconnect();
        this.socket = new WebSocket(BASE_SOCKET_URL);

        this.socket.onopen = () => {
          this.socket!.send(
            JSON.stringify({
              data: {
                jwt: this.jwtToken,
                subscription: subscriptionName,
                trade_pair_id: tradePairID,
              },
              action: "authentication",
            })
          );
          this.currentTradePairID = tradePairID;
          this.subscription = subscriptionName;
          this.connected = true;
          resolve();
        };

        this.socket.onerror = (error: Event) => {
          console.log("WebSocket error:12", error);
          reject(error);
          this.connected = false;
        };

        this.socket.onmessage = (event: MessageEvent) => {
          // Handle incoming messages
          const data = JSON.parse(event.data);
          if (
            data.authentication_required !== null &&
            data.authentication_required === true
          ) {
            this.socket?.send(
              JSON.stringify({
                data: { jwt: this.jwtToken },
                action: "authentication",
              })
            );
          }
          this.messageHandlers.forEach((handler) => handler(data)); // Call all registered handlers
        };

        this.socket.onclose = (event: CloseEvent) => {
          console.log("WebSocket closed876876:", event);
        };
      }
    });
  }

  sendMessage(message: object): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not connected.", message);
    }
  }

  setOnMessageHandler(handler: MessageHandler): () => void {
    this.messageHandlers.push(handler); // Add new handler to the list
    return () => {
      const index = this.messageHandlers.indexOf(handler);
      if (index !== -1) {
        this.messageHandlers.splice(index, 1); // Remove the handler from the list
      }
    };
  }

  disconnect(): void {
    if (this.socket) {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            action: "disconnect",
            data: {
              trade_pair_id: this.currentTradePairID,
              subscription: this.subscription,
            },
          })
        );
      }
      this.socket.close();
      this.socket = null;
      this.connected = false;
    }
  }

  isSocketConnected(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }
}

export default WebSocketService;
