import useMediaQuery from "@mui/material/useMediaQuery";

const useBreakPoints = () => {
  const smallMobileBreakPoint = "320px";
  const mediumMobileBreakPoint = "375px";
  const mobileBreakPoint = "600px";
  const tabletBreakPoint = "768px";
  const laptopBreakPoint = "1024px";

  const isSmallMobile = useMediaQuery(`(max-width: ${smallMobileBreakPoint})`);
  const isMediumMobile = useMediaQuery(
    `(max-width: ${mediumMobileBreakPoint})`
  );
  const isMobile = useMediaQuery(`(max-width: ${mobileBreakPoint})`);
  const isTablet = useMediaQuery(`(max-width: ${tabletBreakPoint})`);
  const isLaptop = useMediaQuery(`(max-width: ${laptopBreakPoint})`);

  return {
    tabletBreakPoint,
    mobileBreakPoint,
    isMobile,
    isTablet,
    isSmallMobile,
    isLaptop,
    isMediumMobile,
  };
};

export default useBreakPoints;
