import React, { useEffect, useState } from "react";
import { DepositWithdrawPopupProps } from "../../helpers/types/WalletType";
import {
  Button,
  Col,
  // Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";

const DepositWithdrawPopup = ({
  ID,
  isOpen,
  setIsOpen,
  selectedAsset,
  depositData,
  handleDeposit,
}: DepositWithdrawPopupProps) => {
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <Modal centered isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <ModalHeader
        style={{ backgroundColor: "#273142" }}
        toggle={() => setIsOpen(false)}
      >
        {depositData?.TransactionType +
          " - " +
          depositData?.Mode.toLocaleUpperCase()}
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row>
          <Col md={12}>
            <Label>Amount</Label>
            <CustomInputBox
              type="number"
              value={amount}
              onchange={(e: any) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              className=""
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#273142" }}>
        <Button
          color="primary"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleDeposit(
              ID,
              amount,
              setAmount,
              isOpen,
              setIsOpen,
              selectedAsset
            );
          }}
        >
          {depositData?.TransactionType}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default DepositWithdrawPopup;
