import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BASE_URL,
  CREATE_CORPORATE_PROFILE,
  // VERIFY_BANK_DETAILS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import { CorporateFileType } from "../../helpers/types/Profile";
import useBreakPoints from "../../hooks/useBreakPoints";

const CorporateDetailModel = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();

  const [loading, setLoading] = useState<boolean>(false);

  const [corporateInfo, setcorporateInfo] = useState({
    compaName: "",
    hqAddressLine1: "",
    hqAddressLine2: "",
    hqAddressLine3: "",
    accountNumber: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    bankName: "",
    ifc: "",
    panNumber: "",
    tanNumber: "",
    upiID: "",
    incNumber: "",
    gstNumber: "",
    bankNumber: "",
  });
  const [fileUpload, setFileUpload] = useState<CorporateFileType>({
    CerificateINC: null,
    GSTCerificate: null,
    Pan: null,
    Tan: null,
    Memorandum: null,
    Articles: null,
    cancelledCheque: null,
  });
  const [step, setStep] = useState<number>(1);
  const [terms, setTerms] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please agree to the terms and conditions");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "certificate_of_incorporation",
        fileUpload?.CerificateINC!
      );
      formData.append("gst_certificate", fileUpload?.GSTCerificate!);
      formData.append("pan", fileUpload?.Pan!);
      formData.append("tan", fileUpload?.Tan!);
      formData.append("memorandum_o_a", fileUpload?.Memorandum!);
      formData.append("articles_o_a", fileUpload?.Articles!);
      formData.append("cancelled_cheque", fileUpload?.cancelledCheque!);
      formData.append("company_name", corporateInfo.compaName);
      formData.append("hq_address_line_1", corporateInfo.hqAddressLine1);
      formData.append("hq_address_line_2", corporateInfo.hqAddressLine2);
      formData.append("hq_address_line_3", corporateInfo.hqAddressLine3);
      formData.append("city", corporateInfo.city);
      formData.append("state", corporateInfo.state);
      formData.append("country", corporateInfo.country);
      formData.append("pin_code", corporateInfo.pinCode);
      formData.append(
        "certificate_of_incorporation_number",
        corporateInfo.incNumber
      );
      formData.append("pan_number", corporateInfo.panNumber);
      formData.append("tan_number", corporateInfo.tanNumber);
      formData.append("gst_reg_id", corporateInfo.gstNumber);
      formData.append("bank_account_number", corporateInfo.accountNumber);
      formData.append("bank_name", corporateInfo.bankName);
      formData.append("upi_id", corporateInfo.upiID);
      formData.append("ifsc_code", corporateInfo.ifc);

      axios
        .post(BASE_URL + CREATE_CORPORATE_PROFILE, formData, {
          headers: {
            Authorization: jwt,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success("Corporate Profile Added Successfully");
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Failed To Add Corporate Profile, Please try Again");
            console.error("Failed To Add Corporate Profile", err);
          }
        });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    pdf?: boolean
  ) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const allowedExtensions = [".jpeg", ".jpg", ".png"];
      const pdfExtensions = [".pdf"];
      const fileExtension = selectedFile.name.slice(
        ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (
        (pdf === undefined || pdf === false) &&
        allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setFileUpload((prevState: any) => ({
          ...fileUpload,
          [key]: selectedFile,
        }));
      } else if (
        pdf === true &&
        pdfExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setFileUpload((prevState: any) => ({
          ...fileUpload,
          [key]: selectedFile,
        }));
      } else {
        e.target.value = "";
        if (pdf === true) {
          alert("Only .pdf files are allowed.");
        } else {
          alert("Only .jpeg, .jpg, and .png files are allowed.");
        }
      }
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD CORPORATE DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        {step === 1 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  COMPANY INFORMATION
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={4} md={12}>
                  <Label>Company Name </Label>
                  <CustomInputBox
                    value={corporateInfo.compaName}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        compaName: e.target.value,
                      })
                    }
                    placeholder="Enter Company Name"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Label>Address Line 1 </Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine1}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        hqAddressLine1: e.target.value,
                      })
                    }
                    placeholder="Enter Address Line 1"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col
                  lg={4}
                  md={12}
                  className={`${isTablet ? (isMobile ? "" : "mt-3") : ""}`}
                >
                  <Label>Address Line 2 </Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine2}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        hqAddressLine2: e.target.value,
                      })
                    }
                    placeholder="Enter Address Line 2"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Address Line 3 </Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine3}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        hqAddressLine3: e.target.value,
                      })
                    }
                    placeholder="Enter Address Line 3"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>City </Label>
                  <CustomInputBox
                    value={corporateInfo.city}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        city: e.target.value,
                      })
                    }
                    placeholder="Enter City"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>State </Label>
                  <CustomInputBox
                    value={corporateInfo.state}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        state: e.target.value,
                      })
                    }
                    placeholder="Enter State"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Country </Label>
                  <CustomInputBox
                    value={corporateInfo.country}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        country: e.target.value,
                      })
                    }
                    placeholder="Enter Country"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Pincode </Label>
                  <CustomInputBox
                    value={corporateInfo.pinCode}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        pinCode: e.target.value,
                      })
                    }
                    placeholder="Enter Pincode"
                    className="form-control"
                    type="number"
                    required={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  PAN DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>PAN Number </Label>
                  <CustomInputBox
                    value={corporateInfo.panNumber}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        panNumber: e.target.value,
                      })
                    }
                    placeholder="Enter PAN Number"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={10}
                    maxLength={10}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload PAN Card Front Page </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        cursor: "pointer",
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Pan?.name}
                    />
                    {fileUpload.Pan && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Pan as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-3"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-3"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "Pan", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  TAN DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>TAN Number </Label>
                  <CustomInputBox
                    value={corporateInfo.tanNumber}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        tanNumber: e.target.value,
                      })
                    }
                    placeholder="Enter TAN Number"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={10}
                    maxLength={10}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload TAN Card </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.Tan?.name}
                    />
                    {fileUpload.Tan && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Tan as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-4"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-4"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "Tan", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 2 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  INCORPORATION DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>Incorporation Number </Label>
                  <CustomInputBox
                    value={corporateInfo.incNumber}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        incNumber: e.target.value,
                      })
                    }
                    placeholder="Enter Incorporation Number"
                    className="form-control"
                    type="number"
                    required={true}
                    minLength={21}
                    maxLength={21}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload Incorporation Certification </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.CerificateINC?.name}
                    />
                    {fileUpload.CerificateINC && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.CerificateINC as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-5"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-5"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "CerificateINC", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  GST DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>GST Number </Label>
                  <CustomInputBox
                    value={corporateInfo.gstNumber}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        gstNumber: e.target.value,
                      })
                    }
                    placeholder="Enter GST Number"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={15}
                    maxLength={15}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload GST Certificate </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.GSTCerificate?.name}
                    />
                    {fileUpload.GSTCerificate && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.GSTCerificate as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-6"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-6"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "GSTCerificate", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {step === 3 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  ASSOCIATION DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>Memorandum of Association </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Memorandum?.name}
                    />
                    {fileUpload.Memorandum && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Memorandum as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-7"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-7"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "Memorandum", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <Label>Article of Association </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Articles?.name}
                    />

                    {fileUpload.Articles && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Articles as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}

                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-8"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-8"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "Articles", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  BANK DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={4} md={12}>
                  <Label>Bank Name </Label>
                  <CustomInputBox
                    value={corporateInfo.bankName}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        bankName: e.target.value,
                      })
                    }
                    placeholder="Enter Bank Number"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={3}
                    maxLength={200}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Label>IFSC Code </Label>
                  <CustomInputBox
                    value={corporateInfo.ifc}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        ifc: e.target.value,
                      })
                    }
                    placeholder="Enter IFSC Code"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={11}
                    maxLength={11}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Label>Account Number </Label>
                  <CustomInputBox
                    value={corporateInfo.accountNumber}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        accountNumber: e.target.value,
                      })
                    }
                    placeholder="Enter Account Number"
                    className="form-control"
                    type="text"
                    required={true}
                    minLength={6}
                    maxLength={40}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>UPI ID </Label>
                  <CustomInputBox
                    value={corporateInfo.upiID}
                    onchange={(e: any) =>
                      setcorporateInfo({
                        ...corporateInfo,
                        upiID: e.target.value,
                      })
                    }
                    placeholder="Enter UPI ID"
                    className="form-control"
                    type="text"
                    required={true}
                  />
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Void Cheque </Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.cancelledCheque?.name}
                    />

                    {fileUpload.cancelledCheque && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.cancelledCheque as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}

                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-9"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-9"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, "cancelledCheque", true)
                      }
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ModalBody>

      {step !== 3 && (
        <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
          {" "}
          {step === 1 && (
            <CustomButton
              placeholder="Next"
              color="#00abff"
              type="button"
              onClick={() => {
                setStep(2);
              }}
            />
          )}
          {step === 2 && (
            <>
              <CustomButton
                placeholder="Previous"
                color="#323d4e"
                type="button"
                onClick={() => {
                  setStep(1);
                }}
              />
              <CustomButton
                placeholder="Next"
                color="#00abff"
                type="button"
                disabled={loading}
                onClick={() => {
                  setStep(3);
                }}
              />
            </>
          )}
        </ModalFooter>
      )}
      {step === 3 && (
        <div
          className="p-4 pt-2 d-flex justify-content-between gap-2"
          style={{ backgroundColor: "#273142" }}
        >
          <div className="d-flex align-items-center gap-3">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              placeholder="terms"
              required={true}
              checked={terms}
              onChange={(e: any) => {
                setTerms(e.target.checked);
              }}
            />
            <label className="v-aligh">
              I agree to all terms and conditions.
            </label>
          </div>
          <div className="d-flex justify-content-between gap-2">
            <CustomButton
              placeholder="Previous"
              color="#323d4e"
              type="button"
              onClick={() => {
                setStep(2);
              }}
            />
            <CustomButton
              placeholder="Submit"
              color="#00abff"
              disabled={loading}
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CorporateDetailModel;
