import React, { useContext, useEffect } from "react";
import { UserContext } from "../../helpers/types/contextType";
import ResizeTrading from "./ResizeTrading";
import useBreakPoints from "../../hooks/useBreakPoints";
import Trading from "./Trading";

const Index = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isMobile } = useBreakPoints();

  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext1 is undefined");
  }

  const { handleResizeModeChange, resizeMode } = userContext;

  useEffect(() => {
    if (isMobile) {
      handleResizeModeChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return <div>{resizeMode ? <ResizeTrading /> : <Trading />}</div>;
};

export default Index;
