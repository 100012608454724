import React, { useContext, useEffect, useRef, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import {
  ACTION_CHANGE_SUBSCRIPTION,
  ACTION_DICONNECT_SUBSCRIPTION,
  SOCKET_SUB_TRADING,
  SOCKET_SUB_TRADING_MARKET,
} from "../../helpers/api_helper";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
// import { TradingData, UserData } from "../../helpers/types/contextType";
import { SocketConectionContext } from "../../hooks/SocketConectionContext/SocketConectionContext";
import { Table } from "react-bootstrap";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";

export interface RecentTrade {
  amount: number;
  buy_order_id: number;
  final_amount: number;
  id: number;
  price: number;
  sell_order_id: number;
  timestamp: number;
  order_side: string | null;
}

const RecentTrades = ({
  TradingData,
  SetTradingData,
  tradeID,
  resizeMode,
}: {
  TradingData: any;
  SetTradingData: any;
  tradeID: any;
  resizeMode?: boolean;
}) => {
  const { isMobile } = useBreakPoints();
  const role = GetCookies(CookieItems.ROLE);
  const socketConnectionContext = useContext(SocketConectionContext);
  if (!socketConnectionContext) {
    throw new Error("socketConnectionContext is undefined");
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { webSocketService, setWebSocketService } = socketConnectionContext;
  const [recentTrades, setRecentTrades] = useState<RecentTrade[]>([]);
  const [marketFlag, setMarketFlag] = useState<boolean>(
    role?.startsWith("unverified") ? true : false
  );
  // const [error, setError] = useState(false);
  const webSocketServiceRef = useRef(webSocketService);
  const tradePairRef = useRef(tradeID);

  console.log("recentTrades   ==============>", recentTrades);

  const [containerHeight, setContainerHeight] = useState("100%");
  const containerRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (tradeID) {
      setMarketFlag(role?.startsWith("unverified") ? true : false)
    }
  }, [tradeID])


  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerHeight(`${entry.contentRect.height - 50}px`);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  useEffect(() => {
    webSocketServiceRef.current = webSocketService;
    tradePairRef.current = tradeID;
  }, [webSocketService, tradeID]);

  useEffect(() => {
    // if (!isMobile) {
    return () => {
      console.log("tradingdata", TradingData);
      if (SetTradingData && TradingData) {
        SetTradingData(null);
      }
      if (webSocketServiceRef.current !== null) {
        webSocketServiceRef.current.sendMessage({
          data: {
            trade_pair_id: tradePairRef.current,
            subscription:
              marketFlag === true
                ? SOCKET_SUB_TRADING_MARKET
                : SOCKET_SUB_TRADING,
          },
          action: ACTION_DICONNECT_SUBSCRIPTION,
        });
      }
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (webSocketService !== null) {
      // Define the message handler
      const handleMessage = (newData: any) => {
        if (newData && newData.recent_trades !== undefined) {
          setRecentTrades(newData.recent_trades); // Update the state with the new data
        }
      };
      const cleanupFunction =
        webSocketService.setOnMessageHandler(handleMessage);

      return cleanupFunction;
    }
  }, [webSocketService]);

  useEffect(() => {
    console.log(
      webSocketService &&
        webSocketService?.isSocketConnected() === true &&
        tradeID !== undefined
    );
    if (
      webSocketService &&
      webSocketService?.isSocketConnected() === true &&
      tradeID !== undefined
    ) {
      // webSocketService.sendMessage({
      //   data: {
      //     from_trade_pair_id: tradeID,
      //     to_trade_pair_id: tradeID,
      //     from_subscription:
      //       marketFlag === true
      //         ? SOCKET_SUB_TRADING
      //         : SOCKET_SUB_TRADING_MARKET,
      //     to_subscription:

      //   },
      //   action: ACTION_CHANGE_SUBSCRIPTION,
      // });
      webSocketService?.connect(marketFlag === true
        ? SOCKET_SUB_TRADING_MARKET
        : SOCKET_SUB_TRADING, tradeID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketFlag, webSocketService]);

  // const isScrollable = recentTrades.length > 5; // Adjust the threshold as needed

  return (
    <div
      ref={containerRef}
      style={{
        background: "#273142",
        padding: 10,
        borderRadius: 5,
        // border:  "1px solid #313d4f",
        height: resizeMode ? "100%" : "auto",
        width: resizeMode ? "100%" : "auto",
      }}
    >
      <div
        className={`text-white d-flex ${
          resizeMode ? "justify-content-between" : "justify-content-end"
        } align-items-center w-100`}
      >
        {resizeMode && (
          <span
            style={{
              lineHeight: "20px",
              fontSize: isMobile ? 15 : 24,
              fontWeight: 700,
            }}
          >
            Recent Trades{" "}
          </span>
        )}

        <div>
          <button
            type="button"
            onClick={() => {
              setMarketFlag(false);
            }}
            disabled={role?.startsWith("unverified") ? true : false}
            style={{
              border: marketFlag ? "1px solid #444e5d" : "1px solid #00abff",
              background: marketFlag ? "#323d4e" : "#00abff",
              fontSize: 12,
              padding: "7px 13px",
              color: "white",
              borderRadius: "5px 0px 0px 5px",
            }}
          >
            Yours
          </button>
          <button
            type="button"
            onClick={() => {
              setMarketFlag(true);
            }}
            disabled={role?.startsWith("unverified") ? true : false}
            style={{
              border: marketFlag ? "1px solid #00abff" : "1px solid #444e5d",
              background: !marketFlag ? "#323d4e" : "#00abff",
              fontSize: 12,
              padding: "7px 13px",
              color: "white",
              borderRadius: "0px 5px 5px 0px",
            }}
          >
            Market
          </button>
        </div>
      </div>

      <div
        className="mt-3"
        style={{
          height: resizeMode ? containerHeight : 418,

          overflow: "auto",
        }}
      >
        <Table responsive bordered={false}>
          <thead>
            <tr>
              <th
                style={{
                  width: "33.33%",

                  fontWeight: 500,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "1px solid #444e5d",
                  borderRight: "none",
                  borderRadius: "10px 0px 0px 10px",
                }}
              >
                Time
              </th>
              <th
                style={{
                  width: "33.33%",

                  fontWeight: 500,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Quantity
              </th>
              <th
                style={{
                  width: "33.33%",

                  fontWeight: 500,
                  background: "#313c4d",
                  paddingLeft: 15,
                  color: "white",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "1px solid #444e5d",
                  borderRadius: "0px 10px 10px 0px",
                }}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {recentTrades?.map((item: any) => (
              <tr>
                <td
                  style={{
                    background: "#273142",
                    paddingLeft: 15,
                    fontSize: 12,
                    border: "none",
                    color: item.order_side
                      ? item.order_side === "buy"
                        ? "rgb(0 251 230)"
                        : "#ff656d"
                      : "yellow",
                  }}
                >
                  {UnixUTCtoLocalTime(item.timestamp, "D MMMM YYYY")}
                </td>
                <td
                  style={{
                    background: "#273142",
                    color: item.order_side
                      ? item.order_side === "buy"
                        ? "rgb(0 251 230)"
                        : "#ff656d"
                      : "rgba(31, 199, 212, 1)",
                    fontSize: 12,
                    border: "none",
                  }}
                >
                  {item.amount.toFixed(2)}
                </td>
                <td
                  style={{
                    background: "#273142",
                    paddingLeft: 15,
                    color: item.order_side
                      ? item.order_side === "buy"
                        ? "rgb(0 251 230)"
                        : "#ff656d"
                      : "rgba(31, 199, 212, 1)",
                    fontSize: 12,
                    border: "none",
                  }}
                >
                  {item.price.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RecentTrades;
