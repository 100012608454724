import React from "react";
import RGL, { WidthProvider, Layout } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ReactGridLayout = WidthProvider(RGL);

interface MinMaxLayoutProps {
  isDraggable?: boolean;
  isResizable?: boolean;
  preventCollision?: boolean;
  cols?: number;
  isDroppable?: boolean;
  children: React.ReactNode;
  layoutGrid: any[];
  selectedComponent: any[];
  handleLayoutGridChange: (newLayoutGrid: any[]) => void;
}

interface MinMaxLayoutState {
  layout: Layout[];
}

class ResizeableComponent extends React.PureComponent<
  MinMaxLayoutProps,
  MinMaxLayoutState
> {
  static defaultProps: MinMaxLayoutProps = {
    isDraggable: true,
    isResizable: true,
    preventCollision: false,
    cols: 12,
    isDroppable: true,
    children: null,
    selectedComponent: [],
    layoutGrid: [],
    handleLayoutGridChange: () => {},
  };

  state: MinMaxLayoutState = {
    layout: [],
  };

  componentDidMount() {
    // Get the layout from localStorage and set it in the state
    if (this.props.layoutGrid?.length > 0) {
      this.setState({ layout: this.props.layoutGrid });
    } else {
      this.initializeLayout();
    }
    window.addEventListener("resize", this.initializeLayout);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.initializeLayout);
  }

  initializeLayout = () => {
    // const containerWidth = this.containerRef?.clientWidth || 1200;
    // const containerHeight = this.containerRef?.clientHeight || 800;
    const { cols = 12 } = this.props;
    // const childCount = React.Children.count(this.props.children);

    // Calculate the number of rows based on the child count
    // const rows = Math.ceil(childCount / 2);

    // Calculate unit sizes
    // const unitWidth = containerWidth / cols;
    // const unitHeight = containerHeight / rows;

    const layout = React.Children.map(this.props.children, (child, index) => {
      // const row = Math.floor(index / 2);
      const col = index % 2;
      return {
        i: index.toString(),
        x: col * (cols / 2),
        y: 5,
        w: cols / 2,
        h: 5,
      };
    });
    console.log(layout, "++++++++++++++++++++++++++++++++");
    this.setState({ layout: layout || [] }, () => {
      this.props.handleLayoutGridChange(this.state.layout);
    });
  };

  onLayoutChange = (layout: Layout[]) => {
    this.setState({ layout }, () => {
      this.props.handleLayoutGridChange(layout);
    });
  };

  handleRemove = (index: number) => {
    const updatedLayout = this.state.layout.filter((item, i) => i !== index);
    this.setState({ layout: updatedLayout }, () => {
      this.props.handleLayoutGridChange(updatedLayout);
    });
  };

  containerRef: HTMLDivElement | null = null;

  render() {
    // const { selectedComponent } = this.props;
    console.log(this.props.layoutGrid, "updatedLayout", this.state.layout);

    return (
      <div
        ref={(el) => (this.containerRef = el)}
        style={{ width: "100%", height: "100%" }}
      >
        <ReactGridLayout
          {...this.props}
          layout={this.state.layout}
          isResizable={true}
          // key={this.state.layout?.length}
          onLayoutChange={this.onLayoutChange}
          width={this.containerRef?.clientWidth}
          rowHeight={
            this.containerRef?.clientHeight
              ? this.containerRef.clientHeight / 12
              : 30
          }
        >
          {React.Children.map(this.props.children, (child, index) => (
            <div key={index.toString()} data-grid={this.state.layout[index]}>
              {child}

              {/* <span
                className="remove"
                style={{
                  position: "absolute",
                  right: "2px",
                  top: 0,
                  cursor: "pointer",
                }}
                onClick={() => this.handleRemove(index)}
              >
                x
              </span> */}
            </div>
          ))}
        </ReactGridLayout>
      </div>
    );
  }
}

export default ResizeableComponent;
