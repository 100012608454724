import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../Component/CustomButton";
import { Col, Row } from "reactstrap";
import MoneyIcon from "../../assets/svg/Wallet-Money-Icon.svg";
import RupeesIcon from "../../assets/svg/Wallet-rupees-icon.svg";
import WalletIcon from "../../assets/svg/Wallet-Color.svg";
import WalletCreditIcon from "../../assets/svg/Wallet-credit-icon.svg";
import useBreakPoints from "../../hooks/useBreakPoints";
import { toast } from "react-toastify";
import axios from "axios";
import {
  BASE_URL,
  CLAIM_CREDITS_EP,
  FIAT_DEPOSIT_EP,
  FIAT_WITHRAWL_EP,
  GET_CREDITS_EP,
  GET_CURRENCY_EP,
  GET_DEPOSIT_HIST_EP,
  // GET_WITHRAWL_HIST_EP,
  RETIRE_CREDITS_EP,
} from "../../helpers/api_helper";
import {
  ModeType,
  Transaction,
  UserContext,
} from "../../helpers/types/contextType";
// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
  TransactionHistoryType,
  WalletEntry,
  WalletEntrySelect,
  WalletdataType,
} from "../../helpers/types/WalletType";
import WalletChart from "./WalletChart";
import Lock from "../../assets/svg/Lock.svg";
import TransactionTable from "./TransactionTable";
import DepositWithdrawPopup from "./DepositWithdrawPopup";
import CustomDropdown from "../../Component/CustomDropdown";
import RetirePopup from "./RetirePopup";

const Wallet = () => {
  const { isMobile, isTablet, isSmallMobile } = useBreakPoints();
  // Router
  const navigate = useNavigate();

  // Context
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext1 is undefined");
  }

  const { userId, depositData, setDepositData } = userContext;

  // STATE declarations
  const [balance, setBalance] = useState({
    currencyTotal: 0,
    creditsTotal: 0,
  });

  const [walletData, setWalletData] = useState<WalletdataType>({
    fiat: [],
    credits: [],
  });

  const [selectedAsset, setSelectedAsset] = useState<WalletEntrySelect>({
    label: "",
    value: {
      amount: 0,
      is_currency: false,
      name: "",
      symbol: "",
      asset_id: -1,
      mode: ModeType.Fiat,
      locked_balance: 0,
    },
  });
  const [mode, setMode] = useState(ModeType.Fiat);
  const [transactionHistory, setTransactionHistory] = useState<
    TransactionHistoryType | []
  >([]);

  const [isOpen, setIsOpen] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [modalStatus, setModalStatus] = useState(false);

  // ERROR HANDLER

  // const handleError = useErrorHandler();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // whenver mode changes fetch the balance
  useEffect(() => {
    if (userId !== null && userId.jwt !== null) {
      getWalletData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, buttonClick]);

  const getWalletData = () => {
    // fetch currency balance
    axios
      .post(
        BASE_URL + GET_CURRENCY_EP,
        { user_id: null },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      )
      .then((response: any) => {
        if (response.data === null) {
          setWalletData((prevState: any) => ({
            ...prevState,
            fiat: [],
          }));
        } else {
          setWalletData((prevState: any) => ({
            ...prevState,
            fiat: response.data,
          }));
          if (selectedAsset.label === response.data[0]?.name) {
            setSelectedAsset({
              label: response.data[0]?.name,
              value: { ...response.data[0], mode: ModeType.Fiat },
            });
          } else if (!selectedAsset.label) {
            setSelectedAsset({
              label: response.data[0]?.name,
              value: { ...response.data[0], mode: ModeType.Fiat },
            });
          }
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("error while getting currency", error);
        }
      });

    // etch credits balance
    axios
      .post(
        BASE_URL + GET_CREDITS_EP,
        { user_id: null },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      )
      .then((response: any) => {
        if (response.data === null) {
          setWalletData((prevState: any) => ({
            ...prevState,
            credits: [],
          }));
        } else {
          setWalletData((prevState: any) => ({
            ...prevState,
            credits: response.data,
          }));
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("error while getting credits", error);
        }
      });
  };

  useEffect(() => {
    if (
      selectedAsset?.value?.asset_id !== -1 &&
      userId !== null &&
      userId.jwt !== null
    ) {
      getTranscationHistoryApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, buttonClick, userId, mode]);

  const getTranscationHistoryApi = () => {
    // fetch Transcation history
    axios
      .post(
        BASE_URL + GET_DEPOSIT_HIST_EP,
        { userid: null, asset_id: selectedAsset?.value?.asset_id },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      )
      .then((response: any) => {
        if (response.data === null) {
          setTransactionHistory([]);
        } else {
          setTransactionHistory(response.data);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("error while getting deposit history", error);
        }
      });
  };
  // whenever walletData changes update the balance
  useEffect(() => {
    const totalAmount = walletData.fiat.reduce(
      (sum: number, entry: any) => sum + (entry.amount ? entry.amount : 0),
      0
    );
    setBalance((prevState: any) => ({
      ...prevState,
      currencyTotal: totalAmount,
    }));

    const totalAmountCredits = walletData.credits.reduce(
      (sum: number, entry: any) => sum + (entry.amount ? entry.amount : 0),
      0
    );
    setBalance((prevState: any) => ({
      ...prevState,
      creditsTotal: totalAmountCredits,
    }));
  }, [walletData]);

  const handleDepositWithrawSubmit = (transactionMode: Transaction) => {
    if (
      selectedAsset?.value === null ||
      selectedAsset?.value === undefined ||
      selectedAsset?.value.mode === null ||
      selectedAsset?.value.mode === undefined
    ) {
      return;
    }
    setDepositData({
      Mode: selectedAsset?.value.mode,
      AssetID: selectedAsset?.value.asset_id,
      TransactionType: transactionMode,
    });
    togglePopup();
  };

  const handleDeposit = (
    ID: string | null | undefined,
    amnt: string,
    setAmnt: React.Dispatch<React.SetStateAction<string>>,
    isOpen: boolean,
    setIsOpn: React.Dispatch<React.SetStateAction<boolean>>,
    sltdAsset: WalletEntry
  ) => {
    if (amnt) {
      // // Handle deposit logic here.
      alert(`Depositing ${amnt}...`);

      var ep = "";
      if (
        depositData?.TransactionType === Transaction.Deposit &&
        depositData?.Mode === ModeType.Fiat
      ) {
        ep = FIAT_DEPOSIT_EP;
      } else if (
        depositData?.TransactionType === Transaction.Withdraw &&
        depositData?.Mode === ModeType.Fiat
      ) {
        ep = FIAT_WITHRAWL_EP;
      } else if (
        depositData?.TransactionType === Transaction.Deposit &&
        depositData?.Mode === ModeType.Credit
      ) {
        ep = CLAIM_CREDITS_EP;
      } else if (
        depositData?.TransactionType === Transaction.Withdraw &&
        depositData?.Mode === ModeType.Credit
      ) {
        ep = RETIRE_CREDITS_EP;
      }

      var data: any;
      if (depositData?.Mode === ModeType.Fiat) {
        data = {
          user_id: ID,
          asset_id: sltdAsset.asset_id,
          amount: parseInt(amnt, 10),
        };
      } else if (depositData?.Mode === ModeType.Credit) {
        data = {
          user_id: ID,
          asset_id: sltdAsset.asset_id,
          amount: parseInt(amnt, 10),
          token_id: 1,
        };
      }

      axios
        .post(BASE_URL + ep, data, {
          headers: {
            Authorization: userId?.jwt,
          },
        })
        .then((response: any) => {
          toast.success("Transaction Successful");
          if (response.data === null) {
            setTransactionHistory((prevState) => ({
              ...prevState,
              withdraw: [],
            }));
          }
          setButtonClick(!buttonClick);
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error("error while transaction", error);
            toast.error("Transaction Failed");
          }
        });

      getTranscationHistoryApi();
      getWalletData();
      // Close the popup
      setIsOpn(!isOpen);
      // Clear the input field
      setAmnt("");
    } else {
      alert("Please enter an amount.");
    }
  };

  const handleOnChangeSwtich = (value: any) => {
    setMode(value);

    if (value === ModeType.Fiat) {
      setSelectedAsset({
        label: walletData?.fiat[0]?.name,
        value: { ...walletData?.fiat[0], mode: ModeType.Fiat },
      });
    } else {
      setSelectedAsset({
        label: walletData?.credits[0]?.name,
        value: { ...walletData?.credits[0], mode: ModeType.Credit },
      });
    }
  };

  function formatNumber(num: any) {
    const numStr = num.toString();
    const parts = [];
    let counter = 0;

    // Handle the last 3 digits
    if (numStr.length > 3) {
      parts.unshift(numStr.slice(-3));
      counter = 1;
    } else {
      parts.unshift(numStr);
    }

    // Handle the rest of the digits
    for (let i = numStr.length - 3; i > 0; i -= 2) {
      parts.unshift(numStr.slice(Math.max(0, i - 2), i));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      counter++;
    }

    return parts.join(",");
  }

  function formatIndianNumber(num: number): string {
    const [integerPart, decimalPart] = num.toFixed(2).split(".");

    const formattedIntegerPart = formatNumber(integerPart);

    return `${formattedIntegerPart}.${decimalPart}`;
  }

  return (
    <div className="mt-3 p-3 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
            lineHeight: "43.65px",
            letterSpacing: "-0.11px",
          }}
        >
          Wallet{" "}
        </span>
        <CustomButton
          placeholder="Tokenize your credits"
          color="#00ABFF"
          style={{ paddingBottom: 4 }}
          onClick={() => {
            navigate("/tokenize-credit");
          }}
        />
      </div>

      <Row className={`mt-4 ${isMobile ? "gap-3  p-0 m-0" : ""}`}>
        <Col
          md={6}
          className={`${isMobile ? "p-0" : ""}`}
          style={{ paddingRight: isMobile ? 0 : 10 }}
        >
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              cursor: "pointer",
              padding: "10px 20px",
              border:
                mode === ModeType.Fiat
                  ? "1px solid #00abff"
                  : "1px solid #313d4f",
              transition: "transform 0.3s ease-in-out",
              transform: mode === ModeType.Fiat ? "scale(1)" : "scale(1)",
            }}
            onClick={() => {
              handleOnChangeSwtich(ModeType.Fiat);
            }}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <div>
              <span style={{ fontSize: 16, fontWeight: 600 }}>Currencies</span>

              <p
                style={{
                  fontSize: isTablet ? (isSmallMobile ? 15 : 30) : 40,
                  fontWeight: 700,
                }}
              >
                {formatIndianNumber(
                  parseFloat(balance.currencyTotal.toFixed(2))
                )}
              </p>
              <img src={RupeesIcon} alt="Rupees Icon" />
            </div>

            <div>
              <img src={MoneyIcon} alt="Money Icon" />
            </div>
          </div>
        </Col>
        <Col
          md={6}
          className={`${isMobile ? "p-0" : ""}`}
          style={{ paddingLeft: 0 }}
        >
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              cursor: "pointer",
              border:
                mode === ModeType.Credit
                  ? "1px solid #00abff"
                  : "1px solid #313d4f",
              transition: "transform 0.3s ease-in-out",
              transform: mode === ModeType.Fiat ? "scale(1)" : "scale(1)",
            }}
            onClick={() => {
              handleOnChangeSwtich(ModeType.Credit);
            }}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <div>
              <span style={{ fontSize: 16, fontWeight: 600 }}>Credits</span>

              <p
                style={{
                  fontSize: isTablet ? (isSmallMobile ? 15 : 30) : 40,
                  fontWeight: 700,
                }}
              >
                {formatIndianNumber(
                  parseFloat(balance.creditsTotal.toFixed(2))
                )}
              </p>

              <img src={WalletCreditIcon} alt="Rupees Icon" />
            </div>
            <div>
              <img src={WalletIcon} alt="Wallet Icon" />
            </div>
          </div>
        </Col>
      </Row>

      <Row
        style={{
          backgroundColor: "#273142",
          borderRadius: "10px 10px 0px 0px",
          border: "1px solid #313d4f",
          margin: "10px 0px",
          padding: "10px 20px",
        }}
      >
        <Col
          className={`${isMobile ? "p-0" : ""}`}
          style={{ marginTop: 12, paddingRight: 0 }}
          md={12}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 24, fontWeight: 700, lineHeight: "20px" }}>
              Portfolio Holding
            </span>

            {!isMobile && (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleOnChangeSwtich(ModeType.Fiat);
                  }}
                  style={{
                    border:
                      mode === ModeType.Fiat
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                    background: mode === ModeType.Fiat ? "#00abff" : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "5px 0px 0px 5px",
                  }}
                >
                  Currency
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleOnChangeSwtich(ModeType.Credit);
                  }}
                  style={{
                    border:
                      mode === ModeType.Credit
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                    background:
                      mode === ModeType.Credit ? "#00abff" : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "0px 5px 5px 0px",
                  }}
                >
                  Credits
                </button>
              </div>
            )}
          </div>

          {isMobile && (
            <div className="w-100 mt-2 mb-2">
              <button
                className="w-50"
                type="button"
                onClick={() => {
                  handleOnChangeSwtich(ModeType.Fiat);
                }}
                style={{
                  border:
                    mode === ModeType.Fiat
                      ? "1px solid #00abff"
                      : "1px solid #444e5d",
                  background: mode === ModeType.Fiat ? "#00abff" : "#323d4e",
                  fontSize: 12,
                  padding: "7px 13px",
                  color: "white",
                  borderRadius: "5px 0px 0px 5px",
                }}
              >
                Currency
              </button>
              <button
                className="w-50"
                type="button"
                onClick={() => {
                  handleOnChangeSwtich(ModeType.Credit);
                }}
                style={{
                  border:
                    mode === ModeType.Credit
                      ? "1px solid #00abff"
                      : "1px solid #444e5d",
                  background: mode === ModeType.Credit ? "#00abff" : "#323d4e",
                  fontSize: 12,
                  padding: "7px 13px",
                  color: "white",
                  borderRadius: "0px 5px 5px 0px",
                }}
              >
                Credits
              </button>
            </div>
          )}

          {isMobile && (
            <CustomDropdown
              options={
                mode === ModeType.Fiat
                  ? walletData.fiat.map((option: any) => ({
                      value: { ...option, mode: ModeType.Fiat },
                      label: option.name,
                    }))
                  : walletData.credits.map((option: any) => ({
                      value: { ...option, mode: ModeType.Credit },
                      label: option.name,
                    }))
              }
              value={selectedAsset}
              onChange={(e: any) => {
                setSelectedAsset(e);
              }}
              isDisabled={false}
            />
          )}
          <Row>
            <Col lg={6} md={6}>
              <WalletChart
                walletData={
                  mode === ModeType.Fiat ? walletData.fiat : walletData.credits
                }
              />
            </Col>
            <Col lg={6} md={6}>
              <div className="mt-4" style={{ marginRight: 20 }}>
                {!isMobile && (
                  <CustomDropdown
                    options={
                      mode === ModeType.Fiat
                        ? walletData.fiat.map((option: any) => ({
                            value: option,
                            label: option.name,
                          }))
                        : walletData.credits.map((option: any) => ({
                            value: option,
                            label: option.name,
                          }))
                    }
                    value={selectedAsset}
                    onChange={(e: any) => {
                      setSelectedAsset(e);
                    }}
                    isDisabled={false}
                  />
                )}

                {!isMobile && (
                  <Row
                    style={{
                      background: "#323d4e",
                      border: "1px solid #444e5d",

                      margin: 2,
                      borderRadius: 5,
                      padding: 12,
                    }}
                    className={`mt-2 ${isMobile ? "flex-column" : ""}`}
                  >
                    <Col lg={6} md={6}>
                      <span style={{ fontSize: 16, fontWeight: 400 }}>
                        Locked
                      </span>
                      <div className="d-flex align-items-center justify-content-start gap-2">
                        <p
                          className="mb-0"
                          style={{
                            fontSize: isSmallMobile ? 15 : 20,
                            fontWeight: 700,
                          }}
                        >
                          {selectedAsset?.value?.locked_balance.toFixed(2)}
                        </p>
                        <img src={Lock} alt="Lock" />
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      style={{
                        borderLeft: isMobile ? "none" : "1px solid white",
                        marginTop: isMobile ? 10 : 0,
                        paddingTop: isMobile ? 10 : 0,
                        borderTop: isMobile ? "1px solid white" : "none",
                      }}
                    >
                      <span style={{ fontSize: 16, fontWeight: 400 }}>
                        Balance
                      </span>
                      <p
                        className="mb-0"
                        style={{ fontSize: 20, fontWeight: 700 }}
                      >
                        {selectedAsset?.value?.amount.toFixed(2)}
                      </p>
                    </Col>
                  </Row>
                )}

                {isMobile && (
                  <div
                    style={{
                      background: "#323d4e",
                      border: "1px solid #444e5d",
                      margin: 2,
                      borderRadius: 5,
                      padding: 12,
                    }}
                    className={`mt-2 d-flex gap-4`}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: isSmallMobile ? 10 : 16,
                          fontWeight: 400,
                        }}
                      >
                        Locked
                      </span>
                      <div className="d-flex align-items-center justify-content-start gap-2">
                        <p
                          className="mb-0"
                          style={{
                            fontSize: isSmallMobile ? 10 : 20,
                            fontWeight: 700,
                          }}
                        >
                          {selectedAsset?.value?.locked_balance.toFixed(2)}
                        </p>
                        <img src={Lock} alt="Lock" />
                      </div>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid white",
                        marginTop: 0,
                        paddingLeft: 15,
                        borderTop: "none",
                      }}
                    >
                      <span
                        style={{
                          fontSize: isSmallMobile ? 10 : 16,
                          fontWeight: 400,
                        }}
                      >
                        Balance
                      </span>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: isSmallMobile ? 10 : 20,
                          fontWeight: 700,
                        }}
                      >
                        {selectedAsset?.value?.amount.toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}

                <Row className="mt-4">
                  {mode === ModeType.Fiat ? (
                    <>
                      <Col lg={6} md={12}>
                        <button
                          type="button"
                          className="w-100"
                          onClick={() => {
                            handleDepositWithrawSubmit(Transaction.Deposit);
                          }}
                          style={{
                            border: "none",
                            background: "#1ccab8",
                            fontSize: 17,
                            fontWeight: 700,
                            padding: "7px 13px",
                            color: "white",
                            borderRadius: "5px",
                            textTransform: "uppercase",
                          }}
                        >
                          Deposit
                        </button>
                      </Col>
                      <Col lg={6} md={12}>
                        <button
                          type="button"
                          className="w-100"
                          onClick={() => {
                            handleDepositWithrawSubmit(Transaction.Withdraw);
                          }}
                          style={{
                            marginTop: isTablet ? 10 : 0,
                            border: "none",
                            background: "#ff4c54",
                            fontSize: 17,
                            fontWeight: 700,
                            padding: "7px 13px",
                            color: "white",
                            textTransform: "uppercase",
                            borderRadius: "5px",
                          }}
                        >
                          Withdraw
                        </button>
                      </Col>
                    </>
                  ) : (
                    <Col lg={12} md={12}>
                      <button
                        type="button"
                        className="w-100"
                        onClick={() => {
                          setModalStatus(!modalStatus);
                        }}
                        style={{
                          marginTop: isTablet ? 10 : 0,
                          border: "none",
                          background: "#ff4c54",
                          fontSize: 17,
                          fontWeight: 700,
                          padding: "7px 13px",
                          color: "white",
                          textTransform: "uppercase",
                          borderRadius: "5px",
                        }}
                      >
                        Retire
                      </button>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} className={`${isMobile ? "mt-5" : "mt-4 "}`}>
          <span style={{ fontSize: 24, fontWeight: 700, lineHeight: "20px" }}>
            Recent Transactions{" "}
          </span>

          <div className="mt-2">
            <TransactionTable data={transactionHistory} />
          </div>
        </Col>
      </Row>

      {isOpen && (
        <DepositWithdrawPopup
          ID={userId?.userID}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedAsset={selectedAsset?.value}
          depositData={depositData}
          handleDeposit={handleDeposit}
        />
      )}
      {modalStatus && (
        <RetirePopup
          userId={userId}
          isOpen={modalStatus}
          setIsOpen={setModalStatus}
          selectedAsset={selectedAsset?.value}
          onclose={() => {
            getTranscationHistoryApi();
            getWalletData();
          }}
        />
      )}
    </div>
  );
};

export default Wallet;
