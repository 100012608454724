// import exp from "constants";
import {  useState } from "react";
// import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const [isOpen, setIsOpen] = useState(true);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const onClick = () => {
    togglePopup();
    resetErrorBoundary();
  };

  return (
    <div className={`alert fallback ${isOpen ? "open" : ""}`}>
      <div className="fallback-content">
        <span className="close" onClick={togglePopup}>
          &times;
        </span>
        <h4 className="fallback-heading">Something went wrong</h4>
        <pre className="fallback-heading">{error.message}</pre>
        <button onClick={onClick}>Try again</button>
      </div>
    </div>
  );
}

export default ErrorFallback;
