import Cookies from 'js-cookie';

// TO_Unit is an enum that contains the time unit for the cookie timeout
enum TO_Unit {
    Minutes = "MINUTES",
    HOURS = "HOURS",
    DAYS = "DAYS",
}

//  CookieItems is an enum that contains all the cookie keys
export enum  CookieItems {
    JWT =  "jwt",
    USERID = "userID",
    EMAIL = "email",
    ROLE = "role",
}

export function SetCookies(key: string, value: string, timeoutValue: number, timeoutUnit: string) {

    var expirationTime: Date;

    if (timeoutUnit === TO_Unit.Minutes) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 60000); // Convert minutes to milliseconds
    } else if (timeoutUnit === TO_Unit.HOURS) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 3600000); // Convert hours to milliseconds
    } else if (timeoutUnit === TO_Unit.DAYS) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 86400000); // Convert days to milliseconds
    } else {
        throw new Error("Invalid timeoutUnit");
    }
    Cookies.set(key, value, { expires: expirationTime });
}

export function GetCookies(key: CookieItems): string | undefined {
    return Cookies.get(key);
}

export function DeleteCookie(cookieName: CookieItems): void {
    Cookies.remove(cookieName);
  }