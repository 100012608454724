import React, { useEffect, useState } from "react";
import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import {
  AdminFilterType,
  AdminRequestType,
} from "../../helpers/types/AdminScreenType";
import axios from "axios";
import {
  APPROVE_CREDIT_REQUEST,
  BASE_URL,
  BLOCK_CHAIN,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_COUNT,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";
import { Col, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import UpdateStatusModal from "./UpdateStatusModal";
import ApproveTokenRequestModal from "./ApproveTokenRequestModal";
import CustomDropdown from "../../Component/CustomDropdown";
import { Box, TablePagination } from "@mui/material";
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import DownloadIcon from "../../assets/svg/Download-Icon.svg";
import Drawer from "@mui/material/Drawer";

const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Request More Information",
    value: "requested more information",
  },
  { label: "Pending", value: "pending" },
  { label: "Reject", value: "rejected" },
  { label: "Approved", value: "approved" },
];

const reqTypeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Deposit",
    value: "deposit",
  },
  { label: "Retire", value: "retire" },
];

const CreditRequestManagement = () => {
  const { isMobile } = useBreakPoints();
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);

  // ERROR HANDLER
  const handleError = useErrorHandler();

  // USE STATE
  const [downloadCSV, setDownloadCSV] = useState<AdminRequestType[]>([]);
  const [adminRequest, setAdminRequests] = useState<AdminRequestType[]>([]);
  const [totalRequests, setTotalRequests] = useState<Number | null>(null);
  const [modalStatus, setModalStatus] = useState({
    updateStatus: false,
    acceptTokenize: false,
  });
  const [open, setOpen] = useState<number | null>(null);
  const [requestID, setRequestID] = useState<number | null>(null);
  const [filters, setFilters] = useState<AdminFilterType>({
    page: 0,
    req_type: {
      label: "All",
      value: "all",
    },
    status: {
      label: "All",
      value: "all",
    },
    userName: null,
    asset_name: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  useEffect(() => {
    if (jwt !== null) {
      getAllRequestApi();
      //get requests counts
    }
    // handleFilterChange.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, modalStatus, filters]);

  const getAllRequestApi = () => {
    axios
      .post(
        BASE_URL + GET_ALL_REQUESTS,
        {
          page: filters.page,
          req_type:
            filters.req_type?.value === "all" ? null : filters.req_type?.value,
          status:
            filters.status?.value === "all" ? null : filters.status?.value,
          asset_name: filters.asset_name,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setAdminRequests(response.data);
        getTotalCount();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting all requests for admin", error);
          handleError(error);
        }
      });
    axios
      .post(
        BASE_URL + GET_ALL_REQUESTS,
        {
          req_type:
            filters.req_type?.value === "all" ? null : filters.req_type?.value,
          status:
            filters.status?.value === "all" ? null : filters.status?.value,
          asset_name: filters.asset_name,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setDownloadCSV(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting all requests for admin", error);
          handleError(error);
        }
      });
  };

  const getTotalCount = () => {
    axios
      .post(
        BASE_URL + GET_ALL_REQUESTS_COUNT,
        {},
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setTotalRequests(response.data.total_requests.total_requests);
      })
      .catch((error) => {
        console.error(
          "Error while getting all requests count for admin",
          error
        );
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          toast.error("Error while getting requests count for admin");
        }
      });
  };

  function handleApproveRetire(row: any) {
    setLoading(true);
    axios
      .post(
        BASE_URL + APPROVE_CREDIT_REQUEST,
        {
          request_id: row.id,
          amount: row.num_of_credits,
          asset_id: row.asset_id,
          token_id: row.token_id,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        toast.success("Retire request approved successfully");
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while approving retire request", error);
          toast.error("Error while approving retire request");
        }
      });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({ ...filters, page: newPage });
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div
        className={` d-flex  w-100 ${
          isMobile
            ? "flex-column gap-2 justify-content-start align-items-start"
            : " justify-content-between align-items-center"
        }`}
      >
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
            lineHeight: "43.65px",
            letterSpacing: "-0.11px",
          }}
        >
          Credit Request Management{" "}
        </span>
        <div className="d-flex justify-content-center align-items-center ">
          <p
            className="mb-0"
            style={{
              background: "#3cab3d",
              padding: "5px 10px",
              borderRadius: "9px 0px 0px 9px",
              fontWeight: 600,
              textWrap: "nowrap",
            }}
          >
            Total Credit Request
          </p>
          <p
            className="mb-0"
            style={{
              background: "#008dd2",
              padding: "5px 15px",
              borderRadius: "0px 9px 9px 0px",
              fontWeight: 600,
              textWrap: "nowrap",
            }}
          >
            {totalRequests ? totalRequests?.toString() : "-"}
          </p>
        </div>
      </div>

      <div
        style={{ marginTop: "20px", paddingRight: 30 }}
        className="d-flex justify-content-end align-items-center w-100"
      >
        {!isMobile && (
          <div>
            <TablePagination
              component="div"
              count={Number(totalRequests)} // Total number of rows
              page={filters.page}
              onPageChange={handleChangePage}
              rowsPerPage={10}
              rowsPerPageOptions={[]} // Hide the rows per page options
              sx={{
                color: "white",
                "& .css-pdct74-MuiTablePagination-selectLabel ": {
                  color: "white",
                  marginBottom: 0,
                },
                "& .css-levciy-MuiTablePagination-displayedRows ": {
                  color: "white",
                  marginBottom: 0,
                },
                "& .Mui-disabled": {
                  color: "grey",
                  opacity: 0.5,
                },
                "& .MuiTablePagination-actions": {
                  "& .MuiIconButton-root.Mui-disabled": {
                    color: "grey",
                  },
                },
              }}
            />
          </div>
        )}
        <div
          style={{
            borderLeft: isMobile ? "" : "1px solid white",
            paddingLeft: 20,
            marginLeft: 10,
          }}
          className="d-flex justify-content-center align-items-center gap-3"
        >
          <img
            onClick={toggleDrawer(true)}
            style={{ cursor: "pointer" }}
            src={FilterIcon}
            alt="Filter Icon"
          />
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              const csvData = downloadCSV;
              if (csvData !== null && csvData !== undefined) {
                const csv = new Blob(
                  [
                    `${Object.keys(csvData[0]).join(",")}\n${csvData
                      .map((e: any) => Object.values(e).join(","))
                      .join("\n")}`,
                  ],
                  {
                    type: "text/csv;charset=utf-8;",
                  }
                );
                const url = window.URL.createObjectURL(csv);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "myCreditRequests.csv");
                document.body.appendChild(link);
                link.click();
              }
            }}
            src={DownloadIcon}
            alt="Download Icon"
          />
        </div>
      </div>
      <div
        className={`${isMobile ? "mt-3" : ""} admin-table-2 table-responsive`}
      >
        <table className="accordion-table" style={{ fontSize: 13 }}>
          <thead>
            <tr>
              <th className="expand"></th>
              <th style={{ minWidth: 40, maxWidth: 40 }}>ID</th>
              <th style={{ minWidth: 80, maxWidth: 80 }}>Time</th>
              <th style={{ textWrap: "nowrap" }}>User Name</th>
              <th style={{ textWrap: "nowrap" }}>User Email</th>
              <th style={{ textWrap: "nowrap" }}>Request Type</th>
              <th>Asset Name</th>
              <th style={{ minWidth: 100, maxWidth: 250, width: 240 }}>
                Reason
              </th>
              <th style={{ minWidth: 100, maxWidth: 100 }}>Status</th>
              <th style={{ minWidth: 100, maxWidth: 100 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {adminRequest.map((row, i) => (
              <>
                <tr key={i} className="header-level">
                  <td
                    style={{
                      cursor: "pointer",
                      background: "#1b2431",
                      fontWeight: 400,
                    }}
                    onClick={() => setOpen(open === i ? null : i)}
                    className="expand"
                  >
                    {open === i ? (
                      <i className="fa fa-chevron-down"></i>
                    ) : (
                      <i className="fa fa-chevron-right"></i>
                    )}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.id}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {UnixUTCtoLocalTime(row?.timestamp, "D MMMM YYYY")}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.name}
                  </td>
                  <td
                    onClick={() => setOpen(open === i ? null : i)}
                    style={{ cursor: "pointer", textWrap: "balance" }}
                  >
                    {row?.email}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.req_type}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(open === i ? null : i)}
                  >
                    {row?.asset_name}
                  </td>
                  <td
                    onClick={() => setOpen(open === i ? null : i)}
                    style={{ cursor: "pointer", textWrap: "balance" }}
                  >
                    {row?.reason.toUpperCase()}
                  </td>
                  <td>
                    <span
                      onClick={() => setOpen(open === i ? null : i)}
                      style={{
                        cursor: "pointer",
                        background:
                          row?.status === "approved"
                            ? "#1ccab8"
                            : row?.status === "pending"
                            ? "rgb(77 202 28)"
                            : row?.status === "requested more information"
                            ? "rgb(202 94 28)"
                            : row?.status === "rejected"
                            ? "#cb1b2c"
                            : "#1ccab8",
                        padding:
                          row?.status.toUpperCase() ===
                          "REQUESTED MORE INFORMATION"
                            ? "5px 10px"
                            : "5px 29px",
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.status.toUpperCase() ===
                      "REQUESTED MORE INFORMATION"
                        ? "Req-More-Info"
                        : row?.status}
                    </span>
                  </td>
                  <td>
                    <div className="gap-3 mx-2 d-flex justify-content-start align-items-center">
                      <CustomButton
                        placeholder="Update Status"
                        onClick={() => {
                          setRequestID(row.id);
                          setModalStatus({
                            ...modalStatus,
                            updateStatus: true,
                          });
                        }}
                        style={{ textWrap: "nowrap" }}
                        color="#008DD2"
                        disabled={row.status === "approved"}
                      />

                      {row.status !== "approved" && (
                        <CustomButton
                          placeholder="Accept"
                          onClick={() => {
                            if (row.req_type === "retire") {
                              handleApproveRetire(row);
                            } else {
                              setRequestID(row.id);
                              setModalStatus({
                                ...modalStatus,
                                acceptTokenize: true,
                              });
                            }
                          }}
                          color="#008DD2"
                          disabled={row.status === "approved"}
                          loading={loading}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr key={i} className="sub-level">
                  <td className="expand" style={{ background: "#1b2431" }}></td>
                  <td colSpan={12}>
                    <div className={`${open === i ? "open" : "close"} w-100`}>
                      {row.req_type === "deposit" && (
                        <Row
                          style={{
                            backgroundColor: "#17202d",
                            border: "1px solid #313d4f",
                            borderRadius: "10px",
                            margin: "10px 5px",
                            padding: "0px 23px 20px 10px",
                          }}
                        >
                          <Col
                            className=""
                            style={{ marginTop: 12, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                BLOCK CHAIN INFORMATION
                              </span>
                            </div>
                            <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={4} md={12} className="text-start">
                                <div className="text-white d-flex justify-content-start align-items-center gap-2">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Name:
                                  </span>{" "}
                                  &nbsp;{" "}
                                  {row.txhash && row.txhash !== "0x123" ? (
                                    <CustomButton
                                      placeholder="Block-Chain-Explorer-Link"
                                      onClick={() => {
                                        window.open(
                                          BLOCK_CHAIN + row.txhash,
                                          "_blank"
                                        );
                                      }}
                                      style={{ textWrap: "nowrap" }}
                                      color="#008DD2"
                                    />
                                  ) : (
                                    "Blockchain settlement pending"
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className=""
                            style={{ marginTop: 20, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                General Information
                              </span>
                            </div>
                            <Row className={`mt-2 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Name:{" "}
                                  </span>{" "}
                                  &nbsp; {row.registry_name}
                                </div>
                              </Col>

                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    If Registry Account:{" "}
                                  </span>{" "}
                                  &nbsp;{" "}
                                  {row.if_registry_account ? "Yes" : "No"}
                                </div>
                              </Col>
                              <Col lg={4} md={6} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry URL:{" "}
                                  </span>{" "}
                                  &nbsp; {row.registry_project_url}
                                </div>
                              </Col>
                              <Col
                                lg={12}
                                md={12}
                                className={`text-start ${
                                  isMobile ? "" : "mt-3"
                                }`}
                              >
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Number of Credits:{" "}
                                  </span>{" "}
                                  &nbsp; {row.num_of_credits}
                                </div>
                              </Col>

                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Account ID:{" "}
                                  </span>{" "}
                                  &nbsp; {row.registry_account_id}
                                </div>
                              </Col>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Methodology ID:{" "}
                                  </span>{" "}
                                  &nbsp; {row.methodology_id}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className=""
                            style={{ marginTop: 20, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Project Information
                              </span>
                            </div>
                            <Row className={`mt-2 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Credit Program Name:{" "}
                                  </span>{" "}
                                  &nbsp; {row.credit_programme}
                                </div>
                              </Col>

                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Type:{" "}
                                  </span>{" "}
                                  &nbsp; {row.project_type}
                                </div>
                              </Col>
                              <Col lg={4} md={4} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project State:{" "}
                                  </span>{" "}
                                  &nbsp; {row.project_state}
                                </div>
                              </Col>
                              <Col
                                lg={4}
                                md={4}
                                className={`text-start ${
                                  isMobile ? "" : "mt-3"
                                }`}
                              >
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Country:{" "}
                                  </span>{" "}
                                  &nbsp; {row.project_country}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className=""
                            style={{ marginTop: 20, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Mint Information
                              </span>
                            </div>
                            <Row className={`mt-2 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    One Time Claimable:{" "}
                                  </span>{" "}
                                  &nbsp; {row?.if_one_time_claim}
                                </div>
                              </Col>

                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Mint Duration:{" "}
                                  </span>{" "}
                                  &nbsp; {row.mint_duration}
                                </div>
                              </Col>
                              <Col lg={4} md={4} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Mint Year:{" "}
                                  </span>{" "}
                                  &nbsp; {row.mint_year}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className=""
                            style={{ marginTop: 20, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Project Proponent Information
                              </span>
                            </div>
                            <Row className={`mt-2 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Proponent Name:{" "}
                                  </span>{" "}
                                  &nbsp; {row?.pp_name}
                                </div>
                              </Col>

                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Proponent Address:{" "}
                                  </span>{" "}
                                  &nbsp; {row.pp_address}
                                </div>
                              </Col>
                              <Col lg={4} md={4} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Proponent Phone:{" "}
                                  </span>{" "}
                                  &nbsp; {row.pp_contact_details}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}

                      {row.req_type === "retire" && (
                        <Row
                          style={{
                            backgroundColor: "#17202d",
                            border: "1px solid #313d4f",
                            borderRadius: "10px",
                            margin: "10px 5px",
                            padding: "0px 23px 20px 10px",
                          }}
                        >
                          <Col
                            className=""
                            style={{ marginTop: 12, paddingRight: 0 }}
                            md={12}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              style={{}}
                            >
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                General Information
                              </span>
                            </div>
                            <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    If Registry Account:
                                  </span>{" "}
                                  &nbsp;{" "}
                                  {row.if_registry_account ? "Yes" : "No"}
                                </div>
                              </Col>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Name:
                                  </span>{" "}
                                  &nbsp; {row.registry_name}
                                </div>
                              </Col>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Account ID:
                                  </span>{" "}
                                  &nbsp; {row.registry_account_id}
                                </div>
                              </Col>
                              <Col lg={3} md={3} className="text-start">
                                <div className="text-white">
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Number of Credits:
                                  </span>{" "}
                                  &nbsp; {row.num_of_credits}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      {isMobile && (
        <div>
          <TablePagination
            component="div"
            count={Number(totalRequests)} // Total number of rows
            page={filters.page}
            onPageChange={handleChangePage}
            rowsPerPage={10}
            rowsPerPageOptions={[]} // Hide the rows per page options
            sx={{
              color: "white",
              "& .css-pdct74-MuiTablePagination-selectLabel ": {
                color: "white",
                marginBottom: 0,
              },
              "& .css-levciy-MuiTablePagination-displayedRows ": {
                color: "white",
                marginBottom: 0,
              },
              "& .Mui-disabled": {
                color: "grey",
                opacity: 0.5,
              },
              "& .MuiTablePagination-actions": {
                "& .MuiIconButton-root.Mui-disabled": {
                  color: "grey",
                },
              },
            }}
          />
        </div>
      )}

      {modalStatus.updateStatus && (
        <UpdateStatusModal
          open={modalStatus.updateStatus}
          onClose={() => {
            setModalStatus({ ...modalStatus, updateStatus: false });
            setRequestID(null);
            getAllRequestApi();
          }}
          requestID={requestID!}
        />
      )}

      {modalStatus.acceptTokenize && (
        <ApproveTokenRequestModal
          open={modalStatus.acceptTokenize}
          onClose={() => {
            setModalStatus({ ...modalStatus, acceptTokenize: false });
            setRequestID(null);
            getAllRequestApi();
          }}
          requestID={requestID!}
        />
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ backgroundColor: "#273142", color: "white", height: "100%" }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <div className="p-4 flex-column w-100">
            <Col md={12}>
              <Label>Search by Status</Label>
              <CustomDropdown
                options={statusOptions}
                value={filters.status}
                onChange={(e: any) => setFilters({ ...filters, status: e })}
                isDisabled={false}
                placeholder="Search by Status"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Request Type</Label>
              <CustomDropdown
                options={reqTypeOptions}
                value={filters.req_type}
                onChange={(e: any) => setFilters({ ...filters, req_type: e })}
                isDisabled={false}
                placeholder="Search by Request Type"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Asset Name</Label>
              <CustomInputBox
                type="text"
                value={filters.asset_name || ""}
                placeholder="Search by Asset Name"
                className="w-100"
                onchange={(e: any) =>
                  setFilters({ ...filters, asset_name: e.target.value })
                }
              />
            </Col>
            <Col md={12} className="mt-4 text-end">
              <CustomButton
                placeholder="Clear"
                color="#323D4E"
                onClick={() => {
                  setFilters({
                    page: 0,
                    req_type: {
                      label: "All",
                      value: "all",
                    },
                    status: {
                      label: "All",
                      value: "all",
                    },
                    userName: null,
                    asset_name: null,
                  });
                }}
              />
            </Col>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default CreditRequestManagement;
