import moment from "moment-timezone";

export function UnixUTCtoLocalTime(
	unixTimestamp: number,
	format?: string
): string {
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const localMoment = moment.unix(unixTimestamp).tz(userTimeZone);
	// Format the local time with the time zone abbreviation
	const formattedLocalTime = localMoment.format(
		format === undefined ? "D MMMM YYYY h:mmA z" : format
	);
	return formattedLocalTime;
}

export function ConvertToUnixUtc(dateString: string): number {
	// Create a Date object from the date string
	const date = new Date(dateString);

	// Convert the date to a UNIX timestamp in milliseconds
	// and then convert it to seconds by dividing by 1000
	const unixUtcTimestamp = Math.floor(date.getTime() / 1000);

	return unixUtcTimestamp;
}
