import React, { useContext, useEffect, useState } from "react";
import {
  // Button,
  Col,
  // Input,
  // Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import OtpInput from "react-otp-input";

import OTPImage from "../../assets/images/otp-image.png";
// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BASE_URL,
  COOKIE_TIMEOUT_VALUE,
  COOKIE_TO_UNIT,
  // SEND_OTP,
  TWOFA_SET_PROFILE,
  TWOFA_VERIFY_OTP,
  TWOFA_VERIFY_OTP_CORP,
  // VERIFY_OTP,
} from "../../helpers/api_helper";
import axios from "axios";
import {
  CookieItems,
  GetCookies,
  SetCookies,
} from "../../helpers/cookies_helper";
import { UserContext } from "../../helpers/types/contextType";
import useBreakPoints from "../../hooks/useBreakPoints";
import CustomButton from "../../Component/CustomButton";

const TwoFactorVerificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const userContext = useContext(UserContext);
  const { isMobile } = useBreakPoints();
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { handleChangeUserId } = userContext;

  const [qrImage, setQrImage] = useState<string | null>(null);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    // setVerificationInitiated(true);
    if (jwt) {
      Generate_Qr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Generate_Qr = () => {
    axios
      .post(
        BASE_URL + TWOFA_SET_PROFILE,
        {},
        {
          headers: {
            Authorization: jwt,
            Accept: "image/png",
          },
          responseType: "arraybuffer", // Since the response is a streaming response
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setQrImage(`data:image/png;base64,${base64}`);
          // toast.success("QR Generated Successfully");
        } else {
          toast.error(
            "Error while verifying OTP for QR. Status code: " + res.status
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while verifying OTP for QR", err);
          toast.error("Incorrect OTP");
        }
      });
  };

  const verify2FAOTP = () => {
    setLoading(true);
    let url = role?.endsWith("corporate")
      ? TWOFA_VERIFY_OTP_CORP
      : TWOFA_VERIFY_OTP;

    console.log("url", url);
    axios
      .post(
        BASE_URL + url,
        { otp: otp, jwt: jwt },
        {
          headers: {
            Authorization: jwt,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (role?.endsWith("corporate")) {
            toast.success("2FA OTP verification successful.");
            onClose();
          } else {
            if ("token" in response.data) {
              SetCookies(
                CookieItems.JWT,
                response.data.token,
                COOKIE_TIMEOUT_VALUE,
                COOKIE_TO_UNIT
              );
              SetCookies(
                CookieItems.ROLE,
                response.data.role,
                COOKIE_TIMEOUT_VALUE,
                COOKIE_TO_UNIT
              );
              handleChangeUserId({
                userID: null,
                email: null,
                jwt: response.data.token,
                role: response.data.role,
              });
              onClose(); // Close the modal on success
            } else {
              toast.error("2FA OTP verification failed.");
            }
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while verifying 2FA OTP", err);
          toast.error(
            "Error while verifying 2FA OTP. Please check console for more details."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal
      centered
      size={step === 2 ? "md" : "xl"}
      isOpen={open}
      toggle={onClose}
    >
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        {step === 1
          ? "Two Factor Authentication Setup"
          : "Two Factor Authentication Verification"}
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142", textAlign: "center" }}>
        {step === 1 && (
          <Row>
            <Col lg={8} md={12}>
              <div
                style={{
                  textAlign: "left",
                  paddingBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    <strong>Step 1:</strong> Download the Google Authenticator
                    app on your mobile device.
                  </p>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-sm"
                    style={{
                      marginLeft: isMobile ? "5px" : "auto",
                    }}
                  >
                    Download
                  </a>
                </div>
                <p>
                  <strong>Step 2:</strong> Open the Google Authenticator app and
                  tap on the "+" button to add a new account.
                </p>
                <p>
                  <strong>Step 3:</strong> Choose "Scan a QR code" and scan the
                  QR Code.
                </p>
                <p>
                  <strong>Step 4:</strong> The app will now display a 6-digit
                  code that refreshes every 30 seconds. This is your OTP.
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    Need help? Watch the Video Tutorial
                  </p>
                  <a
                    href="https://youtu.be/FhJjKTXuzik?si=q_szEUJqGn322Ds8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-secondary btn-sm"
                  >
                    Video Tutorial
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <img
                src={qrImage ?? ""}
                alt="QR Code"
                style={{
                  maxWidth: "65%",
                  margin: "0 auto",
                }}
              />
            </Col>
          </Row>
        )}

        {step === 2 && (
          <div>
            <img className="" alt="explore-profile" src={OTPImage} />

            <h4>Please Enter OTP sent to your 2FA app</h4>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span></span>}
              containerStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              inputStyle={{
                width: "2rem",
                height: "2.6rem",
                margin: "0.5rem",
                borderRadius: "8px",
                border: "none",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        {" "}
        {step === 1 && (
          <CustomButton
            placeholder="Next"
            color="#00abff"
            type="button"
            onClick={() => {
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <>
            <CustomButton
              placeholder="Previous"
              color="#323d4e"
              type="button"
              onClick={() => {
                setStep(1);
              }}
            />
            <CustomButton
              placeholder="Submit"
              color="#00abff"
              type="button"
              disabled={loading}
              onClick={() => {
                verify2FAOTP();
              }}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TwoFactorVerificationModal;
