import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  // ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ADD_REP_DETAILS,
  BASE_URL,
  // CREATE_CORPORATE_PROFILE,
  // VERIFY_BANK_DETAILS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import {
  // CorporateFileType,
  RepresentativeDocumentsType,
} from "../../helpers/types/Profile";
import useBreakPoints from "../../hooks/useBreakPoints";
import CustomDropdown from "../../Component/CustomDropdown";

const typeOptionsData = [
  { label: "Representative", value: "representative" },
  { label: "Director", value: "director" },
];

const RepresentativeDetailModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();

  const [loading, setLoading] = useState<boolean>(false);
  const [repInfo, setRepInfo] = useState({
    name: "",
    // corporationID: "",
    type: { label: "Director", value: "director" },
    email: "",
    mobile: "",
    aadhaarNumber: "",
    aadhaarFrontName: "",
    aadhaarBackName: "",
    panNumber: "",
    panFileName: "",
    designation: "",
  });
  const [fileUpload, setFileUpload] = useState<RepresentativeDocumentsType>({
    AadhaarFront: null,
    AadhaarBack: null,
    Pan: null,
    LetterOfAuthorization: null,
  });

  const [terms, setTerms] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please accept the terms and conditions");
    } else {
      setLoading(true);
      const formData = new FormData();
      // formData.append("corp_id", repInfo?.corporationID);
      formData.append("name", repInfo?.name);
      formData.append("mobile_number", repInfo?.mobile);
      formData.append("aadhar_number", repInfo?.aadhaarNumber);
      formData.append("pan_number", repInfo?.panNumber);
      formData.append("type", repInfo?.type?.value);
      formData.append("email", repInfo?.email);
      formData.append(
        "aadhar_front_filename",
        fileUpload.AadhaarFront && fileUpload.AadhaarFront.name
          ? fileUpload.AadhaarFront.name
          : ""
      );
      formData.append(
        "aadhar_back_filename",
        fileUpload.AadhaarBack && fileUpload.AadhaarBack.name
          ? fileUpload.AadhaarBack.name
          : ""
      );
      formData.append("pan_number", repInfo?.panNumber);
      formData.append("designation", repInfo?.designation);

      // upload files
      if (fileUpload.AadhaarFront) {
        formData.append("aadhar_front", fileUpload.AadhaarFront);
      }
      if (fileUpload.AadhaarBack) {
        formData.append("aadhar_back", fileUpload.AadhaarBack);
      }
      if (fileUpload.Pan) {
        formData.append("pan_front", fileUpload.Pan);
      }
      if (fileUpload.LetterOfAuthorization) {
        formData.append(
          "letter_resolution_authority",
          fileUpload.LetterOfAuthorization
        );
      }

      axios
        .post(BASE_URL + ADD_REP_DETAILS, formData, {
          headers: {
            Authorization: jwt,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setLoading(false);
          onClose();
          toast.success("Representative/Director Details Added Successfully");
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error(
              "Error while submitting Representative/Director Details",
              err
            );
            toast.error(
              "Representative/Director Details Added Failed, Please try Again"
            );
          }
        });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    pdf?: boolean
  ) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const allowedExtensions = [".jpeg", ".jpg", ".png"];
      const pdfExtensions = [".pdf"];
      const fileExtension = selectedFile.name.slice(
        ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (
        (pdf === undefined || pdf === false) &&
        allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setFileUpload((prevState) => ({
          ...fileUpload,
          [key]: selectedFile,
        }));
      } else if (
        pdf === true &&
        pdfExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setFileUpload((prevState) => ({
          ...fileUpload,
          [key]: selectedFile,
        }));
      } else {
        e.target.value = "";
        if (pdf === true) {
          alert("Only .pdf files are allowed.");
        } else {
          alert("Only .jpeg, .jpg, and .png files are allowed.");
        }
      }
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD REPRESENTATIVE / DIRECTOR DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row
          style={{
            backgroundColor: "#1b2431",
            borderRadius: "10px",
            margin: "10px 5px",
            padding: "0px 23px 20px 10px",
          }}
        >
          <Col className="" style={{ marginTop: 12, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>SELECT TYPE</span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Type </Label>
                <CustomDropdown
                  options={typeOptionsData}
                  value={repInfo.type}
                  onChange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      type: e,
                    })
                  }
                  isDisabled={false}
                />
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                GENERAL INFORMATION
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Name </Label>
                <CustomInputBox
                  value={repInfo.name}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      name: e.target.value,
                    })
                  }
                  placeholder="Enter Name"
                  className="form-control"
                  type="text"
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={4} md={12}>
                <Label>Designation </Label>
                <CustomInputBox
                  value={repInfo.designation}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      designation: e.target.value,
                    })
                  }
                  placeholder="Enter Designation"
                  className="form-control"
                  type="text"
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </Col>
              <Col lg={4} md={12}>
                <Label>Mobile Number </Label>
                <CustomInputBox
                  value={repInfo.mobile}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      mobile: e.target.value,
                    })
                  }
                  placeholder="Enter Mobile"
                  className="form-control"
                  type="number"
                  required={true}
                  minLength={1}
                  maxLength={12}
                />
              </Col>
              <Col lg={4} md={12} className={`${isMobile ? "" : "mt-4"}`}>
                <Label>Email </Label>
                <CustomInputBox
                  value={repInfo.email}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      email: e.target.value,
                    })
                  }
                  placeholder="Enter Email"
                  className="form-control"
                  type="email"
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>PAN DETAILS</span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>PAN Number </Label>
                <CustomInputBox
                  value={repInfo.panNumber}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      panNumber: e.target.value,
                    })
                  }
                  placeholder="Enter PAN Number"
                  className="form-control"
                  type="text"
                  required={true}
                  minLength={10}
                  maxLength={10}
                />
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload PAN Card Front Page</Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.Pan?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-3">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-3"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "Pan")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {fileUpload.Pan && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.Pan)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                AADHAAR DETAILS
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Aadhaar Number </Label>
                <CustomInputBox
                  value={repInfo.aadhaarNumber}
                  onchange={(e: any) =>
                    setRepInfo({
                      ...repInfo,
                      aadhaarNumber: e.target.value,
                    })
                  }
                  placeholder="Enter Aadhaar Number"
                  className="form-control"
                  type="text"
                  required={true}
                  minLength={12}
                  maxLength={12}
                />
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload Aadhaar Card Front</Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.AadhaarFront?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-4">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-4"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "AadhaarFront")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {fileUpload.AadhaarFront && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.AadhaarFront)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload Aadhaar Card Back</Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.AadhaarBack?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-5">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-5"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "AadhaarBack")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {fileUpload.AadhaarBack && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.AadhaarBack)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                LETTER OF AUTHORITY BY BOARD OF DIRECTORS
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Upload Letter Of Resolution Authority</Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                      height: 47,
                    }}
                    value={fileUpload.LetterOfAuthorization?.name}
                  />

                  {fileUpload.LetterOfAuthorization && (
                    <label
                      style={{
                        background: "#3cab3d",
                        borderColor: "#3cab3d",
                        height: 47,
                        borderRadius: 0,
                      }}
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(
                            fileUpload.LetterOfAuthorization as Blob
                          ),
                          "_blank"
                        );
                      }}
                      className="preciew-btn"
                    >
                      <i className="fa fa-file-pdf-o"></i>Preview
                    </label>
                  )}

                  <label
                    style={{
                      height: 47,
                    }}
                    htmlFor="input-file-9"
                  >
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-9"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "LetterOfAuthorization", true)
                    }
                    required
                    accept=".pdf"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <div
        className="p-4 pt-2 d-flex justify-content-between gap-2"
        style={{ backgroundColor: "#273142" }}
      >
        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            value="terms"
            placeholder="terms"
            required={true}
            checked={terms}
            onChange={(e: any) => {
              setTerms(e.target.checked);
            }}
          />
          <label className="v-aligh">
            I agree to all terms and conditions.
          </label>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <CustomButton
            placeholder="Submit"
            color="#00abff"
            disabled={loading}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RepresentativeDetailModal;
