import React, { useState } from "react";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import axios from "axios";
import {
  BASE_URL,
  BLOCK_CHAIN,
  CREDIT_REQUEST_BY_TOKENID_AND_ASSETSID,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Component/CustomButton";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import useBreakPoints from "../../hooks/useBreakPoints";
const CategoryTable = ({
  data,
  handleAddFavoriteBtn,
  handleRemoveFavoriteBtn,
  tradeData,
  setStep,
  setSingleProjectDetails,
}: {
  data: any;
  handleAddFavoriteBtn: any;
  handleRemoveFavoriteBtn: any;
  tradeData: any;
  setStep: any;
  setSingleProjectDetails: any;
}) => {
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const navigate = useNavigate();
  const { isMediumMobile } = useBreakPoints();
  const [openSubMenu, setOpenSubMenu] = useState({
    open: false,
    id: null,
    data: [],
  });

  console.log("openSubMenu", openSubMenu);
  const handleChangeOpen = (data: any) => {
    if (openSubMenu.open && openSubMenu.id === data.token_id) {
      setOpenSubMenu({
        open: false,
        id: null,
        data: [],
      });
    } else {
      axios
        .post(
          BASE_URL + CREDIT_REQUEST_BY_TOKENID_AND_ASSETSID,
          {
            token_id: data.token_id,
            asset_id: data.asset_id,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          console.log("res", res);
          setOpenSubMenu({
            open: true,
            data: res.data,
            id: data.token_id,
          });
        })
        .catch((err) => {
          console.error("Error while get order details", err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error(
              "Error while order details for Order ID: " + data.token_id
            );
          }
        });
    }
  };

  return (
    <div
      className={`admin-table table-responsive ${isMediumMobile ? "mt-3" : ""}`}
    >
      <table className="accordion-table">
        <thead>
          <tr>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "1px solid #444e5d",
                borderRight: "none",
                borderRadius: "12px 0 0 12px",
              }}
              className="expand"
            ></th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Category ID
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Credit Program Name
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Category Name
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Category Type
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Corsia Compatibility
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Reddplusplus Compatibility
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              One Time Claimable
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              Status
            </th>
            <th
              style={{
                textWrap: "balance",
                borderTop: "1px solid #444e5d",
                borderBottom: "1px solid #444e5d",
                borderLeft: "none",
                borderRight: "1px solid #444e5d",
                borderRadius: "0 12px 12px 0px",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => (
            <>
              <tr className="header-level">
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer", background: "#273142" }}
                  className="expand"
                >
                  <i
                    className={`${
                      openSubMenu.id === item.token_id
                        ? "fa fa-chevron-down"
                        : "fa fa-chevron-right"
                    }`}
                  ></i>
                </td>{" "}
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.token_id}
                </td>
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.credit_programme_name}
                </td>
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.token_id_name}
                </td>
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.token_id_type}
                </td>
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.corsia_compatiblity}
                </td>
                <td
                  onClick={() => handleChangeOpen(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.reddplusplus_compatibility}
                </td>
                <td>
                  <span
                    style={{
                      padding: "5px 12px",
                      background: "#0e9cff",
                      borderRadius: "7px",
                      fontSize: 15,
                      fontWeight: 700,
                    }}
                  >
                    {"Yes"}
                  </span>
                </td>
                <td>
                  {" "}
                  <span
                    style={{
                      padding: "5px 12px",
                      background: "#0e9cff",
                      borderRadius: "7px",
                      fontSize: 15,
                      fontWeight: 700,
                    }}
                  >
                    {"Approved"}
                  </span>
                </td>
                <td>
                  {" "}
                  <div
                    style={{ padding: "5px 25px" }}
                    className="d-flex justify-content-center align-items-center gap-2"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <span>{item?.number_of_sdg_goals} </span>
                      <i
                        className="fa fa-star"
                        style={{ color: "#f1c644" }}
                      ></i>
                    </div>
                    {jwt !== undefined &&
                      role !== undefined &&
                      (item.favourite ? (
                        <FavoriteIcon
                          onClick={() => handleRemoveFavoriteBtn(item.token_id)}
                          sx={{
                            color: "#E812A6",
                            fontSize: 20,
                            borderWidth: 2,
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          onClick={() => handleAddFavoriteBtn(item.token_id)}
                          sx={{
                            color: "#fff",
                            fontSize: 20,
                            borderWidth: 2,
                            cursor: "pointer",
                          }}
                        />
                      ))}
                  </div>
                </td>
              </tr>
              <tr className="sub-level">
                <td style={{ background: "#273142" }} className="expand"></td>
                <td colSpan={9}>
                  <table
                    style={{ width: "100%" }}
                    className={
                      openSubMenu.id === item.token_id ? "open mb-3" : "close"
                    }
                  >
                    <thead>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "1px solid #444e5d",
                          borderRight: "none",
                        }}
                      >
                        Registry Name
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Credit Programme
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Methodology Id
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Mint Duration
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Project Country
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Registry Url
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        Transaction Hash
                      </th>
                      <th
                        style={{
                          background: "#1e2939",
                          borderTop: "1px solid #444e5d",
                          borderBottom: "1px solid #444e5d",
                          borderLeft: "none",
                          borderRight: "1px solid #444e5d",
                        }}
                      >
                        {" "}
                        View
                      </th>
                    </thead>
                    <tbody>
                      {openSubMenu?.data?.map((subItem: any, j: number) => (
                        <tr key={j} style={{ padding: 10 }}>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {subItem?.registry_name}
                          </td>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {subItem?.credit_programme}
                          </td>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {subItem?.methodology_id}
                          </td>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {subItem?.mint_duration}
                          </td>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {subItem?.project_country}
                          </td>
                          <td style={{ background: "#17202d", padding: 8 }}>
                            {" "}
                            <CustomButton
                              type="button"
                              onClick={() =>
                                window.open(item.registry_project_url, "_blank")
                              }
                              style={{ padding: "2px 15px" }}
                              placeholder="Url"
                              color="#3498DB"
                            />
                          </td>
                          <td style={{ background: "#17202d" }}>
                            {" "}
                            <CustomButton
                              type="button"
                              onClick={() =>
                                window.open(
                                  `${BLOCK_CHAIN}${subItem.txhash}`,
                                  "_blank"
                                )
                              }
                              style={{ padding: "2px 15px" }}
                              divclassName="m-0"
                              placeholder="Tx HASH"
                              color="#3498DB"
                            />
                          </td>
                          <td style={{ background: "#17202d" }}>
                            <VisibilityRoundedIcon
                              style={{
                                color: "#8bc34a",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSingleProjectDetails({
                                  ...tradeData,
                                  projectDetails: item,
                                  itemDetails: subItem,
                                });
                                setStep(2);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
