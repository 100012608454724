import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";
import {
  CookieItems,
  DeleteCookie,
  GetCookies,
} from "../helpers/cookies_helper";
import axios from "axios";
import { BASE_URL, VALIDATE_JWT } from "../helpers/api_helper";
import { Role } from "../helpers/types/role";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  LogoutFlag?: boolean;
  acceptedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  LogoutFlag,
  ...rest
}) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId, handleChangeUserId } = userContext;
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (jwt !== undefined && role !== undefined && userId === null) {
      axios
        .post(BASE_URL + VALIDATE_JWT, { token: jwt })
        .then((response: any) => {})
        .catch((error: any) => {
          console.error("error", error);
          DeleteCookie(CookieItems.JWT);
          localStorage.clear();
          DeleteCookie(CookieItems.ROLE);
          handleChangeUserId({
            userID: null,
            email: null,
            jwt: null,
            role: null,
          });
          navigate("/signin");
        });
      handleChangeUserId({ userID: null, email: null, jwt: null, role: null });
    }
    if (LogoutFlag) {
      DeleteCookie(CookieItems.JWT);
      DeleteCookie(CookieItems.ROLE);
      localStorage.clear();
      handleChangeUserId({ userID: null, email: null, jwt: null, role: null });
      navigate("/signin");
    }

    if (
      (userId === null || userId.jwt === null || userId.role === null) &&
      (jwt === undefined || role === undefined)
    ) {
      navigate("/signin");
    } else if (
      role !== undefined &&
      rest.acceptedRoles !== undefined &&
      !rest?.acceptedRoles.includes(role as Role)
    ) {
      if (role === Role.corporate || role === Role.unverifiedCorporate) {
        navigate("/profile");
      } else {
        navigate("/profile");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (
    (userId === null || userId.jwt === null || userId.role === null) &&
    jwt === undefined &&
    role === undefined
  ) {
    return <></>;
  }

  return <Component {...rest} />;
};
export default ProtectedRoute;
